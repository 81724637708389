import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/edu/student';

const SCHOOL_STATE = `${API_PROGRAM}/school-state`;
const FINAL_CONFIRM_STATUS = `${API_PROGRAM}/get-final-confirm-status`;
const STUDENTS = `${API_PROGRAM}/list`;
const STUDENT_DOWNLOAD_TXT = `${API_PROGRAM}/student-download-txt`;
const STUDENT_NEW_DOWNLOAD_TXT = `${API_PROGRAM}/student-new-download-txt`;
const STUDENT_DOWNLOAD_EXCEL = `${API_PROGRAM}/student-download-excel`;
const STUDENT_DETAIL = `${API_PROGRAM}/detail`;
const PROGRAM_STATE = `${API_PROGRAM}/program-state`;
const PROGRAM_STATE_DOWNLOAD = `${API_PROGRAM}/program-state-download`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 임시 학생 최종 확정
export const putSchoolStateFinalDecision = () => {
  return Axios.put(`${SCHOOL_STATE}`);
};

// 임시 학생 최종 확정
export const getFinalConfirmStatus = () => {
  return Axios.get(`${FINAL_CONFIRM_STATUS}`);
};

// [학생관리 > 학생현황] 학생현황 리스트 조회
export const getStudents = data => {
  return Axios.post(`${STUDENTS}`, data);
};

// [학생관리 > 학생현황] 학생 상세정보 조회
export const getStudentDetail = id => {
  return Axios.get(`${STUDENT_DETAIL}/${id}`);
};

// [학생관리 > 프로그램 현황] 프로그램 현황 조회
export const getStudentProgram = data => {
  return Axios.post(`${PROGRAM_STATE}`, data);
};

// [학생관리 > 학생현황] 텍스트 다운로드
export const txtDownloadStudents = data => {
  return Axios.post(`${STUDENT_DOWNLOAD_TXT}`, data, {
    responseType: 'blob',
  });
};

// [학생관리 > 학생현황] 텍스트 신규 다운로드
export const txtNewDownloadStudents = data => {
  return Axios.post(`${STUDENT_NEW_DOWNLOAD_TXT}`, data, {
    responseType: 'blob',
  });
};

// [학생관리 > 학생현황] 엑셀 다운로드
export const excelDownloadStudents = data => {
  return Axios.post(`${STUDENT_DOWNLOAD_EXCEL}`, data, {
    responseType: 'blob',
  });
};

export const downloadStudentProgram = data => {
  return Axios.post(`${PROGRAM_STATE_DOWNLOAD}`, data, {
    responseType: 'blob',
  });
};
