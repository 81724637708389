/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Container, Button, Image, Nav } from 'react-bootstrap';
import { images } from '@assets';
import { Timer } from '@components';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default React.memo(function HeaderAdmEdu({
  timerOption,
  tokenHandler,
  logoutHandler,
}) {
  const history = useHistory();
  const navi = useLocation();

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <header>
      <section className="top-area">
        <Container>
          <h1 className="logo-container">
            <Link
              to={{
                pathname: '/admEdu/student/status',
                state: {
                  refresh: new Date(),
                },
              }}
            >
              <Image src={images.logo} />
            </Link>
          </h1>
          <div className="flex-start">
            <Timer mm="60" ss="00" reset={timerOption} />
            <Button
              size="sm"
              variant="outline-primary"
              className="ms-3"
              onClick={tokenHandler}
            >
              시간연장
            </Button>
            <Button
              size="sm"
              variant="primary"
              className="ms-3"
              onClick={() => history.push('/admEdu/student/status')}
            >
              메인으로
            </Button>
            <div className="user-menu">
              <p>
                <span>교육청 관리자</span>&nbsp;님
              </p>
              <ul>
                {/* <li>
                  <NavLink to="/student/mypage">마이페이지</NavLink>
                </li> */}
                <li>
                  <NavLink to="/admEdu/post/notice/list">공지사항</NavLink>
                </li>
                <li>
                  <NavLink to="/admEdu/post/eduArchive/list">
                    교육 자료실
                  </NavLink>
                </li>
                <li />
                <li>
                  <NavLink to="/" onClick={logoutHandler}>
                    로그아웃
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

      <section className="nav-bar type-left">
        <Container>
          <Nav>
            {/* ↓ 학생 관리 */}
            <Nav.Item>
              <Link
                to={{
                  pathname: '/admEdu/student/status',
                  state: {
                    refresh: new Date(),
                  },
                }}
                className={classNames({
                  selected: navi.pathname.includes('/admEdu/student'),
                })}
              >
                학생 관리
              </Link>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to={{
                      pathname: '/admEdu/student/status',
                      state: {
                        refresh: new Date(),
                      },
                    }}
                  >
                    학생현황
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to={{
                      pathname: '/admEdu/student/fixedStatus',
                      state: {
                        refresh: new Date(),
                      },
                    }}
                  >
                    학생 확정 현황
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    // to="/admEdu/student/program"
                    to={{
                      pathname: '/admEdu/student/program',
                      state: {
                        refresh: new Date(),
                      },
                    }}
                  >
                    프로그램 현황
                  </NavLink>
                </li>
              </ul>
            </Nav.Item>

            {/* ↓ 학교 관리자 관리 */}
            <Nav.Item>
              <NavLink
                activeClassName="selected"
                to={{
                  pathname: '/admEdu/teacher',
                  state: {
                    refresh: new Date(),
                  },
                }}
              >
                학교 관리자 관리
              </NavLink>
            </Nav.Item>

            {/* ↓ 바우처 관리  */}
            <Nav.Item>
              <Link
                to="/admEdu/voucher/lookup"
                className={classNames({
                  selected: navi.pathname.includes('/admEdu/voucher'),
                })}
              >
                바우처 관리
              </Link>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/voucher/lookup"
                  >
                    바우처 조회
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/voucher/status"
                  >
                    바우처 지급 현황
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/voucher/scoreStat"
                  >
                    바우처 점수 통계
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/voucher/scoreMgmt"
                  >
                    바우처 점수 관리
                  </NavLink>
                </li>
              </ul>
            </Nav.Item>

            {/* ↓ 게시물 관리  */}
            <Nav.Item>
              <Link
                to="/admEdu/post/notice/list"
                className={classNames({
                  selected: navi.pathname.includes('/admEdu/post'),
                })}
              >
                게시물 관리
              </Link>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/post/notice/list"
                  >
                    공지사항
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/post/eduArchive/list"
                  >
                    교육 자료실
                  </NavLink>
                </li>
              </ul>
            </Nav.Item>

            {/* ↓ 만족도 조사 관리  */}
            <Nav.Item>
              <NavLink
                to="/admEdu/satisfactionSurvey"
                className={classNames({
                  selected: navi.pathname.includes(
                    '/admEdu/satisfactionSurvey',
                  ),
                })}
              >
                만족도 조사 관리
              </NavLink>
            </Nav.Item>

            {/* ↓ 시스템 관리  */}
            <Nav.Item>
              <Link
                to="/admEdu/system/ip"
                className={classNames({
                  selected: navi.pathname.includes('/admEdu/system/ip'),
                })}
              >
                시스템 관리
              </Link>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/system/ip"
                  >
                    IP관리
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/system/schedule"
                  >
                    일정관리
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="sub-selected"
                    to="/admEdu/system/comp"
                  >
                    기업관리
                  </NavLink>
                </li>
              </ul>
            </Nav.Item>
          </Nav>
        </Container>
      </section>
    </header>
  );
});
