/**
 *   [ 현장실무능력 API ]
 *
 */
import Axios from '../axios';

const API_STUDENT = 'api/v1/teacher';

const INDUSTRY_LIST = `${API_STUDENT}/industry-list`;
const INDUSTRY = `${API_STUDENT}/industry`;
const INDUSTRY_INFO = `${API_STUDENT}/industry-info`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 산업체 채용면접, 현장 견학, 체험, 실습 리스트 가져오기
export const selectFormPracticeAbilityAllByTeacher = param => {
  return Axios.post(`${INDUSTRY_LIST}`, param);
};

// 산업체 채용면접, 현장 견학, 체험, 실습 정보 가져오기
export const selectFormPracticeAbilityInfoByTeacher = param => {
  return Axios.post(`${INDUSTRY_INFO}`, param);
};

// 산업체 채용면접, 현장 견학, 체험, 실습 상태 변경 신규등록, 승인요청, 승인요청 취소, 삭제
export const updateFormPracticeAbilityByTeacher = param => {
  return Axios.post(`${INDUSTRY}`, param, formDataConfig);
};
