/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Table } from 'react-bootstrap';
import { Utils } from '@common';
import { format } from 'date-fns';
import LoadingSpinner from '@components/LoadingSpinner';
import { useHistory } from 'react-router-dom';

import {
  getVoucherBatchList,
  downloadVoucherTxt,
  downloadVoucherExcel,
} from '@api/eduadmin/voucher';

export default React.memo(function AdmEduVoucherStatus(props) {
  const history = useHistory();

  //* #################################################################
  //* [ State ]
  //* #################################################################
  const [showSpinner, setShowSpinner] = useState(false);
  const [checkSchoolList, setCheckSchoolList] = useState([]);
  const [voucherBatchList, setVoucherBatchList] = useState([]);
  const getVoucherBatchs = async () => {
    const { data } = await getVoucherBatchList();
    setVoucherBatchList(data.data);
  };

  //* #################################################################
  //* [ API ] 학교/학년별 바우처 리스트
  //* #################################################################
  const handleDownloadSchool = e => {
    if (e.target.checked) {
      if (e.target.value === 'all') {
        setCheckSchoolList([
          ...voucherBatchList.map((v, i) => {
            return String(v.schoolIdx);
          }),
        ]);
      } else {
        setCheckSchoolList([...checkSchoolList, e.target.value]);
      }
    } else if (e.target.value === 'all') {
      setCheckSchoolList([]);
    } else {
      setCheckSchoolList(checkSchoolList.filter(val => val !== e.target.value));
    }
  };

  //* #################################################################
  //* [ API ] 텍스트 파일 다운로드
  //* #################################################################
  const txtDownload = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const params = {
        schoolIdxList: checkSchoolList,
      };
      setShowSpinner(true);
      const { data } = await downloadVoucherTxt(params);
      const blob = new Blob([data]);
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      const fileName = format(new Date(), 'yyyyMMdd ').toString();
      link.download = fileName;
      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      target.disabled = false;
      setShowSpinner(false);
    }
    target.disabled = false;
    setShowSpinner(false);
  };

  //* #################################################################
  //* [ API ] 엑셀 파일 다운로드
  //* #################################################################
  const excelDownload = async event => {
    const target = event.currentTarget;
    target.disabled = true;

    try {
      // JSON 파라미터
      const params = {
        schoolIdxList: checkSchoolList,
      };

      setShowSpinner(true);
      let { data } = await downloadVoucherExcel(params);
      const blob = new Blob([data]);

      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = `${format(new Date(), 'yyyyMMdd')}.xlsx`;

      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      target.disabled = false;
      setShowSpinner(false);
    }
    target.disabled = false;
    setShowSpinner(false);
  };

  // 다운로드 파일 이름을 추출하는 함수
  const extractDownloadFilename = response => {
    console.log(response);
    const disposition = response.headers['content-disposition'];
    const fileName = decodeURI(
      disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, ''),
    );
    return fileName;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getVoucherBatchs();
  }, []);

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <main id="admEdu-voucher-status" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>바우처 지급 현황</h5>
            </div>
          </section>
          {showSpinner ? (
            <LoadingSpinner show={showSpinner} message="다운로드 중입니다." />
          ) : (
            <Card>
              <Card.Header>
                <div className="btn-area justify-content-end m-0">
                  <Button
                    variant="outline-primary"
                    disabled={!checkSchoolList || checkSchoolList.length === 0}
                    onClick={e => {
                      excelDownload(e);
                    }}
                  >
                    엑셀 다운로드
                  </Button>
                  <Button
                    variant="outline-primary"
                    disabled={!checkSchoolList || checkSchoolList.length === 0}
                    onClick={e => {
                      txtDownload(e);
                    }}
                  >
                    텍스트 다운로드
                  </Button>
                  <Button
                    disabled={voucherBatchList.find(v => v.confirmYn === 'N')}
                    variant="primary"
                    onClick={() => {
                      history.push('/admEdu/system/schedule');
                    }}
                  >
                    바우처 후처리
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="pt-0">
                <Table className="bt-none tr-even-bg">
                  <colgroup>
                    <col width={60} />
                    <col width={120} />
                    <col width="*" />
                    <col width={120} />
                    <col width={160} />
                    <col width={160} />
                    <col width={160} />
                    <col width={160} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th rowSpan={2}>
                        <Form.Check
                          type="checkbox"
                          id="check-all"
                          label=""
                          value="all"
                          onChange={e => {
                            handleDownloadSchool(e);
                          }}
                        />
                      </th>
                      <th rowSpan={2}>ID</th>
                      <th rowSpan={2}>학교명</th>
                      <th rowSpan={2}>지급 상태</th>
                      <th colSpan={3}>바우처금액</th>
                      <th rowSpan={2} className="text-end">
                        총금액
                      </th>
                    </tr>
                    <tr>
                      <th>1학년</th>
                      <th>2학년</th>
                      <th>3학년</th>
                    </tr>
                  </thead>
                  <tbody>
                    {voucherBatchList.length > 0 ? (
                      voucherBatchList.map((item, i) => {
                        return (
                          <tr key={`voucher-${i}`} className="go-detail">
                            <td>
                              <Form.Check
                                type="checkbox"
                                id={`check-${item.schoolIdx}`}
                                label=""
                                value={item.schoolIdx}
                                checked={
                                  !!checkSchoolList.find(
                                    v => String(v) === String(item.schoolIdx),
                                  )
                                }
                                onChange={e => {
                                  handleDownloadSchool(e);
                                }}
                              />
                            </td>
                            <td
                              onClick={() =>
                                props.history.push({
                                  pathname: '/admEdu/voucher/status/detail',
                                  state: { thisVoucher: item },
                                })
                              }
                            >
                              {item.schoolId}
                            </td>
                            <td
                              onClick={() =>
                                props.history.push({
                                  pathname: '/admEdu/voucher/status/detail',
                                  state: { thisVoucher: item },
                                })
                              }
                            >
                              {item.schoolName}
                            </td>
                            <td
                              onClick={() =>
                                props.history.push({
                                  pathname: '/admEdu/voucher/status/detail',
                                  state: { thisVoucher: item },
                                })
                              }
                            >
                              {item.confirmYn !== 'F' ? (
                                item.confirmYn === 'Y' ? (
                                  <strong className="text-success">
                                    지급완료
                                  </strong>
                                ) : (
                                  <strong className="text-warning">
                                    미지급
                                  </strong>
                                )
                              ) : (
                                <strong className="text-gray">미참여</strong>
                              )}
                            </td>
                            <td
                              className="text-end"
                              onClick={() =>
                                props.history.push({
                                  pathname: '/admEdu/voucher/status/detail',
                                  state: { thisVoucher: item },
                                })
                              }
                            >
                              {Utils.numberComma(item.grade1Voucher)}
                            </td>
                            <td
                              className="text-end"
                              onClick={() =>
                                props.history.push({
                                  pathname: '/admEdu/voucher/status/detail',
                                  state: { thisVoucher: item },
                                })
                              }
                            >
                              {Utils.numberComma(item.grade2Voucher)}
                            </td>
                            <td
                              className="text-end"
                              onClick={() =>
                                props.history.push({
                                  pathname: '/admEdu/voucher/status/detail',
                                  state: { thisVoucher: item },
                                })
                              }
                            >
                              {Utils.numberComma(item.grade3Voucher)}
                            </td>
                            <td
                              className="text-end"
                              onClick={() =>
                                props.history.push({
                                  pathname: '/admEdu/voucher/status/detail',
                                  state: { thisVoucher: item },
                                })
                              }
                            >
                              {Utils.numberComma(item.voucherSum)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <p className="no-data">데이터가 없습니다.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </article>
      </Container>
    </main>
  );
});
