/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table, Row, Col } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

//* API
import {
  selectFormAbilityInfo,
  insertUpdateAbilityTest,
} from '@api/student/jobAssess';
import { getFilePathFromRedis } from '@api/file';

//* Utils
import Utils from '@common/Utils';
import Swal from 'sweetalert2';

//* CONSTS
import { FormStatus, FILE_FORM_TYPE } from '@common/consts';
import Moment from 'react-moment';

// Form 정보
const initialInfo = {
  formSeq: '',
  status: 'NONE',
  point: 0,
  name: '',
  id: '',
  testTitle: '',
  testDate: '',
  KOR: '',
  ENG: '',
  MATH: '',
  SOL: '',
  AVG: '',
  files: [],
};

//* [ Main ]
export default React.memo(function JobAssessReport(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################

  const history = useHistory();
  // const [showFileList, setShowFileList] = useState([]);

  const { seq: formSeq, type: typeParam } = useParams();

  //* Data
  const [formInfo, setFormInfo] = useState(initialInfo);
  // const [isValidForm, setIsValidForm] = useState(false);

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // type
  const [viewType, setViewType] = useState(typeParam);
  // const [approveState, setApproveState] = useState('tempoSave'); // approve/waitingToApprove/tempoSave/security
  // const [type, setType] = useState('student'); // viewType (student, teacher, eduAdm)
  // const [gradeType, setGradeType] = useState('freshman'); // freshman/junior/senior

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 인쇄
  const printWindowRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  // 수정 페이지 이동
  const moveToEdit = () => {
    history.push(`/student/employAbility/jobAssess/write/${formSeq}`);
  };

  // 목록 이동
  const moveToList = () => {
    history.push('/student/employAbility/jobAssess');
  };

  const [teacherCommentList, setTeacherCommentList] = useState([]);

  //* #################################################################
  //* [ API ] Form 정보 가져오기
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getInfo = async () => {
    // 파라미터
    const param = {
      formSeq,
    };

    // Axios
    try {
      const { data } = await selectFormAbilityInfo(param);

      if (data.code === 0) {
        setFirstCallDone(true);
        const formData = data.data;

        setFormInfo({
          formSeq: formData.seq,
          name: formData.name,
          id: formData.id,
          status: formData.status,
          point: formData.point,
          testTitle: formData.test_title,
          testDate: new Date(formData.test_date).toISOString().split('T')[0],
          KOR: formData.test_korean,
          ENG: formData.test_english,
          MATH: formData.test_math,
          SOL: formData.test_solve,
          AVG: formData.test_average,
          files: formData.files,
        });

        setTeacherCommentList(formData.teachercommentlist);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 승인요청, 취소, 삭제
  //* #################################################################
  const updateRequest = async reqStatus => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 승인요청 > 입력 검증
    if (reqStatus === 'REQ') {
      // if (!Utils.checkDateTime(new Date(formInfo.testDate))) {
      //   Swal.fire({
      //     text: '평가일을 확인해주세요.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }

      if (!formInfo.testTitle) {
        Swal.fire({
          text: '평가명이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      if (!formInfo.KOR || !formInfo.ENG || !formInfo.MATH || !formInfo.SOL) {
        Swal.fire({
          text: '평가 결과가 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      // if (!formInfo.files || formInfo.files.length === 0) {
      //   Swal.fire({
      //     text: '첨부파일이 등록되지 않았습니다.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'DEL' &&
      !(await Swal.fire({
        text: '삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      // TODO :: confirm
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'ING' &&
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'REQ' &&
      !(await Swal.fire({
        text: '승인요청하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // API 파라미터
    const param = {
      formSeq,
      status: reqStatus,
    };

    const formData = new FormData();

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(param)], { type: 'application/json' }),
    );

    // 중복요청 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await insertUpdateAbilityTest(formData);

      if (data.code === 0) {
        history.push('/student/employAbility/jobAssess');
      }
    } catch (e) {
      // 중복요청 방지 해제
      setIsSentRequest(false);

      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 파일 조회
  //* #################################################################
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_ABILITY_TEST,
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        window.open(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getInfo();

    return () => {
      setFormInfo({});
    };
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (인쇄, 목록, 삭제, 수정, 취소)
  const customButton = type => {
    let variant;
    let event;
    let text;
    // let isValid = true;

    switch (type) {
      case 'print':
        variant = 'outline-primary';
        event = handlePrint;
        text = '인쇄';
        break;
      case 'list':
        variant = 'primary';
        event = moveToList;
        text = '목록으로';
        break;
      case 'delete':
        variant = 'outline-primary';
        event = () => {
          updateRequest('DEL');
        };
        text = '삭제';
        break;
      case 'edit':
        variant = 'primary';
        event = moveToEdit;
        text = '수정';
        break;
      case 'cancel':
        variant = 'primary';
        event = () => {
          updateRequest('ING');
        };
        text = '승인요청 취소';
        break;
      case 'request':
        variant = 'primary';
        event = () => {
          updateRequest('REQ');
        };
        text = '승인요청';
        // isValid = isValidForm;
        break;
      default:
        break;
    }

    return (
      <Button size="lg" variant={variant} onClick={event}>
        {text}
      </Button>
    );
  };

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-jobAssess-report">
      <Container>
        <article className="content" ref={printWindowRef}>
          {viewType === 'student' && (
            <ul className="path">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="#none">직업기초능력평가</Link>
              </li>
              <li>
                <Link to="#none">결과보고서</Link>
              </li>
            </ul>
          )}

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>직업기초능력평가 결과 보고서</h5>
              <div className="dl-box type-bar lg">
                <dl>
                  <strong className={FormStatus[formInfo.status].class}>
                    {FormStatus[formInfo.status].text}
                  </strong>
                </dl>
                <dl>
                  <dd>
                    <strong>{formInfo.point}</strong>
                  </dd>
                </dl>
              </div>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <div className="split-table">
              <Row className="table-row">
                <Col className="th col-2">학번</Col>
                <Col className="td col-10">{formInfo.id}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">이름</Col>
                <Col className="td col-10">{Utils.decrypt(formInfo.name)}</Col>
              </Row>
            </div>
          </section>

          {/* 평가정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>평가정보</h5>
            </div>
            <div className="split-table">
              <Row className="table-row">
                <Col className="th col-2">평가일</Col>
                <Col className="td col-10">{formInfo.testDate}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">평가명</Col>
                <Col className="td col-10">{formInfo.testTitle}</Col>
              </Row>
            </div>
          </section>

          {/* 직업기초능력평가 결과 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>직업기초능력평가 결과</h5>
            </div>
            <Table>
              <colgroup>
                <col width={200} />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>세부역량</th>
                  <th>등급</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-start text-gray">의사소통 국어</td>
                  <td className="text-start">
                    {formInfo.KOR ? `${formInfo.KOR}등급` : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-start text-gray">의사소통 영어</td>
                  <td className="text-start">
                    {formInfo.ENG ? `${formInfo.ENG}등급` : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-start text-gray">수리활용</td>
                  <td className="text-start">
                    {formInfo.MATH ? `${formInfo.MATH}등급` : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-start text-gray">문제해결</td>
                  <td className="text-start">
                    {formInfo.SOL ? `${formInfo.SOL}등급` : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="text-start text-gray">평균</td>
                  <td className="text-start">
                    {formInfo.AVG ? `${formInfo.AVG}등급` : '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </section>

          {/* 파일첨부 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>파일첨부</h5>
            </div>
            {formInfo.files.length > 0 ? (
              <ul className="attachment-list">
                {formInfo.files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <Button
                        className="btn-icon ic-down"
                        onClick={() => openFile(file.file_seq)}
                      >
                        {file.file_name_org}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            ) : (
              firstCallDone && (
                <ul className="attachment-list">
                  <li>없음</li>
                </ul>
              )
            )}
          </section>

          {/* 교사 코멘트 */}
          {(formInfo.status === FormStatus.CON.value ||
            formInfo.status === FormStatus.REJ.value) && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5 className="text-primary">교사 코멘트</h5>
              </div>
              <div className="comment-box type-primary">
                <ul className="comment-list">
                  {teacherCommentList.length > 0 &&
                    teacherCommentList.map((item, index) => {
                      return (
                        <li key={`comment-${index}`}>
                          <p className="date">
                            {item.reg_date && (
                              <Moment
                                format="YYYY.MM.DD"
                                date={item.reg_date}
                              />
                            )}
                          </p>
                          <p className="text">{item.comment}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {/* 승인 */}
          {viewType === 'student' && formInfo.status === 'CON' && (
            <div className="btn-area">
              {/* 인쇄 버튼 */}
              {customButton('print')}
              {/* 목록 버튼 */}
              {customButton('list')}
            </div>
          )}
          {/* 승인대기 */}
          {viewType === 'student' && formInfo.status === 'REQ' && (
            <div className="btn-area">
              {/* 인쇄 버튼 */}
              {customButton('print')}
              {/* 승인요청 취소 버튼 */}
              {customButton('cancel')}
              {/* 목록 버튼 */}
              {customButton('list')}
            </div>
          )}
          {/* 임시저장 */}
          {viewType === 'student' && formInfo.status === 'ING' && (
            <div className="btn-area">
              {/* 인쇄 버튼 */}
              {customButton('print')}
              {/* 삭제 버튼 */}
              {customButton('delete')}
              {/* 수정 버튼 */}
              {customButton('edit')}
              {/* 승인요청 버튼 */}
              {customButton('request')}
              {/* 목록 버튼 */}
              {customButton('list')}
            </div>
          )}
          {/* 보완 */}
          {viewType === 'student' && formInfo.status === 'REJ' && (
            <div className="btn-area">
              {/* 인쇄 버튼 */}
              {customButton('print')}
              {/* 수정 버튼 */}
              {customButton('edit')}
              {/* 목록 버튼 */}
              {customButton('list')}
            </div>
          )}
          {/* 학생 X */}
          {viewType !== 'student' && (
            <div className="btn-area">
              {/* 목록 버튼 */}
              {customButton('list')}
            </div>
          )}
        </article>
      </Container>
    </main>
  );
});
