/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Table } from 'react-bootstrap';
import { CustomSelect } from '@components';
import Swal from 'sweetalert2';

//* API
import { getMatchingCompanyList } from '@api/teacher/matchingInfo';

//* Consts
import { COMPANY_SEARCH } from '@common/consts';
import ReactPaginate from 'react-paginate';

//* [ Main ]
export default React.memo(function AdmEduSystemCompList(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 검색
  const [searchOption, setSearchOption] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  // 페이지
  const [page, setPage] = useState(1);
  const [pageCnt, setPageCnt] = useState(1);

  // 리스트
  const [companyList, setCompanyList] = useState([]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 검색
  const searchHandler = () => {
    if (!searchOption) {
      Swal.fire({
        text: '검색조건을 확인해주세요.',
        confirmButtonText: '확인',
      });
      return;
    }

    getCompanyList();
  };

  //* #################################################################
  //* [ API ] 기업 리스트 가져오기
  //* #################################################################
  const getCompanyList = async () => {
    try {
      const { data } = await getMatchingCompanyList({
        option: searchOption ? searchOption.value : '',
        search: searchKeyword,
        page,
      });

      if (data.code === 0) {
        const info = data.data;

        setPageCnt(info.totalPages);
        setCompanyList([...info.list]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getCompanyList();

    return () => {};
  }, [page]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="admEdu-system-comp" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>기업관리</h5>
            </div>
          </section>

          <Card>
            <Card.Body>
              <div className="search-box mb-4">
                <div className="select-inside-box">
                  <CustomSelect
                    options={COMPANY_SEARCH}
                    value={searchOption}
                    onChange={setSearchOption}
                    placeholder="검색조건"
                  />
                </div>
                <div className="input-has-btn ms-2">
                  <Form.Control
                    type="text"
                    placeholder="검색어를 입력하세요."
                    className="input-search border-0"
                    value={searchKeyword}
                    onChange={e => setSearchKeyword(e.target.value.trim())}
                  />
                  <Button size="sm" variant="primary" onClick={searchHandler}>
                    검색
                  </Button>
                </div>
              </div>
              <Table className="bt-none type-narrow">
                <colgroup>
                  <col width={60} />
                  <col width={100} />
                  <col width={100} />
                  <col width="*" />
                  <col width={80} />
                  <col width={80} />
                  <col width={160} />
                  <col width={130} />
                  <col width={80} />
                  <col width={120} />
                  <col width={80} />
                  <col width={120} />
                </colgroup>

                {/* 헤더 */}
                <thead>
                  <tr>
                    <th rowSpan={2}>순번</th>
                    <th rowSpan={2}>구분</th>
                    <th rowSpan={2}>사업자번호</th>
                    <th rowSpan={2}>업체명</th>
                    <th colSpan={2}>취업형태</th>
                    <th rowSpan={2}>표준직업분류</th>
                    <th rowSpan={2}>연봉</th>
                    <th rowSpan={2}>현장실습 건수</th>
                    <th rowSpan={2}>실습 후 동일기업 취업여부</th>
                    <th rowSpan={2}>평가</th>
                    <th rowSpan={2}>등급</th>
                  </tr>
                  <tr>
                    <th>업종</th>
                    <th>직무</th>
                  </tr>
                </thead>

                {/* 리스트 */}
                <tbody>
                  {companyList.length > 0 ? (
                    companyList.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={`company-${index}`}
                            onClick={e =>
                              props.history.push(
                                `/teacher/matchingInfo/comp/report/${item.seq}`,
                              )
                            }
                          >
                            {/* 순번 */}
                            <td rowSpan={2}>{item.seq}</td>

                            {/* 구분 */}
                            <td rowSpan={2}>{item.gubunHan}</td>

                            {/* 사업자번호 */}
                            <td rowSpan={2}>{item.number}</td>

                            {/* 업체명 */}
                            <td rowSpan={2} className="text-start">
                              {item.name}
                            </td>

                            {/* 취업형태 */}
                            <td colSpan={2}>{item.applyGubunHan}</td>

                            {/* 표준직업분류 */}
                            <td rowSpan={2}>{item.jobStandard}</td>

                            {/* 연봉 */}
                            <td rowSpan={2}>{item.paymentHan}</td>

                            {/*  */}
                            <td rowSpan={2}>{item.internCnt}</td>

                            {/* 실습 후 동일기업 취업여부 */}
                            <td rowSpan={2}>{item.directYn}</td>

                            {/* 평가 */}
                            <td rowSpan={2}>{item.value}</td>

                            {/* 등급 */}
                            <td rowSpan={2}>{item.grade}</td>
                          </tr>

                          <tr
                            onClick={e =>
                              props.history.push(
                                `/teacher/matchingInfo/comp/report/${item.seq}`,
                              )
                            }
                          >
                            {/* 업종 */}
                            <td>{item.applyField}</td>

                            {/* 직무 */}
                            <td>{item.applyJob}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={12}>
                        <p className="no-data">데이터가 없습니다.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {/* 페지네이션 */}
              <ReactPaginate
                pageRangeDisplayed={3}
                nextLabel=""
                previousLabel=""
                breakLabel="..."
                pageCount={pageCnt}
                renderOnZeroPageCount={null}
                containerClassName="pagination mt-4"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
                onPageChange={e => {
                  setPage(e.selected + 1);
                }}
                forcePage={page ? page - 1 : 0}
              />
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});
