/**
 *   [ 마이페이지 API ]
 *
 */
import Axios from '@api/axios';

const API_TEACHER = 'api/v1/teacher';

const TEACHER_INFO = `${API_TEACHER}/teacher-info`;
const UPDATE_TEACHER_PASSWORD = `${API_TEACHER}/update-teacher-password`;
const CHANGE_STATIC_IP_YN = `${API_TEACHER}/change-static-ip-yn`;

// 선생님 마이페이지 조회
export const selectTeacherInfo = () => {
  return Axios.post(`${TEACHER_INFO}`);
};

// 선생님 비밀번호 변경
export const updateTeacherPassword = data => {
  return Axios.post(`${UPDATE_TEACHER_PASSWORD}`, data);
};

// 고정IP 사용유무 변경
export const updateTeacherStaticIpYn = data => {
  return Axios.post(`${CHANGE_STATIC_IP_YN}`, data);
};
