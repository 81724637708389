/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';

//* CONST
import { ROLE, PROGRAM_ID, FormStatus, LOGIN_TYPE } from '@common/consts';

//* API
import {
  selectFormMajorJobEduAll,
  upsertFormMajorJobEdu,
} from '@api/student/majorJobEdu';
import { selectFormMajorJobEduAllByTeacher } from '@api/teacher/majorJobEdu';

//* Utils
import { Utils } from '@common';
import Moment from 'react-moment';
import Swal from 'sweetalert2';

//* [ Main ]
export default React.memo(function MajorJobEdu(props) {
  const history = useHistory();
  const [showReportList, setShowReportList] = useState([]);
  const [summaryPoint, setSummaryPoint] = useState(0);

  const { loginType, studentIdx, pageType } = useParams();

  const authType = props.userInfo.type;

  if (loginType !== LOGIN_TYPE.STUDENT && loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  // 제대로 된 페이지로 들어왔는지 확인
  const pageTypes = ['basic', 'advanced', 'applied'];
  if (!pageTypes.includes(pageType)) {
    history.replace('/home');
    return false;
  }

  // 페이지별 학년 구분
  const getPageGrade = type => {
    switch (type) {
      case 'basic':
        return 1;
      case 'advanced':
        return 2;
      case 'applied':
        return 3;
      default:
        return null;
    }
  };

  // 프로그램 ID
  const programId = PROGRAM_ID[`PROGRAM_ID_FME_${getPageGrade(pageType)}`];

  //* #################################################################
  // * [API] 취업역량강화 결과 보고서 리스트 조회
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const selectMajorJobEduList = async () => {
    const params = {
      program_id: programId,
    };
    try {
      const { data } = await selectFormMajorJobEduAll(params);

      if (data.code === 0) {
        setFirstCallDone(true);
        setShowReportList(data.data.list);
        setSummaryPoint(data.data.point);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [API] 취업역량강화 결과 보고서 리스트 조회
  //* #################################################################
  const selectMajorJobEduListByTeacher = async () => {
    const params = {
      student_idx: studentIdx,
      program_id: programId,
    };
    try {
      const { data } = await selectFormMajorJobEduAllByTeacher(params);

      if (data.code === 0) {
        setFirstCallDone(true);
        setShowReportList(data.data.list);
        setSummaryPoint(data.data.point);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 취업역량 강화 보고서 수정/삭제
  //* #################################################################
  const updateReport = async (event, reqStatus, seq) => {
    const target = event.currentTarget;
    target.disabled = true;

    if (
      reqStatus === FormStatus.DEL.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    if (
      reqStatus === FormStatus.ING.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    // FormData
    const formData = new FormData();

    // JSON 파라미터
    const params = {
      formSeq: seq,
      status: reqStatus,
      program_id: programId,
    };

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    // Axios
    try {
      const { data } = await upsertFormMajorJobEdu(formData);
      if (data.code === 0) {
        window.location.reload();
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ Components ] HTML 생성
  //* #################################################################
  // 타입에 따른 버튼 생성
  const getManageButton = item => {
    switch (item.status) {
      case FormStatus.REQ.value:
        return (
          <div className="btn-area">
            <Button
              size="sm"
              variant="primary"
              onClick={e => {
                e.stopPropagation();
                updateReport(e, FormStatus.ING.value, item.seq);
              }}
            >
              승인요청 취소
            </Button>
          </div>
        );
      case FormStatus.CON.value:
        return '';
      case FormStatus.ING.value:
        return (
          <div className="btn-area">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={e => {
                e.stopPropagation();
                updateReport(e, FormStatus.DEL.value, item.seq);
              }}
            >
              삭제
            </Button>
            <Button
              onClick={e => {
                e.stopPropagation();
                history.push({
                  pathname: `/student/majorJobEdu/write/${pageType}/${item.seq}`,
                });
              }}
              size="sm"
              variant="primary"
            >
              수정
            </Button>
          </div>
        );
      case FormStatus.REJ.value:
        return (
          <div className="btn-area">
            <Button
              onClick={e => {
                e.stopPropagation();
                history.push({
                  pathname: `/student/majorJobEdu/write/${pageType}/${item.seq}`,
                });
              }}
              size="sm"
              variant="primary"
            >
              수정
            </Button>
          </div>
        );
      default:
        return <div />;
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    return () => {
      setShowReportList([]);
    };
  }, []);

  useEffect(() => {
    if (loginType === LOGIN_TYPE.STUDENT) {
      selectMajorJobEduList();
    }
    if (loginType === LOGIN_TYPE.TEACHER) {
      selectMajorJobEduListByTeacher();
    }
  }, [pageType, loginType]);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-majorJobEdu">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">
                직무
                {pageType === 'basic' && '기초'}
                {pageType === 'advanced' && '심화'}
                {pageType === 'applied' && '응용'}
                교육
              </Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>
                직무
                {pageType === 'basic' && '기초'}
                {pageType === 'advanced' && '심화'}
                {pageType === 'applied' && '응용'}
                교육
              </h5>
              {loginType === LOGIN_TYPE.STUDENT && (
                <Button
                  onClick={() =>
                    props.history.push(`/student/majorJobEdu/write/${pageType}`)
                  }
                  variant="primary"
                >
                  신규작성
                </Button>
              )}
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>결과 보고서</h5>
              <div className="mx-3">
                <strong>{summaryPoint} 점</strong>
              </div>
            </div>
            <Table>
              <colgroup>
                <col width={260} />
                <col width="*" />
                <col width={150} />
                {/* <col width={150} /> */}
                {loginType === LOGIN_TYPE.STUDENT && <col width={150} />}
              </colgroup>
              <thead>
                <tr>
                  <th>교육일</th>
                  <th>전공 교육명</th>
                  <th>상태</th>
                  {/* <th>점수</th> */}
                  {loginType === LOGIN_TYPE.STUDENT && <th>관리</th>}
                </tr>
              </thead>
              <tbody>
                {showReportList.length > 0
                  ? showReportList.map((item, index) => {
                      return (
                        <tr
                          key={`report-idx-${index}`}
                          className="go-detail"
                          onClick={() => {
                            if (loginType === LOGIN_TYPE.STUDENT) {
                              props.history.push(
                                `/student/majorJobEdu/report/${pageType}/${item.seq}`,
                              );
                            } else {
                              props.history.push(
                                `/teacher/studentMgmt/majorJobEdu/comment/${pageType}/${studentIdx}/${item.seq}`,
                              );
                            }
                          }}
                        >
                          {/* 교육일 */}
                          <td>
                            {item.edu_start_date && (
                              <Moment
                                format="YYYY-MM-DD"
                                date={item.edu_start_date}
                              />
                            )}
                            &nbsp; ~ &nbsp;
                            {item.edu_end_date && (
                              <Moment
                                format="YYYY-MM-DD"
                                date={item.edu_end_date}
                              />
                            )}
                          </td>

                          {/* 교육명 */}
                          <td>
                            {item.indv_yn === 'N'
                              ? item.edu_title
                              : '산업안전보건교육'}
                          </td>

                          {/* 상태 */}
                          <td className="status-text">
                            <strong
                              className={
                                FormStatus[item.status]
                                  ? FormStatus[item.status].class
                                  : ''
                              }
                            >
                              {FormStatus[item.status]
                                ? FormStatus[item.status].text
                                : ''}
                              <br />
                              {loginType === LOGIN_TYPE.TEACHER &&
                                item.status === 'CON' && (
                                  <small>
                                    {Utils.getJustDateString(item.confirm_date)}
                                  </small>
                                )}
                            </strong>
                          </td>

                          {/* <td>{item.point}</td> */}

                          {/* 관리 - 버튼 */}
                          {loginType === LOGIN_TYPE.STUDENT && (
                            <td>{getManageButton(item)}</td>
                          )}
                        </tr>
                      );
                    })
                  : firstCallDone && (
                      <tr>
                        <td
                          colSpan={loginType === LOGIN_TYPE.STUDENT ? '4' : '3'}
                          className="no-data"
                        >
                          작성한 결과 보고서가 없습니다.
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </section>

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {loginType === LOGIN_TYPE.TEACHER && (
            <div className="btn-area mb-0">
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="outline-primary"
                size="lg"
              >
                목록
              </Button>
            </div>
          )}
        </article>
      </Container>
    </main>
  );
});
