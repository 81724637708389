import React, { useEffect, useState } from 'react';
import { Container, Table, Card, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import {
  patchNextNth,
  getSystemStatus,
  putAllSystemStatus,
} from '@api/sysadmin/order';

export default React.memo(function AdmSystemOrder() {
  const [nth, setNth] = useState();
  /** API */
  const getNth = async () => {
    try {
      const { data } = await getSystemStatus();
      if (data.code === 0) {
        setNth(data.data.nth);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // 차수전환
  const changeNth = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    if (
      !(await Swal.fire({
        text: '전환된 차수는 되돌릴 수 없습니다. 차수를 전환하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }
    try {
      const { data } = await patchNextNth();
      if (data.code === 0) {
        Swal.fire({
          text: '차수가 전환되었습니다.',
          confirmButtonText: '확인',
          reverseButtons: true,
        }).then(result => {
          if (result.isConfirmed) window.location.reload();
        });
      }
    } catch (e) {
      // console.log(e);
    }

    target.disabled = false;
  };
  // Redis 업데이트
  const updateRedis = async event => {
    const target = event.currentTarget;
    target.disabled = true;

    try {
      const { data } = await putAllSystemStatus();
      if (data.code === 0) {
        Swal.fire({
          text: '시스템 상태에 대한 Redis 업데이트가 완료되었습니다.',
          confirmButtonText: '확인',
          reverseButtons: true,
        });
      }
    } catch (e) {
      // console.log(e);
    }
    target.disabled = false;
  };

  /** useEffect */
  useEffect(() => {
    getNth();
  }, []);

  return (
    <main id="admSystem-order" className="type-02 admSystem">
      <Container>
        <article className="content py">
          <section>
            <Card>
              <Card.Body>
                <Table className="bt-none">
                  <colgroup>
                    <col width={400} />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>차수</th>
                      <td>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>{nth && <p>{nth}차</p>}</div>
                          <Button onClick={changeNth}>차수전환</Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <div className="btn-area justify-content-start">
              <Button onClick={updateRedis}>Redis 업데이트</Button>
            </div>
          </section>
        </article>
      </Container>
    </main>
  );
});
