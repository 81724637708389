/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import Swal from 'sweetalert2';

const baseURL = process.env.REACT_APP_API_URL;

/* ****************************************************** */
// HTTP URL, Request Header 설정
/* ****************************************************** */
const REST = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

/* ****************************************************** */
// API > Header > Access Token 주입
/* ****************************************************** */
REST.interceptors.request.use(
  async config => {
    const originalRequest = config;

    try {
      // 토큰 확인
      const accessToken = localStorage.getItem('access_token');

      // 토큰 주입
      if (accessToken) {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch (err) {
      console.log('Failed to set Access Token');
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/* ****************************************************** */
// API > Resposne 전처리
/* ****************************************************** */
REST.interceptors.response.use(
  //* OK
  response => {
    return response;
  },
  //* ERROR
  async error => {
    try {
      const { response } = error;

      //* Response Type == Blob
      if (
        response.data instanceof Blob &&
        response.data.type === 'application/json'
      ) {
        const responseData = await response.data.text();
        const responseJson =
          typeof responseData === 'string'
            ? JSON.parse(responseData)
            : responseData;
        if (responseJson && responseJson.message) {
          let errorMessage = '';
          if (response.error) {
            errorMessage = responseJson.error;
          }
          errorMessage += responseJson.message;

          // API 에러코드 제거
          errorMessage =
            errorMessage.split(']').length > 1
              ? errorMessage.split(']')[1].trim()
              : errorMessage;

          // 전역 알림 제외 코드
          const exceptCodes = [2009];
          if (exceptCodes.includes(window.parseInt(responseJson.code))) {
            return Promise.reject(error);
          }

          //* 에러 코드 > 로그아웃 후처리
          /*
            1101 : 토큰이 만료되었습니다.
            1104 : 로그인이 필요합니다.
            1106 : 토큰값이 맞지 않습니다.
            1100 : IP주소가 변경되었습니다. 다시 로그인해 주세요.
            6003 : 반이 배정되지 않은 담임 선생입니다.
          */
          const logoutCodes = [1101, 1104, 1106, 1110, 6003];

          if (logoutCodes.includes(+response.data.code)) {
            return Promise.reject(
              await Swal.fire({
                html: errorMessage,
                confirmButtonText: '확인',
              }).then(result => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');

                window.location.href = '/'; // 로그인 화면 이동
                return error;
              }),
            );
          }
          //* 에러 코드
          /*
            로그인 오입력 횟수
              - 2007 : 로그인 오입력 횟수
          */
          if (response.data.code === 2007) {
            errorMessage = errorMessage.replace('{0}', response.data.data);
          }
          /*
            기업등록 파일업로드 후처리
              - 7211 ~ 7231 : 파일정보 누락 & 오입력
          */
          if (+response.data.code >= 7211 && +response.data.code <= 7231) {
            errorMessage = errorMessage.replace('{0}', response.data.data);
          }
          /*
            권한 접근 제한
              - 3006 : 정보 조회 거부
          */
          if (+response.data.code === 3006) {
            return Promise.reject(
              await Swal.fire({
                html: errorMessage,
                confirmButtonText: '확인',
              }).then(result => {
                if (result.isConfirmed) history.go(-1);
              }),
            );
          }

          //* 에러 코드 > 일반 팝업
          Swal.fire({
            html: errorMessage,
            confirmButtonText: '확인',
          });
        }
      }

      //* HTTP Status >= 400
      if (response.status >= 400) {
        if (response.data && response.data.message) {
          let errorMessage = '';
          if (response.data.error) {
            errorMessage = response.data.error;
          }
          errorMessage += response.data.message;

          // 전역 알림 제외 코드
          const exceptCodes = [2009];
          if (exceptCodes.includes(window.parseInt(+response.data.code))) {
            return Promise.reject(error);
          }

          // API 에러코드 제거
          errorMessage =
            errorMessage.split(']').length > 1
              ? errorMessage.split(']')[1].trim()
              : errorMessage;

          //* 에러 코드 > 로그아웃 후처리
          /*
            1101 : 토큰이 만료되었습니다.
            1104 : 로그인이 필요합니다.
            1106 : 토큰값이 맞지 않습니다.
            1100 : IP주소가 변경되었습니다. 다시 로그인해 주세요.
            6003 : 반이 배정되지 않은 담임 선생입니다.
          */
          const logoutCodes = [1101, 1104, 1106, 1110, 6003];

          if (logoutCodes.includes(+response.data.code)) {
            return Promise.reject(
              await Swal.fire({
                html: errorMessage,
                confirmButtonText: '확인',
              }).then(result => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');

                window.location.href = '/'; // 로그인 화면 이동
                return error;
              }),
            );
          }
          //* 에러 코드
          /*
            로그인 오입력 횟수
              - 2007 : 로그인 오입력 횟수
          */
          if (response.data.code === 2007) {
            errorMessage = errorMessage.replace('{0}', response.data.data);
          }
          //* 에러 코드 > 기업등록 파일업로드 후처리
          /*
            7211 ~ 7231 : 파일정보 누락 & 오입력
          */
          if (+response.data.code >= 7211 && +response.data.code <= 7231) {
            errorMessage = errorMessage.replace('{0}', response.data.data);
          }
          /*
            권한 접근 제한
              - 3006 : 정보 조회 거부
          */
          if (+response.data.code === 3006) {
            return Promise.reject(
              await Swal.fire({
                html: errorMessage,
                confirmButtonText: '확인',
              }).then(result => {
                if (result.isConfirmed) history.go(-1);
              }),
            );
          }

          //* 에러 코드 > 일반 팝업
          Swal.fire({
            html: errorMessage,
            confirmButtonText: '확인',
          });
        }
      }
    } catch (err) {
      console.log(err);
    }

    return Promise.reject(error);
  },
);

export default REST;
