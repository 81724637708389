/**
 *   [ 기초직업 능력 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const SELECT_ABILITY_BASIC = `${API_PROGRAM}/ability-test-basic-info`;
const SELECT_ABILITY_FORM = `${API_PROGRAM}/ability-test-info`;
const SELECT_ABILITY_LIST = `${API_PROGRAM}/ability-test-list`;
const INSERT_UPDATE_ABILITY = `${API_PROGRAM}/ability-test`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 직업기초 능력평가 > [ SELECT ] 개인정보
export const selectFormAbilityBasicInfo = () => {
  return Axios.get(`${SELECT_ABILITY_BASIC}`);
};

// 직업기초 능력평가 > [ SELECT ] 개인정보 & Form 데이터
export const selectFormAbilityInfo = param => {
  return Axios.post(`${SELECT_ABILITY_FORM}`, param);
};

// 직업기초 능력평가 > [ SELECT ] 리스트
export const selectFormAbilityList = param => {
  return Axios.get(`${SELECT_ABILITY_LIST}`, param);
};

// 직업기초 능력평가 > [ INSERT, UPDATE ] 신규등록, 임시저장, 삭제, 취소
export const insertUpdateAbilityTest = param => {
  return Axios.post(`${INSERT_UPDATE_ABILITY}`, param, formDataConfig);
};
