/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Card,
  Form,
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'react-moment';
import Swal from 'sweetalert2';

//* Utils
import Utils from '@common/Utils';

//* CONSTS
import {
  FormStatus,
  PROGRAM_ID,
  RESEARCH_POINT_TYPE_LIST,
} from '@common/consts';

//* API
import {
  selectFormMajorJobEduInfo,
  upsertFormMajorJobEdu,
} from '@api/student/majorJobEdu';
import { CustomSelect } from '../../../components';
import { COMPLETE_TIME, COMPLETE_TIME_10 } from '../../../common/consts';

// FORM 정보
const initialFormInfo = {
  educated_time: '',
  id: '',
  grade: '',
  status: 'NONE',
  point: '0',
  student_id: '',
  student_name: '',
  program_id: '',
  indv_yn: 'N',
  edu_start_date: new Date(),
  edu_end_date: new Date(),
  edu_title: '',
  edu_contents: '',
  edu_review: '',
  edu_time: '',
  files: [],
};

//* Main
export default React.memo(function MajorJobEduReport(props) {
  //* #################################################################
  //* [ State ]
  //* #################################################################
  const history = useHistory();

  const { pageType, seq } = useParams();

  // type
  const pageTypes = ['basic', 'advanced', 'applied'];
  if (!pageTypes.includes(pageType)) {
    history.replace('/home');
    return false;
  }
  if (seq && !window.parseInt(seq)) {
    history.replace('/home');
  }

  // 페이지별 학년 구분
  const getPageGrade = type => {
    switch (type) {
      case 'basic':
        return 1;
      case 'advanced':
        return 2;
      case 'applied':
        return 3;
      default:
        return null;
    }
  };

  /** form state */
  // 보고서 정보 / 학생정보
  const [reportInfo, setReportInfo] = useState(initialFormInfo);

  // 산업안전보건교육 체크
  const [indvYn, setIndvYn] = useState('N');

  // datepicker
  const [eduStartDate, setEduStartDate] = useState(new Date());
  const [eduEndDate, setEduEndDate] = useState(new Date());

  // 교육 시간
  const [eduTime, setEduTime] = useState('');

  // radio
  const [researchRadioResult, setResearchRadioResult] = useState({});

  // 교사 코멘트
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  // 리서치 정보
  const [researchList, setResearchList] = useState([]);

  // 프로그램 제목
  const [title, setTitle] = useState('');

  // 프로그램 내용
  const [contents, setContents] = useState('');

  // 참여 후 느낀 점
  const [review, setReview] = useState('');

  // 프로그램 ID
  const programId = PROGRAM_ID[`PROGRAM_ID_FME_${getPageGrade(pageType)}`];

  // 파일
  const [files, setFiles] = useState([]);
  const [initialFiles, setInitalFiles] = useState([]); // 첨부 파일 초기값 (수정 시, 사용)
  const selectFile = useRef('');

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 산업안전보건교육 체크
  const indvCheckHandler = e => {
    setIndvYn(e.target.checked ? 'Y' : 'N');

    // 입력값 초기화
    setEduStartDate(new Date());
    setEduEndDate(new Date());
    setEduTime('');
    setTitle('');
    setContents('');
    setReview('');
    // setResearchRadioResult({});
  };

  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    selectFile.current.value = null;
  };

  // 파일 삭제
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  // 파일 드래그 앤 드랍 :: 파일 클래스명 처리
  const fileDragHover = e => {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.className =
      e.type === 'dragover' ? 'drag-box hover' : 'drag-box';
  };

  // 파일 드래그 앤 드랍 :: 파일 처리
  const fileSelectHandler = e => {
    // cancel event and hover styling
    fileDragHover(e);

    // fetch FileList object
    const insertFiles = e.target.files || e.dataTransfer.files;
    const totlaFilesLength = files.length;
    const insertFilesLength = insertFiles.length;

    if (totlaFilesLength >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }
    if (totlaFilesLength + insertFilesLength > 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    setFiles([...files, ...insertFiles]);
  };

  // 입력 제한 조건 체크
  const inputConditionCheck = () => {
    if (
      !Utils.limitCheck(contents, 0, 1000) ||
      !Utils.limitCheck(review, 0, 1000)
    ) {
      return false;
    }
    // 리서치 조사를 한 경우에 대해서만 값 체크
    const researchKeys = Object.keys(researchRadioResult);
    if (researchKeys.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of researchKeys) {
        if (
          researchRadioResult[key] &&
          !Utils.rangeCheck(researchRadioResult[key], 1, 5)
        )
          return false;
      }
    }

    return true;
  };

  // research_idx, research_response 문자열 생성
  const getResearchResponse = () => {
    const result = {};
    const researchKeys = Object.keys(researchRadioResult);
    if (researchKeys.length > 0) {
      result.research_idx = researchKeys.join(';');
      const resultValueList = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of researchKeys) {
        resultValueList.push(researchRadioResult[key]);
      }
      result.research_response = resultValueList.join(';');
    }
    return result;
  };

  //* #################################################################
  //* [ API ] 전공기초교육 보고서 조회, 리서치 목록 조회, 내정보 조회
  //* #################################################################
  const selectReportInfo = async () => {
    try {
      const params = {
        formSeq: seq || '0',
        program_id: programId,
      };
      const { data } = await selectFormMajorJobEduInfo(params);
      if (data.code === 0) {
        // null이 반환되었다면 잘못된 요청으로 판단한다.
        if (!data.data) history.goBack();

        // 학생 정보, 보고서 정보
        const { info } = data.data;

        setReportInfo(info);
        if (seq) {
          // 보고서 정보 세팅
          setIndvYn(info.indv_yn);
          if (info.edu_start_date)
            setEduStartDate(
              new Date(Utils.getJustDateString(info.edu_start_date)),
            );
          if (info.edu_end_date)
            setEduEndDate(new Date(Utils.getJustDateString(info.edu_end_date)));
          setTitle(info.edu_title);
          setContents(info.edu_contents);
          setReview(info.edu_review);
          setEduTime(info.edu_time);
        }

        // 리서치 값 세팅
        // eslint-disable-next-line camelcase
        const researchIdx = info.research_idx;
        const researchResponse = info.research_response;
        // eslint-disable-next-line camelcase
        if (researchIdx && researchResponse) {
          const idxs = researchIdx.split(';');
          const responses = researchResponse.split(';');
          const researchObject = {};
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in idxs) {
            researchObject[`${idxs[i]}`] = responses[i];
          }
          setResearchRadioResult(researchObject);
        }

        // 교사 코멘트 리스트
        setTeacherCommentList(data.data.teacherCommentList);

        // 리서치 목록 구성
        setResearchList(data.data.researchList);

        // 파일 갱신
        setFiles([...info.files]);
        setInitalFiles([...info.files]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 전공기초교육 보고서 추가
  //* #################################################################
  const upsertReport = async (event, status) => {
    const target = event.currentTarget;
    target.disabled = true;
    if (!inputConditionCheck(status, true)) {
      Swal.fire({
        text: '입력 제한을 확인해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      return false;
    }

    const allFileSeq = [...initialFiles.map(file => file.file_seq)];
    const saveFileSeq = [];
    const deleteFileSeq = [];

    // FormData
    const formData = new FormData();

    // 파일 append (File 타입 Only)
    for (let i = 0; i < files.length; i += 1) {
      // File 타입
      if (Object.getPrototypeOf(files[i]) === File.prototype) {
        formData.append('files', files[i]);
      }
      // Object 타입
      else {
        saveFileSeq.push(files[i].file_seq);
      }
    }

    // 삭제 파일 Seq 추출 (기존파일)
    allFileSeq.forEach(fileSeq => {
      if (saveFileSeq.indexOf(fileSeq) === -1) deleteFileSeq.push(fileSeq);
    });

    // JSON 파라미터
    const params = {
      formSeq: seq || '0',
      program_id: programId,
      indv_yn: indvYn,
      edu_start_date: Utils.dateToString(eduStartDate),
      edu_end_date: Utils.dateToString(eduEndDate),
      edu_title: title,
      edu_contents: contents,
      edu_review: review,
      status,
      edu_time: eduTime.value,
      deleteFileSeq,
      ...getResearchResponse(),
    };

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    // Axios
    try {
      const { data } = await upsertFormMajorJobEdu(formData);
      if (data.code === 0) {
        history.push({
          pathname: `/student/majorJobEdu/report/${pageType}/${
            data.data.formSeq || seq
          }`,
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }
    target.disabled = false;
    return true;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    selectReportInfo();

    return () => {
      setResearchList([]);
      setReportInfo({});
      setTeacherCommentList([]);
      setResearchList([]);
      setEduStartDate(new Date());
      setEduEndDate(new Date());
      setEduTime(null);
    };
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-majorJobEdu-write">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to={`/student/majorJobEdu/${pageType}`}>
                직무
                {pageType === 'basic' && '기초'}
                {pageType === 'advanced' && '심화'}
                {pageType === 'applied' && '응용'}
                교육
              </Link>
            </li>
            <li>
              <Link to="#none">작성페이지</Link>
            </li>
          </ul>
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>
                직무
                {pageType === 'basic' && '기초'}
                {pageType === 'advanced' && '심화'}
                {pageType === 'applied' && '응용'}교육 결과 보고서
              </h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>학번</h5>
                  <Form.Control
                    type="text"
                    defaultValue={reportInfo.student_id}
                    readOnly
                  />
                </li>
                <li>
                  <h5>이름</h5>
                  <Form.Control
                    type="text"
                    defaultValue={Utils.decrypt(reportInfo.student_name)}
                    readOnly
                  />
                </li>
              </ul>
            </Card>
          </section>

          {/* 교육정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>교육정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>산업안전보건교육</h5>
                  <div className="mw-input">
                    <Form.Check
                      type="checkbox"
                      // label="반 전체"
                      // id="gradeSnClass-all"
                      onChange={indvCheckHandler}
                      checked={indvYn === 'Y'}
                    />
                  </div>
                </li>
                <li>
                  <h5>교육일</h5>
                  <div className="mw-input">
                    <DatePicker
                      className="input-datepicker"
                      name="edu-start-date"
                      dateFormat="yyyy.MM.dd"
                      selected={eduStartDate}
                      onChange={date => setEduStartDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                      // maxDate={eduEndDate}
                      disabled={indvYn === 'Y'}
                    />
                  </div>
                  <div className="tilde">~</div>
                  <div className="mw-input">
                    <DatePicker
                      className="input-datepicker"
                      name="edu-end-date"
                      dateFormat="yyyy.MM.dd"
                      selected={eduEndDate}
                      onChange={date => setEduEndDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                      minDate={eduStartDate}
                      // maxDate={new Date()}
                      disabled={indvYn === 'Y'}
                    />
                  </div>
                </li>
                <li>
                  <h5>교육 시간</h5>
                  <div className="mw-input">
                    <CustomSelect
                      options={COMPLETE_TIME_10}
                      value={eduTime}
                      onChange={setEduTime}
                      placeholder="선택하세요."
                      disabled={indvYn === 'Y'}
                    />
                  </div>

                  {/* <Form.Control */}
                  {/*  type="text" */}
                  {/*  placeholder="교육시간을 숫자로 입력하세요." */}
                  {/*  value={eduTime || ''} */}
                  {/*  maxLength="10" */}
                  {/*  onChange={e => { */}
                  {/*    setEduTime(window.parseInt(e.target.value)); */}
                  {/*  }} */}
                  {/*  disabled={indvYn === 'Y'} */}
                  {/* /> */}
                </li>
              </ul>
            </Card>
          </section>

          {/* 전공 교육명 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>전공 교육명</h5>
            </div>
            <Card>
              <Form.Control
                type="text"
                placeholder="전공 교육명을 입력하세요."
                maxLength="50"
                onChange={e => {
                  setTitle(e.target.value);
                }}
                value={title || ''}
                disabled={indvYn === 'Y'}
              />
            </Card>
          </section>

          {/* 교육 내용 요약 및 정리 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>교육 내용 요약 및 정리</h5>
            </div>
            <Card>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                maxLength="1000"
                onChange={e => {
                  setContents(e.target.value);
                }}
                value={contents || ''}
                disabled={indvYn === 'Y'}
              />
              <small className="text-gray flex-end mt-2">
                {(contents && contents.length) || 0}/1000자
              </small>
            </Card>
          </section>

          {/* 느낀점 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>느낀점</h5>
            </div>
            <Card>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                onChange={e => {
                  setReview(e.target.value);
                }}
                value={review || ''}
                disabled={indvYn === 'Y'}
              />
              <small className="text-gray flex-end mt-2">
                {(review && review.length) || 0}/1000자
              </small>
            </Card>
          </section>

          {/* 만족도 조사 */}

          <section className="mb-5">
            <div className="sub-title">
              <h5>만족도 조사</h5>
            </div>
            <div className="split-table bt-none h-56">
              <Row className="table-row">
                <Col className="th col-2 text-gray">조사내용</Col>
                <Col className="th col-4 text-center text-gray">질문</Col>
                <Col className="th col-6">
                  <ul className="type-flex-1">
                    <li>매우 그렇다</li>
                    <li>그렇다</li>
                    <li>보통</li>
                    <li>그렇지 않다</li>
                    <li>매우 그렇지 않다</li>
                  </ul>
                </Col>
              </Row>
              {researchList &&
                researchList.length > 0 &&
                researchList.map((item, index) => {
                  return (
                    <Row className="table-row" key={`research-${index}`}>
                      <Col className="th col-2 text-gray">
                        {item.research_contents}
                      </Col>
                      <Col className="td col-4">{item.research_question}</Col>
                      <Col className="td col-6 flex-between">
                        <div className="radio-group type-flex-1">
                          {RESEARCH_POINT_TYPE_LIST.map(pointItem => {
                            return (
                              <Form.Check
                                key={`${index}-${pointItem.value}`}
                                name={`${index}`}
                                type="radio"
                                label=""
                                value={pointItem.value}
                                id={pointItem.value}
                                onChange={e => {
                                  setResearchRadioResult({
                                    ...researchRadioResult,
                                    [item.seq]: e.target.value,
                                  });
                                }}
                                checked={
                                  researchRadioResult[item.seq] ===
                                  pointItem.value
                                }
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </section>

          {/* 파일 첨부 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>파일 첨부</h5>
              <Button
                onClick={() => selectFile.current.click()}
                variant="outline-primary"
                size="md"
              >
                파일첨부
              </Button>
              <Form.Control
                ref={selectFile}
                onChange={addFile}
                type="file"
                // accept="image/*, .pdf"
                style={{ display: 'none' }}
              />
            </div>
            <Card>
              {files && files.length > 0 ? (
                // ↓ 첨부된 파일
                <ul className="drag-attachment-list">
                  {files.map((file, idx) => {
                    return (
                      <li key={idx}>
                        <p>{file.name || file.file_name_org}</p>
                        <Button variant="iconOnly">
                          <Image
                            src={images.icCancel}
                            onClick={() => removeFile(idx)}
                          />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                // ↓ 파일 첨부
                <div
                  className="drag-box"
                  draggable
                  onDragOver={e => {
                    fileDragHover(e);
                  }}
                  onDragLeave={e => {
                    fileDragHover(e);
                  }}
                  onDrop={e => {
                    fileSelectHandler(e);
                  }}
                >
                  <p>파일을 마우스로 끌어 오세요.</p>
                </div>
              )}
            </Card>
          </section>

          {/* 교사 코멘트 */}
          {(reportInfo.status === FormStatus.CON.value ||
            reportInfo.status === FormStatus.REJ.value) && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5 className="text-primary">교사 코멘트</h5>
              </div>
              <div className="comment-box type-primary">
                <ul className="comment-list">
                  {teacherCommentList.length > 0 &&
                    teacherCommentList.map((item, index) => {
                      return (
                        <li key={`comment-${index}`}>
                          <p className="date">
                            {item.reg_date && (
                              <Moment
                                format="YYYY.MM.DD"
                                date={item.reg_date}
                              />
                            )}
                          </p>
                          <p className="text">{item.comment}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}

          {/* ////////// ↓ 하단 버튼(신규작성/임시저장) //////////  */}
          <div className="btn-area">
            <Button
              size="lg"
              variant="outline-primary"
              onClick={e => {
                upsertReport(e, FormStatus.ING.value);
              }}
              disabled={!inputConditionCheck()}
            >
              저장
            </Button>
          </div>
        </article>
      </Container>
    </main>
  );
});
