/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { Utils } from '@common';
import {
  selectStudentInfo,
  updatePw,
  updateStudentInfo,
} from '@api/student/mypage';
import Swal from 'sweetalert2';

export default React.memo(function MypageEdit(props) {
  const history = useHistory();

  const { type: typeParam } = useParams();

  // type
  const [viewType, setViewType] = useState(typeParam);

  // studentInfo
  const [studentInfo, setStudentInfo] = useState({});

  /** form state */
  // 주소
  const [address, setAddress] = useState();
  // 주소 상세
  const [addressDetail, setAddressDetail] = useState();
  // 생일
  const [birthDate, setBirthDate] = useState(new Date());
  // 연락처
  const [phoneNo1, setPhoneNo1] = useState();
  const [phoneNo2, setPhoneNo2] = useState();
  const [phoneNo3, setPhoneNo3] = useState();

  // 이메일
  const [email, setEmail] = useState();

  // 현재 비밀번호
  const [curPw, setCurPw] = useState();
  // 새 비밀번호
  const [newPw, setNewPw] = useState();
  // 비밀번호 확인
  const [newPwCheck, setNewPwCheck] = useState();

  /** API */
  const getMyInfo = async () => {
    try {
      const { data } = await selectStudentInfo();
      if (data.code === 0) {
        setStudentInfo(data.data);
        setAddress(Utils.decrypt(data.data.addr1));
        setAddressDetail(Utils.decrypt(data.data.addr2));
        if (data.data.student_birth) {
          setBirthDate(
            new Date(
              Utils.dateHypenFormat(Utils.decrypt(data.data.student_birth)),
            ),
          );
        }
        if (data.data.phone_no) {
          const phoneNo = Utils.decrypt(data.data.phone_no);

          const no1 = phoneNo.substring(0, 3);
          const no2 = phoneNo.substring(3, phoneNo.length - 4);
          const no3 = phoneNo.substring(phoneNo.length - 4);
          setPhoneNo1(no1);
          setPhoneNo2(no2);
          setPhoneNo3(no3);
        }
        if (data.data.e_mail) {
          setEmail(Utils.decrypt(data.data.e_mail));
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // 비밀번호 변경
  const changePw = async event => {
    const target = event.currentTarget;
    target.disabled = true;

    // 필수 값 및 비밀번호 형식 체크
    if (!curPw) {
      Swal.fire({
        text: '현재 비밀번호를 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }
    if (!newPw) {
      Swal.fire({
        text: '새 비밀번호를 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }
    if (!newPwCheck) {
      Swal.fire({
        text: '비밀번호 확인을 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }
    if (newPw !== newPwCheck) {
      Swal.fire({
        text: '새 비밀번호와 비밀번호 확인의 값이 다릅니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }
    if (!Utils.pwRuleCheck(newPw)) {
      Swal.fire({
        text: '비밀번호 형식을 확인해주시기 바랍니다. 비밀번호는 대소문자, 특수문자, 숫자 포함 8 ~ 20자리 문자열입니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // 비밀번호 변경
    const params = {
      pw: Utils.encrypt(curPw),
      newPw: Utils.encrypt(newPw),
    };
    try {
      const { data } = await updatePw(params);
      if (data.code === 0) {
        // 재로그인 요청
        Swal.fire({
          text: '비밀번호가 변경되었습니다. 다시 로그인해 주시기 바랍니다.',
          confirmButtonText: '확인',
        }).then(result => {
          if (result.isConfirmed) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/'; // 로그인 화면 이동
          }
        });
      }
    } catch (e) {
      target.disabled = false;
    }
    target.disabled = false;
  };

  const upsertMyInfo = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    if (!phoneNo1 || !phoneNo2 || !phoneNo3) {
      Swal.fire({
        text: '연락처를 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }
    // 휴대폰 번호 체크
    if (!Utils.phoneNumberTest(phoneNo1 + phoneNo2 + phoneNo3)) {
      Swal.fire({
        text: '연락처 형식을 확인해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // 이메일 체크
    if (email && !Utils.emailTest(email)) {
      Swal.fire({
        text: '이메일 형식을 확인해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    const params = {
      addr1: Utils.encrypt(address),
      addr2: Utils.encrypt(addressDetail),
      student_birth: Utils.encrypt(Utils.dateToString(birthDate)),
      phone_no: Utils.encrypt(phoneNo1 + phoneNo2 + phoneNo3),
      e_mail: Utils.encrypt(email),
    };

    try {
      const { data } = await updateStudentInfo(params);
      if (data.code === 0) {
        history.push('/student/mypage');
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }

    target.disabled = false;
  };

  /** Daum 주소검색 API */
  const open = useDaumPostcodePopup();

  const handleComplete = data => {
    let fullAddress = data.address;
    let extraAddress = '';

    // R : 도로명, J : 지번
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    // console.log(data.zonecode); // 우편번호
    setAddress(fullAddress);
  };

  const searchAddress = () => {
    open({
      onComplete: handleComplete,
      defaultQuery: address,
    });
  };

  /** useEffect */
  useEffect(() => {
    getMyInfo();
  }, []);

  return (
    <main id="mypage-write">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">마이페이지</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>마이페이지</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>학교정보</h5>
            </div>
            <div className="split-table type-simple">
              <Row className="table-row">
                <Col className="th col-2">학교명</Col>
                <Col className="td col-10">{studentInfo.school_name}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">이름</Col>
                <Col className="td col-10">
                  {Utils.decrypt(studentInfo.student_name)}
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">성별</Col>
                <Col className="td col-10">{studentInfo.sex}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">전공</Col>
                <Col className="td col-10">{studentInfo.student_depart}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">학년/반/번호</Col>
                <Col className="td col-10">
                  {studentInfo.grade}학년{' '}
                  {studentInfo.ban_no && window.parseInt(studentInfo.ban_no)}반{' '}
                  {studentInfo.student_no &&
                    window.parseInt(studentInfo.student_no)}
                  번
                </Col>
              </Row>
            </div>
          </section>

          {/* 인적사항 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>인적사항</h5>
            </div>
            <div className="split-table type-simple">
              <Row className="table-row">
                <Col className="th col-2">주소</Col>
                <Col className="td col-10">
                  <div className="input-has-btn">
                    <Form.Control
                      type="text"
                      placeholder="주소를 검색하세요."
                      className="input-search"
                      value={address || ''}
                      onChange={e => {
                        setAddress(e.target.value);
                      }}
                      maxLength="50"
                    />
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={e => {
                        searchAddress();
                      }}
                    >
                      검색
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">상세주소</Col>
                <Col className="td col-10">
                  <Form.Control
                    type="text"
                    placeholder="상세주소를 입력하세요."
                    value={addressDetail || ''}
                    onChange={e => {
                      setAddressDetail(e.target.value);
                    }}
                    maxLength="50"
                  />
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">생일</Col>
                <Col className="td col-10">
                  <div className="mw-input">
                    <DatePicker
                      className="input-datepicker"
                      name="birth-date"
                      dateFormat="yyyy.MM.dd"
                      selected={birthDate}
                      onChange={date => setBirthDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                      maxDate={new Date()}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">연락처</Col>
                <Col className="td col-10">
                  <div className="input-phone">
                    <Form.Control
                      type="phone"
                      placeholder="000"
                      onChange={e => {
                        if (
                          window.parseInt(e.target.value) ||
                          window.parseInt(e.target.value) === 0 ||
                          e.nativeEvent.inputType === 'deleteContentBackward'
                        ) {
                          setPhoneNo1(e.target.value);
                        }
                      }}
                      value={phoneNo1 || ''}
                      maxLength="3"
                    />
                    <div className="hyphen">-</div>
                    <Form.Control
                      type="phone"
                      placeholder="0000"
                      onChange={e => {
                        if (
                          window.parseInt(e.target.value) ||
                          window.parseInt(e.target.value) === 0 ||
                          e.nativeEvent.inputType === 'deleteContentBackward'
                        ) {
                          setPhoneNo2(e.target.value);
                        }
                      }}
                      value={phoneNo2 || ''}
                      maxLength="4"
                    />
                    <div className="hyphen">-</div>
                    <Form.Control
                      type="phone"
                      placeholder="0000"
                      onChange={e => {
                        if (
                          window.parseInt(e.target.value) ||
                          window.parseInt(e.target.value) === 0 ||
                          e.nativeEvent.inputType === 'deleteContentBackward'
                        ) {
                          setPhoneNo3(e.target.value);
                        }
                      }}
                      value={phoneNo3 || ''}
                      maxLength="4"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">이메일</Col>
                <Col className="td col-10">
                  <Form.Control
                    type="email"
                    placeholder="이메일을 입력하세요."
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    value={email || ''}
                  />
                </Col>
              </Row>
            </div>
          </section>

          {/* 비밀번호 */}
          <section>
            <div className="sub-title">
              <h5>비밀번호</h5>
            </div>
            <div className="split-table type-simple">
              <Row className="table-row">
                <Col className="th col-2">현재 비밀번호</Col>
                <Col className="td col-10">
                  <Form.Control
                    type="password"
                    placeholder="현재 비밀번호를 입력하세요."
                    onChange={e => {
                      setCurPw(e.target.value);
                    }}
                    value={curPw || ''}
                  />
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">새 비밀번호</Col>
                <Col className="td col-10">
                  <Form.Control
                    type="password"
                    placeholder="새 비밀번호를 입력하세요. (비밀번호는 대소문자, 특수문자, 숫자 포함 8 ~ 20자리 문자열입니다.)"
                    onChange={e => {
                      setNewPw(e.target.value);
                    }}
                    value={newPw || ''}
                  />
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">비밀번호 확인</Col>
                <Col className="td col-10">
                  <Form.Control
                    type="password"
                    placeholder="새 비밀번호를 다시 입력하여 비밀번호를 확인해주세요."
                    onChange={e => {
                      setNewPwCheck(e.target.value);
                    }}
                    value={newPwCheck || ''}
                  />
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">&nbsp;</Col>
                <Col className="td col-10">
                  <Button
                    variant="primary"
                    onClick={e => {
                      changePw(e);
                    }}
                  >
                    비밀번호 변경
                  </Button>
                </Col>
              </Row>
            </div>
          </section>
        </article>
      </Container>

      {/* ////////// ↓ 하단 버튼 //////////  */}
      <div className="btn-area">
        <Button
          size="lg"
          variant="primary"
          onClick={e => {
            upsertMyInfo(e);
          }}
        >
          저장
        </Button>
      </div>
    </main>
  );
});
