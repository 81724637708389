/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

//* API
import {
  selectEduProgramInfo,
  insertUpdateEduProgram,
} from '@api/student/selfEduProgram';
import { getFilePathFromRedis } from '@api/file';

//* Utils
import Utils from '@common/Utils';

//* CONSTS
import {
  FormStatus,
  FILE_FORM_TYPE,
  RESEARCH_POINT_TYPE_LIST,
} from '@common/consts';
import { useReactToPrint } from 'react-to-print';

// 유저 정보
const initialUserInfo = {
  user: '',
  id: '',
  grade: '',
  status: 'NONE',
  point: '0',
  name: '',
  date: '',
  gubun: '',
  title: '',
  contents: '',
  files: [],
};

//* [ Main ]
export default React.memo(function SelfEduProgramReport(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  // URL Param
  const { seq: formSeq } = useParams(); // Form SEQ

  // Form 데이터
  const [formInfo, setFormInfo] = useState(initialUserInfo);
  const [researchResult, setResearchResult] = useState({});
  // const [isValidForm, setIsValidForm] = useState(false);

  // 설문조사 Form
  const [researchFormList, setResearchFormList] = useState([]);

  // 교사 코멘트
  const [teacherComments, setTeacherComments] = useState([]);

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 인쇄
  const printWindowRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  //* #################################################################
  //* [ API ] Form 정보 가져오기
  //* #################################################################
  const getInfo = async () => {
    // 파라미터
    const param = {
      formSeq,
    };

    // Axios
    try {
      const { data } = await selectEduProgramInfo(param);

      if (data.code === 0) {
        const formData = data.data;

        // Form 갱신
        setFormInfo({
          user: formData.name,
          id: formData.id,
          grade: formData.grade,

          status: formData.status,
          point: formData.point,

          name: formData.program_name,
          date: formData.program_date,
          gubun: formData.program_gubun,
          title: formData.program_title,
          contents: formData.program_contents,
          files: formData.files || [],
        });

        // 설문조사 Form 갱신
        setResearchFormList(formData.researchList || []);

        // 설문조사 응답 갱신
        const {
          research_idx: researchIdx = '',
          research_response: resarchResponse = '',
        } = formData;

        if (researchIdx && resarchResponse) {
          const updateMap = {};

          const idxs = researchIdx.split(';'); // 설문 항목
          const responses = resarchResponse.split(';'); // 설문 항목별 입력값

          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in idxs) {
            updateMap[`${idxs[i]}`] = responses[i];
          }

          setResearchResult(updateMap);
        }

        // 교사 코멘트
        setTeacherComments([...formData.commentList]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 파일 조회
  //* #################################################################
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_FREE_PROGRAM, // = FREE_PROGRAM
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        window.open(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 요청 취소, 삭제
  //* #################################################################
  const updateRequest = async (seq, reqStatus) => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 승인요청 > 입력 검증
    if (reqStatus === 'REQ') {
      if (
        !formInfo.name ||
        !formInfo.date
        // || !Utils.checkDateTime(new Date(formInfo.date))
      ) {
        Swal.fire({
          text: '교육정보가 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      if (!formInfo.title) {
        Swal.fire({
          text: '프로그램명이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      if (!researchResult || !Object.keys(researchResult).length) {
        Swal.fire({
          text: '설문이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      // if (!formInfo.files || formInfo.files.length < 1) {
      //   Swal.fire({
      //     text: '첨부파일이 등록되지 않았습니다.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }
    }

    if (
      reqStatus === 'DEL' &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    if (
      reqStatus === 'CAN' &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    if (
      reqStatus === 'REQ' &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // 파라미터 설정
    const param = {
      formSeq: seq,
      status: reqStatus !== 'CAN' ? reqStatus : 'ING',
    };

    // FormData
    const formData = new FormData();

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(param)], { type: 'application/json' }),
    );

    // 중복요청 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await insertUpdateEduProgram(formData);

      if (data.code === 0) {
        history.push(`/student/selfEduProgram/${formInfo.grade}`);
      }
    } catch (e) {
      // 중복요청 방지 해제
      setIsSentRequest(false);

      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getInfo();
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (인쇄, 목록, 삭제, 수정, 취소)
  const customButton = (type, seq) => {
    let variant;
    let event;
    let text;

    switch (type) {
      case 'print':
        variant = 'outline-primary';
        event = () => handlePrint();
        text = '인쇄';
        break;
      case 'list':
        variant = 'primary';
        event = () => history.push(`/student/selfEduProgram/${formInfo.grade}`);
        text = '목록으로';
        break;
      case 'delete':
        variant = 'outline-primary';
        event = () => updateRequest(seq, 'DEL');
        text = '삭제';
        break;
      case 'edit':
        variant = 'primary';
        event = () =>
          props.history.push({
            pathname: `/student/selfEduProgram/write/${formInfo.grade}`,
            state: { formSeq },
          });
        text = '수정';
        break;
      case 'cancel':
        variant = 'primary';
        event = () => updateRequest(seq, 'CAN');
        text = '승인요청 취소';
        break;
      case 'request':
        variant = 'primary';
        event = () => updateRequest(seq, 'REQ');
        text = '승인요청';
        break;
      default:
        break;
    }

    return (
      <Button size="lg" variant={variant} onClick={event}>
        {text}
      </Button>
    );
  };

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-selfEduProgram-report">
      <Container>
        <article className="content" ref={printWindowRef}>
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to={`/student/selfEduProgram/3${formInfo.grade}`}>{`${
                formInfo.grade || 0
              }학년 자율 교육 프로그램`}</Link>
            </li>
            <li>
              <Link to="#none">결과 보고서</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>{`${formInfo.grade}학년 자율 교육 프로그램 결과 보고서`}</h5>
              <div className="dl-box type-bar lg">
                {/* Form 상태 */}
                <dl>
                  <strong className={FormStatus[formInfo.status].class}>
                    {FormStatus[formInfo.status].text}
                  </strong>
                </dl>
                {/* 점수 */}
                <dl>
                  <dd>
                    <strong>{formInfo.point}</strong>
                  </dd>
                </dl>
              </div>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <div className="split-table">
              {/* 학번 */}
              <Row className="table-row">
                <Col className="th col-2">학번</Col>
                <Col className="td col-10">{`${formInfo.id}`}</Col>
              </Row>
              {/* 이름 */}
              <Row className="table-row">
                <Col className="th col-2">이름</Col>
                <Col className="td col-10">{`${Utils.decrypt(
                  formInfo.user,
                )}`}</Col>
              </Row>
            </div>
          </section>

          {/* 교육정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>교육정보</h5>
            </div>
            <div className="split-table">
              <Row className="table-row">
                <Col className="th col-2">교육 프로그램</Col>
                <Col className="td col-10">{formInfo.name}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">교육일</Col>
                <Col className="td col-10">{formInfo.date}</Col>
              </Row>
            </div>
          </section>

          {/* 교육 프로그램명 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>교육 프로그램명</h5>
            </div>
            <div className="comment-box">
              <ul className="comment-list">
                <li>
                  <p className="text">{formInfo.title}</p>
                </li>
              </ul>
            </div>
          </section>

          {/* 교육내용 요약 및 정리 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>교육내용 요약 및 정리</h5>
            </div>
            <div className="comment-box">
              <ul className="comment-list">
                <li>
                  <p className="text">{formInfo.contents}</p>
                </li>
              </ul>
            </div>
          </section>

          {/* 만족도 조사 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>만족도 조사</h5>
            </div>
            <div className="split-table h-56">
              <Row className="table-row">
                <Col className="th col-2 text-gray">조사내용</Col>
                <Col className="th col-4 text-center text-gray">질문</Col>
                <Col className="th col-6">
                  <ul className="type-flex-1">
                    <li>매우 그렇다</li>
                    <li>그렇다</li>
                    <li>보통</li>
                    <li>그렇지 않다</li>
                    <li>매우 그렇지 않다</li>
                  </ul>
                </Col>
              </Row>
              {researchFormList.length > 0 &&
                researchFormList.map((item, idx) => {
                  return (
                    <Row className="table-row" key={idx}>
                      {/* 조사내용 */}
                      <Col className="th col-2">{item.research_contents}</Col>
                      {/* 질문 */}
                      <Col className="td col-4">{item.research_question}</Col>
                      {/* 만족도 입력 */}
                      <Col className="td col-6 flex-between">
                        <div className="radio-group type-flex-1">
                          {RESEARCH_POINT_TYPE_LIST.map((pointItem, index) => {
                            return (
                              <Form.Check
                                key={`${index}-${pointItem.value}`}
                                name={idx}
                                type="radio"
                                label=""
                                value={pointItem.value}
                                id={pointItem.value}
                                disabled
                                onChange={e => {
                                  setResearchResult({
                                    ...researchResult,
                                    [item.seq]: e.target.value,
                                  });
                                }}
                                checked={
                                  researchResult[item.seq] === pointItem.value
                                }
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </section>

          {/* 파일 첨부 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>파일 첨부</h5>
            </div>
            <ul className="attachment-list">
              {formInfo && formInfo.files.length > 0 ? (
                formInfo.files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <Button
                        className="btn-icon ic-down"
                        onClick={() => openFile(file.file_seq)}
                      >
                        {file.name || file.file_name_org}
                      </Button>
                    </li>
                  );
                })
              ) : (
                <ul className="attachment-list">
                  <li>없음</li>
                </ul>
              )}
            </ul>
          </section>

          {/* 교사 코멘트 */}
          {teacherComments.length > 0 &&
            (formInfo.status === 'CON' || formInfo.status === 'REJ') && (
              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5 className="text-primary">교사 코멘트</h5>
                </div>
                <div className="comment-box type-primary">
                  {teacherComments.map((comment, index) => {
                    return (
                      <ul className="comment-list" key={index}>
                        <li>
                          <p className="date">
                            {
                              new Date(comment.reg_date)
                                .toISOString()
                                .split('T')[0]
                            }
                          </p>
                          <p className="text">{comment.comment}</p>
                        </li>
                        <br />
                      </ul>
                    );
                  })}
                </div>
              </section>
            )}

          {/* ////////// ↓ 하단 버튼 //////////  */}
          <div className="btn-area">
            {/* 승인 */}
            {formInfo.status === 'CON' && (
              <>
                {customButton('print')}
                {customButton('list')}
              </>
            )}
            {/* 승인요청 */}
            {formInfo.status === 'REQ' && (
              <>
                {customButton('print')}
                {customButton('cancel', formSeq)}
                {customButton('list')}
              </>
            )}
            {/* 보완 */}
            {formInfo.status === 'REJ' && (
              <>
                {customButton('print')}
                {customButton('edit', formSeq)}
                {customButton('list')}
              </>
            )}
            {/* 임시저장 */}
            {formInfo.status === 'ING' && (
              <>
                {customButton('print')}
                {customButton('delete', formSeq)}
                {customButton('edit', formSeq)}
                {customButton('request', formSeq)}
                {customButton('list')}
              </>
            )}
          </div>
        </article>
      </Container>
    </main>
  );
});
