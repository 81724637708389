import Axios from '@api/axios';

/**
 *   [ 시스템 관리자 > 차수관리 API ]
 *
 */

const API_ADMIN_SYSTEM = 'api/v1/adminSystem';

// [차수관리] 시스템 상태 조회
export const getSystemStatus = () => {
  return Axios.get(`${API_ADMIN_SYSTEM}/system-status`);
};

// [차수관리] 차수 전환
export const patchNextNth = () => {
  return Axios.patch(`${API_ADMIN_SYSTEM}/next-nth`);
};

// [차수관리] Redis 업데이트
export const putAllSystemStatus = () => {
  return Axios.put(`${API_ADMIN_SYSTEM}/all-system-status`);
};
