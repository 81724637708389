import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import {
  HeaderStudent,
  HeaderTeacher,
  HeaderAdmEdu,
  HeaderAdmSystem,
  Footer,
} from '@components';
import Swal from 'sweetalert2';

import PropTypes from 'prop-types';

import { getMemberInfo, getMenuList, getAccessToken } from '@api/header';
import { STUDENT, TEACHER, ADMIN, ADMIN_SYSTEM } from '@common/consts';

// 유저정보
const initialUserMap = {
  name: '-',
  grade: 1,
  type: 'NONE',
  authorization: [],
  // type: STUDENT,
};

//* [ Main ]
function AuthLayout({
  isAuthorized,
  component: Component,
  render,
  path,
  ...rest
}) {
  // propTypes
  AuthLayout.propTypes = {
    component: PropTypes.elementType,
  };

  // defaultProps
  AuthLayout.defaultProps = {
    component: PropTypes.element,
  };

  // Location
  const navi = useLocation();
  useLayoutEffect(() => {
    // go scroll top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [navi.pathname]);

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 회원 정보
  const [userInfo, setUserInfo] = useState(initialUserMap);

  // 메뉴 리스트
  const [menu, setMenu] = useState();

  // timerReset
  const [timerReset, setTimerReset] = useState(false);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 로그아웃
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    window.location.href = urlHandler('LOGOUT'); // 로그인 화면 이동
  };

  // URL 변환
  const urlHandler = type => {
    let url = '/';

    switch (type) {
      case STUDENT:
        url = '/home';
        break;
      case TEACHER:
        url = '/teacher/studentMgmt/studentMgmt';
        break;
      case ADMIN:
        url = '/admEdu/student/status';
        break;
      case ADMIN_SYSTEM:
        url = '/admSystem/code/main';
        break;
      case 'LOGOUT':
        url = '/';
        break;
      default:
        break;
    }

    return url;
  };

  //* #################################################################
  //* [ API ] 회원 정보 가져오기
  //* #################################################################
  const getMember = async () => {
    try {
      const { data } = await getMemberInfo();

      if (data.code === 0) {
        const {
          type = STUDENT,
          name = '',
          grade = 1,
          roleDetail = '',
        } = data.data;

        // 유저정보 갱신
        setUserInfo({
          type, // 로그인 타입 ( STUDENT | TEACHER | ADMIN | ADMIN_SYSTEM )
          name, // 이름
          grade, // 학년
          roleDetail, // 세부 권한
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 웹 메뉴 가져오기
  //* #################################################################
  const getMenuItems = async () => {
    try {
      const { data } = await getMenuList(userInfo.type);

      if (data.code === 0) {
        const menuMap = data.data;
        setMenu(menuMap);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] Access Token 갱신
  //* #################################################################
  const refreshAccessToken = async () => {
    try {
      // Refresh Token 확인
      const refreshToken = localStorage.getItem('refresh_token');

      if (!refreshToken) {
        Swal.fire({
          text: '갱신 토큰이 존재하지 않으므로 접근 토큰을 갱신할 수 없습니다.',
          confirmButtonText: '확인',
        });
      }

      // Axios
      const { data } = await getAccessToken(refreshToken);

      if (data.code === 0) {
        // Access Token 갱신
        const { accessToken } = data.data;

        localStorage.setItem('access_token', accessToken);

        // 타이머 초기화 (60분)
        setTimerReset(!timerReset);
      }
    } catch (e) {
      // Refresh Token 만료
      if (e.response.data.code === 1101) {
        Swal.fire({
          text: '로그인 시간이 만료되었습니다. 다시 로그인해주세요.',
          confirmButtonText: '확인',
        });

        // 로그아웃
        logout();
      } else {
        // alert(e.response.data.message);
      }
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  // 회원 정보 갱신
  useEffect(() => {
    getMember();

    // Access Token 갱신
    if (localStorage.getItem('refresh_token')) {
      refreshAccessToken();
    }
  }, []);

  // 메뉴정보 갱신
  useEffect(() => {
    if (path === '*' || path === '/home') {
      // window.location.href = urlHandler(userInfo.type);
    }

    getMenuItems();
  }, [userInfo]);

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <>
      {/* 학생 Header */}
      {userInfo.type === STUDENT && (
        <HeaderStudent
          userInfo={userInfo}
          menu={menu}
          timerOption={timerReset}
          tokenHandler={refreshAccessToken}
          logoutHandler={logout}
        />
      )}
      {/* 선생님 Header */}
      {userInfo.type === TEACHER && (
        <HeaderTeacher
          userInfo={userInfo}
          menu={menu}
          timerOption={timerReset}
          tokenHandler={refreshAccessToken}
          logoutHandler={logout}
        />
      )}
      {/* 관리자 Header */}
      {userInfo.type === ADMIN && (
        <HeaderAdmEdu
          userInfo={userInfo}
          menu={menu}
          timerOption={timerReset}
          tokenHandler={refreshAccessToken}
          logoutHandler={logout}
        />
      )}
      {/* 시스템 관리자 Header */}
      {userInfo.type === ADMIN_SYSTEM && (
        <HeaderAdmSystem
          menu={menu}
          timerOption={timerReset}
          tokenHandler={refreshAccessToken}
          logoutHandler={logout}
        />
      )}

      {/* Layout */}
      <Container fluid className="app-container">
        <div className="app-contents">
          <Route
            {...rest}
            path={path}
            render={routeProps =>
              isAuthorized ? (
                render ? (
                  render(routeProps)
                ) : (
                  <Component {...routeProps} userInfo={userInfo} />
                )
              ) : (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: routeProps.location },
                  }}
                />
              )
            }
          />
        </div>
      </Container>
      <Footer />
    </>
  );
}
export default React.memo(AuthLayout);
