/**
 *   [ NCS API ]
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/teacher';

const SELECT_STUDENT_NCS_LIST = `${API_PROGRAM}/student-ncs-list`;

// NCS > 학생 등록 NCS 리스트
export const selectStudentNcsList = ({ studentIdx }) => {
  return Axios.get(`${SELECT_STUDENT_NCS_LIST}/${studentIdx}`);
};
