/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';

//* API
import {
  selectFormAbilityList,
  insertUpdateAbilityTest,
} from '@api/student/jobAssess';
import { selectFormAbilityListByTeacher } from '@api/teacher/jobAssess';

//* CONSTS
import { FormStatus } from '@common/consts';
import { LOGIN_TYPE, ROLE } from '../../../../common/consts';

//* Utils
import Utils from '@common/Utils';
import Swal from 'sweetalert2';

//* Main
export default React.memo(function JobAssess(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  const { loginType, seq } = useParams();

  const authType = props.userInfo.type;

  if (loginType !== LOGIN_TYPE.STUDENT && loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !seq) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  // type
  // const [gradeType, setGradeType] = useState('freshman'); // freshman/junior/senior

  //* 리스트
  const [abilityList, setAbilityList] = useState([]);

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ API ] 직업기초 능력평가 리스트 조회
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getAbilityTestList = async () => {
    // Axios
    try {
      const params = {
        studentIdx: seq,
      };
      const { data } = await selectFormAbilityList(params);
      if (data.code === 0) {
        setFirstCallDone(true);
        const list = data.data;
        if (list.length > 0) {
          list.forEach(form => {
            const date = new Date(form.test_date).toISOString().split('T')[0];
            form.test_date = date;
          });
          setAbilityList(list);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  const getAbilityTestListByTeacher = async () => {
    // Axios
    try {
      const params = {
        studentIdx: seq,
      };
      const { data } = await selectFormAbilityListByTeacher(params);
      if (data.code === 0) {
        setFirstCallDone(true);
        const list = data.data;
        if (list.length > 0) {
          list.forEach(form => {
            const date = new Date(form.test_date).toISOString().split('T')[0];
            form.test_date = date;
          });
          setAbilityList(list);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 요청 취소, 삭제
  //* #################################################################
  const insertUpdateRequest = async (formSeq, formStatus, reqStatus) => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // Confirm 메세지
    const confirmMessage =
      reqStatus === 'ING'
        ? '승인요청을 취소하시겠습니까?'
        : '삭제하시겠습니까?';

    // eslint-disable-next-line no-restricted-globals
    if (
      await Swal.fire({
        text: confirmMessage,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed)
    ) {
      // 파라미터 설정
      const param = {
        formSeq,
        status: reqStatus,
      };

      // FormData
      const formData = new FormData();

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(param)], { type: 'application/json' }),
      );

      // 중복요청 방지
      setIsSentRequest(true);

      // Axios
      try {
        const { data } = await insertUpdateAbilityTest(formData);

        if (data.code === 0) {
          getAbilityTestList();

          // 중복요청 방지 해제
          setIsSentRequest(false);
        }
      } catch (e) {
        // 중복요청 방지 해제
        setIsSentRequest(false);

        // alert(e.response.data.message);
      }
    }
  };
  
  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 취소 & 삭제 버튼
  const cancelDeleteButton = (studentIdx, fromStatus, reqStatus) => {

    const isIng = reqStatus === 'ING';
    const btnText = isIng ? '취소' : '삭제';
    const modalText = isIng ? '승인요청을 취소하시겠습니까?' : '삭제하시겠습니까?';

    return (
      <Button
        size="sm"
        variant="outline-primary"
        onClick={e => {
          e.stopPropagation();

          Swal.fire({
            title: '알림',
            text: modalText,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              insertUpdateRequest(studentIdx, fromStatus, reqStatus);
            }
          });
        }}
      >
        {btnText}
      </Button>
    );
  };

  // 수정 버튼
  const editButton = studentIdx => {
    return (
      <Button
        onClick={e => {
          e.stopPropagation();
          history.push(`/student/employAbility/jobAssess/write/${studentIdx}`);
        }}
        size="sm"
        variant="primary"
      >
        수정
      </Button>
    );
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    if (loginType === LOGIN_TYPE.STUDENT) {
      getAbilityTestList();
    }
    if (loginType === LOGIN_TYPE.TEACHER) {
      getAbilityTestListByTeacher();
    }
  }, [loginType]);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-jobAssess">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">직업기초능력평가</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>직업기초능력평가</h5>
              {loginType === LOGIN_TYPE.STUDENT && (
                <Button
                  onClick={() =>
                    history.push('/student/employAbility/jobAssess/write')
                  }
                  variant="primary"
                >
                  신규작성
                </Button>
              )}
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>결과 보고서</h5>
            </div>
            <Table>
              <colgroup>
                <col width={260} />
                <col width="*" />
                <col width={150} />
                <col width={150} />
                {loginType === LOGIN_TYPE.STUDENT && <col width={150} />}
              </colgroup>
              <thead>
                <tr>
                  <th>평가일</th>
                  <th>과정명</th>
                  <th>상태</th>
                  <th>점수</th>
                  {loginType === LOGIN_TYPE.STUDENT && <th>관리</th>}
                </tr>
              </thead>
              <tbody>
                {abilityList.length > 0
                  ? abilityList.map((form, idx) => {
                      return (
                        <tr
                          key={idx}
                          className="go-detail"
                          onClick={() =>
                            loginType === LOGIN_TYPE.STUDENT
                              ? history.push(
                                  `/student/employAbility/jobAssess/report/${form.seq}`,
                                )
                              : history.push(
                                  `/teacher/studentMgmt/basicJob/jobAssess/comment/${seq}/${form.seq}`,
                                )
                          }
                        >
                          {/* 평가일 */}
                          <td>{form.test_date}</td>
                          {/* 과정명 */}
                          <td>{form.test_title}</td>
                          {/* 상태 */}
                          <td className="status-text">
                            <strong className={FormStatus[form.status].class}>
                              {FormStatus[form.status].text}
                              <br/>
                              {form.status === 'CON' && 
                                <small>{Utils.getJustDateString(form.confirm_date)}</small>
                              }
                            </strong>
                          </td>
                          {/* 점수 */}
                          <td>{form.point}</td>
                          {/* 관리 > 버튼 */}
                          {loginType === LOGIN_TYPE.STUDENT && (
                            <td>
                              <div className="btn-area">
                                {/* 승인 */}
                                {form.status === 'CON'}
                                {/* 승인요청 */}
                                {form.status === 'REQ' && (
                                  <>
                                    {cancelDeleteButton(
                                      form.seq,
                                      form.status,
                                      'ING',
                                    )}
                                  </>
                                )}
                                {/* 보완 */}
                                {form.status === 'REJ' && (
                                  <>{editButton(form.seq)}</>
                                )}
                                {/* 작성중 */}
                                {form.status === 'ING' && (
                                  <>
                                    {cancelDeleteButton(
                                      form.seq,
                                      form.status,
                                      'DEL',
                                    )}
                                    {editButton(form.seq)}
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  : firstCallDone && (
                      <tr>
                        <td
                          colSpan={loginType === LOGIN_TYPE.STUDENT ? '5' : '4'}
                          className="no-data"
                        >
                          작성한 결과 보고서가 없습니다.
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </section>

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {loginType === LOGIN_TYPE.TEACHER && <div className="btn-area mb-0">
            <Button
              onClick={() => {
                history.goBack();
              }}
              variant="outline-primary"
              size="lg"
            >
              목록으로
            </Button>
          </div>}          
        </article>
      </Container>
    </main>
  );
});
