/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Container, Table, Card } from 'react-bootstrap';

//* Utils
import Utils from '@common/Utils';

//* API
import { selectSchoolVoucher } from '@api/teacher/voucherLookup';

//* [ Main ]
export default React.memo(function VoucherLookup(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const [schoolList, setSchoolList] = useState([]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################

  //* #################################################################
  //* [ API ] 자율 프로그램 정보 가져오기
  //* #################################################################
  const getSchoolVoucher = async () => {
    // Axios
    try {
      const { data } = await selectSchoolVoucher();

      if (data.code === 0) {
        const { data: list = [] } = data;

        // 학교 리스트 갱신
        setSchoolList(list);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  // 프로그램 정보 가져오기
  useEffect(() => {
    getSchoolVoucher();

    return () => {
      setSchoolList([]);
    };
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="teacher-voucherLookup" className="type-02">
      <Container>
        <article className="content">
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>바우처 조회</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Body>
              <Table className="bt-none tr-even-bg">
                <colgroup>
                  <col width={110} />
                  <col width={110} />
                  <col width="*" />
                  <col width={300} />
                  {/* <col width={280} /> */}
                </colgroup>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>ID</th>
                    <th className="text-start">학교명</th>
                    <th className="text-end">바우처 할당 금액</th>
                    {/* <th>지급일시</th> */}
                  </tr>
                </thead>
                <tbody>
                  {schoolList.length > 0 &&
                    schoolList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.schoolId}</td>
                          <td className="text-start">{item.schoolName}</td>
                          <td className="text-end">
                            {Utils.numberComma(item.maxVoucher)}
                          </td>
                          {/* <td> */}
                          {/*  { */}
                          {/*    new Date(item.mod_date) */}
                          {/*      .toISOString() */}
                          {/*      .split('T')[0] */}
                          {/*  } */}
                          {/* </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});
