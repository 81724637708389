/**
 *   [ NCS API ]
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const SELECT_NCS_INFO = `${API_PROGRAM}/ncs-info`;
const SELECT_NCS_INFO_BY_CODE = `${API_PROGRAM}/ncs-info-by-code`;
const SELECT_NCS_MY_LIST = `${API_PROGRAM}/my-ncs`;
const INSERT_UPDATE_NCS = `${API_PROGRAM}/ncs`;

// NCS > 대분류 정보
export const selectNcsInfo = () => {
  return Axios.get(`${SELECT_NCS_INFO}`);
};

// NCS > 대분류 하위 정보
export const selectNcsInfoByCode = ({ code }) => {
  return Axios.get(`${SELECT_NCS_INFO_BY_CODE}/${code}`);
};

// NCS > 학생 등록 NCS 리스트
export const selectMyNcsList = () => {
  return Axios.get(`${SELECT_NCS_MY_LIST}`);
};

// NCS > 등록 & 삭제
export const insertUpdateNcs = param => {
  return Axios.post(`${INSERT_UPDATE_NCS}`, param);
};
