/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Table, Modal } from 'react-bootstrap';
import { CustomSelect, CustomModal } from '@components';
import Moment from 'react-moment';
import Swal from 'sweetalert2';

//* API
import { getIpList, patchIp, deleteIp } from '@api/eduadmin/systemConfig';

// IP Input 초기값
const initialIpInputArray = ['', '', '', ''];

//* [ Main ]
export default React.memo(function AdmEduSystemIp(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 검색 필터 ( 학교명, IP )
  const [searchFilter, setSearchFilter] = useState(searchFilterOptions[0]);

  // 학교 IP 리스트
  const [ipList, setIpList] = useState([]);
  const [initIpList, setInitIpList] = useState([]);

  // IP 변경 학교 SEQ, IP
  const [schoolSeq, setSchoolSeq] = useState(0);
  const [schoolIp, setSchoolIp] = useState();
  const [inputIpArray, setInputIpArray] = useState(initialIpInputArray);

  // 검색어
  const [searchKeyword, setSearchKeyword] = useState('');

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // IP 입력
  const ipInputHandler = (index, ip) => {
    const tempArray = [...inputIpArray];
    tempArray[index] = ip;

    setInputIpArray(tempArray);
  };

  // 검색
  const searchHandler = () => {
    const filterOption =
      searchFilter.value === 'school' ? 'schoolName' : 'allowIp';
    const filteredArray = initIpList.filter(item =>
      item[filterOption].includes(searchKeyword),
    );

    setIpList(filteredArray);
  };

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  const [regIpType, setRegIpType] = useState('reg'); // ( type = reg: 등록, modify: 수정 )

  const [regIpModalShow, setRegIpModalShow] = useState(false);

  // 모달 Open
  const openRegIpModal = (type, seq, ip) => {
    // 수정 > Input 자동완성
    if (type === 'modify') {
      const splitArray = ip.split('.');

      setInputIpArray(
        splitArray.length === 4 ? splitArray : initialIpInputArray,
      );
    }

    // 등록, 수정 타입
    setRegIpType(type);

    // 학교 정보
    setSchoolSeq(seq);
    setSchoolIp(ip);

    // Open
    setRegIpModalShow(true);
  };

  // 모달 Close
  const closeRegIpModal = () => {
    // Input 초기화
    setInputIpArray(initialIpInputArray);

    // 학교 정보 초기화
    setSchoolSeq(0);
    setSchoolIp('127.0.0.1');

    // Close
    setRegIpModalShow(false);
  };

  //* #################################################################
  //* [ API ] Form 정보 가져오기
  //* #################################################################
  const getIpInfo = async () => {
    // Axios
    try {
      const { data } = await getIpList();

      if (data.code === 0) {
        const list = data.data;

        // 리스트 갱신 ( 검색용, 원본 )
        setIpList([...list]);
        setInitIpList([...list]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] IP 등록, 수정, 삭제
  //* #################################################################
  const updateRequest = async () => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // Input 확인 (1) - Index, 공백
    if (
      inputIpArray.length < 4 ||
      (inputIpArray.filter(ip => ip && ip.trim() !== '').length > 0 &&
        inputIpArray.filter(ip => !ip || ip.trim() === '').length > 0)
    ) {
      Swal.fire({
        text: '입력한 IP를 확인해주세요.',
        confirmButtonText: '확인',
      });
      return;
    }

    // Input 확인 (2) - 동일한 값
    if (schoolIp === inputIpArray.join('.')) {
      Swal.fire({
        text: '기존 IP와 동일한 입력값입니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    // -----------------------------------------------------------
    // 삭제 요청 ( 공백 ip 입력 ) - 공백 or 0.0.0.0
    // -----------------------------------------------------------
    if (
      inputIpArray.filter(ip => !ip || ip.trim() === '').length === 4 ||
      inputIpArray.filter(ip => ip && ip.trim() === '0').length === 4
    ) {
      // 중복요청 방지
      setIsSentRequest(true);

      // Axios
      try {
        const { data } = await deleteIp(schoolSeq);

        if (data.code === 0) {
          // 리스트 갱신
          getIpInfo();

          // 중복요청 방지 해제
          setIsSentRequest(false);

          // 모달 Close
          closeRegIpModal();
        }
      } catch (e) {
        // 중복요청 방지 해제
        setIsSentRequest(false);

        // 모달 Close
        closeRegIpModal();

        // alert(e.response.data.message);
      }
    }
    // -----------------------------------------------------------
    // 등록 & 수정 요청
    // -----------------------------------------------------------
    else {
      // JSON 파라미터
      const param = {
        seq: schoolSeq,
        allowIp: inputIpArray.join('.'),
      };

      // 중복요청 방지
      setIsSentRequest(true);

      // Axios
      try {
        const { data } = await patchIp(param);

        if (data.code === 0) {
          // 리스트 갱신
          getIpInfo();

          // 중복요청 방지 해제
          setIsSentRequest(false);

          // 모달 Close
          closeRegIpModal();
        }
      } catch (e) {
        // 중복요청 방지 해제
        setIsSentRequest(false);

        // 모달 Close
        closeRegIpModal();

        // alert(e.response.data.message);
      }
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getIpInfo();
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="admEdu-system-ip" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>IP관리</h5>
            </div>
          </section>

          <Card>
            <Card.Body>
              <div className="search-box mb-4">
                <div className="select-inside-box">
                  <CustomSelect
                    options={searchFilterOptions}
                    value={searchFilter}
                    onChange={setSearchFilter}
                  />
                </div>
                <div className="input-has-btn ms-2">
                  <Form.Control
                    type="text"
                    placeholder="검색어를 입력하세요."
                    className="input-search border-0"
                    value={searchKeyword}
                    onChange={e => setSearchKeyword(e.target.value.trim())}
                  />
                  <Button size="sm" variant="primary" onClick={searchHandler}>
                    검색
                  </Button>
                </div>
              </div>
              <Table className="bt-none tr-even-bg">
                <colgroup>
                  <col width={120} />
                  <col width="*" />
                  <col width={240} />
                  <col width={240} />
                  <col width={120} />
                </colgroup>
                <thead>
                  <tr>
                    <th>순번</th>
                    <th>학교명</th>
                    <th>IP</th>
                    <th>등록일</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  {ipList.length > 0 ? (
                    ipList.map((item, index) => {
                      return (
                        <tr key={`ipTable-tr-${index}`}>
                          {/* 순번 */}
                          <td>{item.seq}</td>

                          {/* 학교명 */}
                          <td className="text-start">{item.schoolName}</td>

                          {/* 학교 IP */}
                          <td>{item.allowIp || '등록된 IP가 없습니다.'}</td>

                          {/* 등록일 */}
                          <td>
                            {item.ip ? (
                              <Moment date={item.ipDate} format="YYYY.MM.DD" />
                            ) : (
                              '-'
                            )}
                          </td>

                          {/* 모달 버튼 */}
                          <td>
                            <Button
                              variant="outline-primary"
                              onClick={() =>
                                !item.allowIp || item.allowIp === '*'
                                  ? // 등록
                                    openRegIpModal(
                                      'reg',
                                      item.seq,
                                      item.allowIp,
                                    )
                                  : // 수정
                                    openRegIpModal(
                                      'modify',
                                      item.seq,
                                      item.allowIp,
                                    )
                              }
                            >
                              설정
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <p className="no-data">데이터가 없습니다.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </article>
      </Container>

      {/* 모달 */}
      <CustomModal
        show={regIpModalShow}
        onHide={closeRegIpModal}
        title="설정"
        id="modal-reg-ip"
      >
        <Modal.Body className="border-0">
          <ul className="form-list">
            <li>
              <h5>학교명</h5>
              <p>부산진여자상업고등학교</p>
            </li>
            <li>
              <h5>IP</h5>
              <div className="ip-input-box">
                <Form.Control
                  maxLength={3}
                  value={inputIpArray[0]}
                  onChange={e =>
                    ipInputHandler(0, e.target.value.replace(/\D/, ''))
                  }
                />
                <Form.Control
                  maxLength={3}
                  value={inputIpArray[1]}
                  onChange={e => ipInputHandler(1, e.target.value)}
                />
                <Form.Control
                  maxLength={3}
                  value={inputIpArray[2]}
                  onChange={e => ipInputHandler(2, e.target.value)}
                />
                <Form.Control
                  maxLength={3}
                  value={inputIpArray[3]}
                  onChange={e => ipInputHandler(3, e.target.value)}
                />
              </div>
            </li>
          </ul>

          {/* 중복시 노출 */}
          {schoolIp && schoolIp === inputIpArray.join('.') && (
            <small className="d-block text-center mt-3">
              입력하신 IP
              <strong className="text-primary">{`[${schoolIp}]`}</strong>는 이미
              등록된 IP입니다.
            </small>
          )}
          {/* // 중복시 노출 */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeRegIpModal}>
            취소
          </Button>
          <Button variant="primary" onClick={updateRequest}>
            {regIpType === 'reg' ? '추가' : '저장'}
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});

const searchFilterOptions = [
  {
    label: '학교명',
    value: 'school',
  },
  {
    label: 'IP',
    value: 'ip',
  },
];
