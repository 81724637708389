/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Container } from 'react-bootstrap';
import { images } from '@assets';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { getSchools } from '../api/common/common';

// -----------------------------------------------------------------------------------------
// --------------------------------------- CustomMenu --------------------------------------
// -----------------------------------------------------------------------------------------
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [searchKeyword, setSearchKeyword] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="p-2 border-bottom">
          <Form.Control
            autoFocus
            className="input-search"
            placeholder="학교를 검색하세요."
            onChange={e => setSearchKeyword(e.target.value)}
            value={searchKeyword}
          />
        </div>
        <ul>
          {React.Children.toArray(children).filter(
            child =>
              !searchKeyword ||
              child.props.children.toLowerCase().startsWith(searchKeyword),
          )}
        </ul>
      </div>
    );
  },
);

// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------
export default function SearchSchool({
  selectedSchool,
  setSelectedSchool,
  ...rest
}) {
  // const [checkedSchool, setSelectedSchool] = useState({});

  const [schoolList, setSchoolList] = useState([]);

  const getSchoolList = async () => {
    const { data } = await getSchools();
    if (data.code === 0) {
      const tempList = data.data.map(v => {
        const tempData = {
          ...v,
          value: v.seq,
          label: v.schoolName,
        };
        return tempData;
      });
      const allSchool = {
        value: 'all',
        label: '학교 전체',
      };

      tempList.unshift(allSchool);
      setSchoolList(tempList);
    }
  };

  useEffect(() => {
    getSchoolList();
  }, []);

  return (
    <Dropdown css={dropdown} {...rest}>
      <Dropdown.Toggle as="div">
        <strong>
          {!selectedSchool || !selectedSchool.value
            ? '학교 선택'
            : selectedSchool.label}
        </strong>
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        {schoolList.map(item => {
          return (
            <Dropdown.Item
              as="li"
              key={item.value}
              value={item.value}
              onClick={e => setSelectedSchool(item)}
              className={classNames('dropdown-item', {
                checked: selectedSchool === item,
              })}
            >
              {item.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const dropdown = css`
  .dropdown-toggle {
    min-width: 300px;
    height: 52px;
    padding: 0 0.5rem 0 38px;
    background: url(${images.icSchool}) no-repeat 0.5rem 50% !important;
    cursor: pointer;
    &:before {
      top: 24px;
    }
    strong {
      line-height: 52px;
      font-weight: 500;
      font-size: var(--fs-16);
    }
  }
  .dropdown-menu {
    &.show {
      transform: translate(0px, 58px) !important;
      width: 100%;
      padding: 0;
      box-shadow: none;
      border: 1px solid var(--bs-border);
      border-radius: 0.25rem;
      ul {
        max-height: 300px;
        overflow: auto;
        li.dropdown-item {
          cursor: pointer;
          font-size: var(--fs-16);
          padding: 0.5rem 0.75rem;
          font-weight: 400;
          &.checked {
            background: transparent !important;
            color: var(--bs-primary);
            font-weight: 600;
          }
        }
      }
    }
  }
`;
