/**
 *   [ 매칭정보 API ]
 *      - 종합보고서
 *      - 기업정보
 *      - 대학정보
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const SELECT_MATCHING_SUMMARY_REPORT = `${API_PROGRAM}/summary-report`;
const SELECT_MATCHING_COMPANY = `${API_PROGRAM}/matching-company`;
const SELECT_MATCHING_UNIV = `${API_PROGRAM}/matching-univ`;

// 매칭정보 > 종합보고서
export const selectMatchingSummaryReport = () => {
  return Axios.get(`${SELECT_MATCHING_SUMMARY_REPORT}`);
};

// 매칭정보 > 기업정보
export const selectMatchingCompany = ({ types, income, search }) => {
  return Axios.get(`${SELECT_MATCHING_COMPANY}/${types}/${income}/${search}`);
};

// 매칭정보 > 대학정보
export const selectMatchingUniv = () => {
  return Axios.get(`${SELECT_MATCHING_UNIV}`);
};
