/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Container,
  Form,
  Table,
  Tab,
  Nav,
  Col,
  Button,
  Card,
  Modal,
} from 'react-bootstrap';
import { CustomModal } from '@components';
import ReactPaginate from 'react-paginate';
import { Utils } from '@common';
import { selectTeacherList } from '@api/teacher/teacherMgmt';
import Swal from 'sweetalert2';
import { TEACHER_ROLE_DETAIL_HAN } from '../../../common/consts';
import { resetPw } from '../../../api/teacher/teacherMgmt';

export default React.memo(function TeacherMgmt(props) {
  const history = useHistory();

  const historyState = history.location.state;

  // type
  const [teacherType, setTeacherType] = useState('basic'); // basic/director

  // 검색
  const searching =
    historyState && historyState.searching ? historyState.searching : false;

  const searchedKeyword =
    historyState && historyState.keyword ? historyState.keyword : '';

  // 비밀번호 초기화
  const [pwdInitModalShow, setPwdInitModalShow] = useState(false);
  const openPwdInitModal = (e, item) => {
    e.stopPropagation();
    setSelectedTeacher(item);
    setPwdInitModalShow(true);
  };
  const closePwdInitModal = () => {
    setPwdInitModalShow(false);
  };
  const submitPwdInitModal = e => {
    resetTeacherPw(e);
  };

  /**  form status */
  const [schoolName, setSchoolName] = useState();
  const [teacherList, setTeacherList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState({});
  const [keyword, setKeyword] = useState();

  /** API */
  // 선생 리스트 조회
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getTeacherList = async () => {
    const params = {
      keyword: searchedKeyword,
    };
    try {
      const { data } = await selectTeacherList(params);
      if (data.code === 0) {
        setFirstCallDone(true);
        setSchoolName(data.data.schoolName);
        setTeacherList(data.data.list);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // 선생 비밀번호 초기화
  const resetTeacherPw = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    const params = {
      teacher_idx: selectedTeacher.seq,
    };
    try {
      const { data } = await resetPw(params);
      if (data.code === 0) {
        closePwdInitModal();
        Swal.fire({
          html: '비밀번호가 초기화되었습니다.<br/>초기화된 비밀번호는 계정 아이디와 동일합니다.',
          confirmButtonText: '확인',
        });
      }
    } catch (e) {
      target.disabled = false;
      closePwdInitModal();
    }
    target.disabled = false;
  };

  /** page move */
  const movePage = () => {
    setFirstCallDone(false);
    history.push({
      pathname: '/teacher/teacherMgmt',
      state: {
        searching: !searching,
        keyword,
      },
    });
  };

  /** useEffect */
  useEffect(() => {
    return () => {
      setSchoolName(null);
      setTeacherList([]);
    };
  }, []);

  useEffect(() => {
    setKeyword(searchedKeyword);
    getTeacherList();
  }, [searching]);

  return (
    <main id="teacher-mgmt" className="type-02">
      <Container>
        <article className="content">
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>교사관리</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {firstCallDone && (
            <Card>
              <Card.Header>
                <div className="input-has-btn">
                  <Form.Control
                    type="text"
                    placeholder="교사 이름을 검색하세요."
                    className="input-search"
                    onChange={e => {
                      setKeyword(e.target.value);
                    }}
                    value={keyword || ''}
                  />
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={e => {
                      movePage();
                    }}
                  >
                    검색
                  </Button>
                </div>
                <div className="table-title mt-4">
                  <h5>{schoolName}</h5>
                  <Button
                    onClick={() =>
                      props.history.push('/teacher/teacherMgmt/register')
                    }
                    variant="primary"
                  >
                    교사 등록
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="pt-0">
                <Table className="bt-none tr-even-bg">
                  <colgroup>
                    <col width={150} />
                    <col width="*" />
                    <col width="*" />
                    <col width={190} />
                    <col width="*" />
                    <col width={200} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>이름</th>
                      <th>담당학급</th>
                      <th>아이디</th>
                      <th>상태</th>
                      <th>직책</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teacherList.length > 0 ? (
                      teacherList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="go-detail"
                            onClick={() =>
                              props.history.push(
                                `/teacher/teacherMgmt/detail/${item.seq}`,
                              )
                            }
                          >
                            <td>{Utils.decrypt(item.teacher_name)}</td>
                            <td>
                              {item.grade &&
                                `${
                                  +item.grade === 0 ? '전체' : item.grade
                                }학년`}
                              {item.ban_no &&
                                `${window.parseInt(item.ban_no)}반`}
                              {!item.grade && !item.ban_no && '-'}
                            </td>
                            <td>{item.teacher_id}</td>
                            <td>
                              {item.teacher_gubun_han !== '결과없음'
                                ? item.teacher_gubun_han
                                : '-'}
                            </td>
                            <td>
                              {item.role_detail
                                ? TEACHER_ROLE_DETAIL_HAN[item.role_detail]
                                : '-'}
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={e => openPwdInitModal(e, item)}
                              >
                                비밀번호 초기화
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="no-data">
                          등록된 교사가 존재하지 않습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </article>
      </Container>
      <CustomModal
        title="알림"
        show={pwdInitModalShow}
        onHide={closePwdInitModal}
        size="sm"
      >
        <Modal.Body className="border-0">
          <p className="text-center">비밀번호를 초기화 하시겠습니까?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closePwdInitModal}>
            취소
          </Button>
          <Button onClick={submitPwdInitModal}>초기화</Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
