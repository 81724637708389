/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Table, Tab, Nav } from 'react-bootstrap';
import { CustomSelect } from '@components';
// import { images } from '@assets';

//* Utils
import Utils from '@common/Utils';

//* CONSTS
import { FormStatus, NTH_LIST } from '@common/consts';

//* API
import { selectDashboardInfo, selectStatusSummary } from '@api/student/home';
import Moment from 'react-moment';
import { getMainArchiveList, getMainNoticeList } from '../../api/board/main';

// 인적사항 Form
const initialUserInfo = {
  name: '',
  school: '',
  major: '',
  grade: 1,
  class: '',
  num: '',
};

// 학년 정보
const allGradeList = [1, 2, 3];

//* [ Main ]
export default React.memo(function Home(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();
  // const [selectedTurn, setSelectedTurn] = useState(turnOptions[0]);

  // 유저 정보
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  // 현재 점수
  const [pointTotal, setPointTotal] = useState(0);

  // 예상 금액
  const [voucherTotal, setVoucherTotal] = useState(0);

  // 선택 프로그램 학년
  const [selectedGrade, setSelectedGrade] = useState(1);

  // 학년별 프로그램
  const [programList, setProgramList] = useState([]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################

  // 학년 선택
  const gradeHandler = data => {
    setSelectedGrade(+data);
  };

  //* #################################################################
  //* [ API ] 개인정보 조회
  //* #################################################################
  const getBoardInfo = async () => {
    // Axios
    try {
      const { data } = await selectDashboardInfo({});

      if (data.code === 0) {
        const {
          userName = '',
          schoolName = '',
          userMajor = '',
          userGrade = '',
          userBan = '',
          userNum = '',
          pointSum = 0,
          voucherSum = 0,
        } = data.data;

        // 개인 정보
        setUserInfo({
          name: userName,
          school: schoolName,
          major: userMajor,
          grade: +userGrade,
          class: +userBan,
          num: +userNum,
        });

        // 현재점수, 예상금액
        setPointTotal(pointSum);
        setVoucherTotal(voucherSum);

        // 선택 학년
        setSelectedGrade(+userGrade);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 학년별 프로그램 현황
  //* #################################################################
  const getStatusSummary = async () => {
    // JSON 파라미터
    const param = {
      grade: selectedGrade,
    };

    // Axios
    try {
      const { data } = await selectStatusSummary(param);

      if (data.code === 0) {
        setProgramList([...data.data]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  const [noticeList, setNoticeList] = useState([]);

  const getNotices = async () => {
    const { data } = await getMainNoticeList();
    if (data.code === 0) {
      setNoticeList([...data.data]);
    }
  };

  const [archiveList, setArchiveList] = useState([]);
  const getArchives = async () => {
    const { data } = await getMainArchiveList();
    if (data.code === 0) {
      setArchiveList([...data.data]);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  // 개인정보
  useEffect(() => {
    getBoardInfo();
    getNotices();
    getArchives();
    return () => {};
  }, []);

  // 학년 선택 > 학년별 프로그램
  useEffect(() => {
    getStatusSummary();

    return () => {};
  }, [selectedGrade]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="home">
      <Container>
        <article className="content">
          {/* ////////// ↓ 배너, 유저박스 ////////// */}
          <Row className="mb-5">
            {/* 배너 */}
            <Col lg="8">
              <section className="banner">
                {/* ↓ 배너 데이터 넣은 후 삭제 */}
                {/* <div className="annotation-box">
                  <p className="annotation">메인 배너</p>
                </div> */}
                {/* ↓ 주석 풀기 */}
                <div
                  className="img-box"
                  // style={{
                  //   backgroundImage: `url(../images/coin/ETH.png)`,
                  // }}
                />
              </section>
            </Col>
            {/* 내정보 */}
            <Col lg="4">
              <section className="user-box">
                <div className="top-area">
                  {/* 이름 */}
                  <h5>{Utils.decrypt(userInfo.name)}</h5>
                  <div className="btn-area">
                    <Button
                      onClick={() => props.history.push('/student/mypage')}
                      variant="outline-primary"
                    >
                      마이페이지
                    </Button>
                    {/* <Button
                      onClick={() => props.history.push('/')}
                      variant="outline-primary"
                    >
                      종합보고서
                    </Button> */}
                  </div>
                </div>
                <div className="dl-box info">
                  {/* 학교 */}
                  <dl>
                    <dt>학교</dt>
                    <dd>{userInfo.school}</dd>
                  </dl>
                  {/* 전공 */}
                  <dl>
                    <dt>전공</dt>
                    <dd>{userInfo.major}</dd>
                  </dl>
                  {/* 학년 & 반 */}
                  <dl>
                    <dt>학년/반</dt>
                    <dd>
                      {userInfo.grade}학년 {userInfo.class}반
                    </dd>
                  </dl>
                </div>
                <div className="dl-box total">
                  <dl>
                    <dt>현재 점수</dt>
                    <dd>
                      <strong>{+pointTotal}</strong>점
                    </dd>
                  </dl>
                  <dl>
                    <dt>예상 금액</dt>
                    <dd>
                      <strong>{Utils.numberComma(voucherTotal)}</strong>원
                    </dd>
                  </dl>
                </div>
              </section>
            </Col>
          </Row>

          {/* ////////// ↓ 학년별 프로그램 탭 ////////// */}
          <Row className="mb-5">
            <section>
              <div className="sub-title">
                <h5>학년별 프로그램</h5>
              </div>
              <Tab.Container id="grade-tab" activeKey={selectedGrade}>
                <Nav className="text-tabs">
                  <Nav.Item>
                    {allGradeList
                      .filter(item => item <= userInfo.grade)
                      .map((item, index) => {
                        return (
                          <Nav.Link
                            key={index}
                            eventKey={item}
                            onClick={() => gradeHandler(item)}
                          >
                            {item}학년
                          </Nav.Link>
                        );
                      })}
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  {/* ↓ 학년별 탭 */}
                  {allGradeList
                    .filter(item => item <= userInfo.grade)
                    .map((item, index) => {
                      return (
                        <Tab.Pane key={index} eventKey={item}>
                          <Table>
                            <colgroup>
                              {/* <col width={166} /> */}
                              <col width="*" />
                              <col width={150} />
                              <col width={150} />
                            </colgroup>
                            <thead>
                              <tr>
                                {/* <th>구분</th> */}
                                <th>프로그램</th>
                                <th>상태</th>
                                <th>점수</th>
                              </tr>
                            </thead>
                            <tbody>
                              {programList.length > 0 &&
                                programList
                                  // .filter(program => {
                                  //   return program.status;
                                  // })
                                  .map((program, idx) => {
                                    return (
                                      <tr
                                        key={idx}
                                        className="go-detail"
                                        onClick={() => {
                                          props.history.push(
                                            Utils.convertProgram4Student(
                                              program.program_id,
                                              selectedGrade,
                                            ),
                                          );
                                        }}
                                      >
                                        {/* <td>{program.program_gubun}</td> */}
                                        <td className="text-start">
                                          {program.program_name}
                                        </td>
                                        <td>
                                          <strong
                                            className={
                                              FormStatus[
                                                program.status || 'NONE'
                                              ].class
                                            }
                                          >
                                            {
                                              FormStatus[
                                                program.status || 'NONE'
                                              ].text
                                            }
                                          </strong>
                                        </td>
                                        <td>{program.point}</td>
                                      </tr>
                                    );
                                  })}
                            </tbody>
                          </Table>
                        </Tab.Pane>
                      );
                    })}
                </Tab.Content>
              </Tab.Container>
            </section>
          </Row>

          {/* ////////// ↓ 공지사항, 교육 자료실 ////////// */}
          <Row className="mb-5">
            <Col lg="6">
              <section>
                <div className="sub-title">
                  <h5>공지사항</h5>
                  <Button
                    variant="view-more"
                    onClick={() =>
                      props.history.push('/student/service/notice')
                    }
                  >
                    더보기
                  </Button>
                </div>
                <ul className="post-list has-bt">
                  {noticeList &&
                    noticeList.length > 0 &&
                    noticeList.map((v, i) => {
                      return (
                        <li key={`not_${i}`}>
                          <Link to={`/student/service/notice/detail/${v.seq}`}>
                            <span className="ellipsis">{v.title}</span>
                            <span className="date">
                              <Moment date={v.regDate} format="YYYY-MM-DD" />
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </section>
            </Col>
            <Col lg="6">
              <section>
                <div className="sub-title">
                  <h5>교육 자료실</h5>
                  <Button
                    variant="view-more"
                    onClick={() =>
                      props.history.push('/student/service/archive')
                    }
                  >
                    더보기
                  </Button>
                </div>
                <ul className="post-list has-bt">
                  {archiveList.map((v, i) => {
                    return (
                      <li key={`arc_${i}`}>
                        <Link to={`/student/service/archive/detail/${v.seq}`}>
                          <span className="ellipsis">{v.title}</span>
                          <span className="date">
                            <Moment date={v.regDate} format="YYYY-MM-DD" />
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </section>
            </Col>
          </Row>
        </article>
      </Container>
    </main>
  );
});
