import React from 'react';
import { images } from '@assets';
import Select from 'react-select';

function CustomSelect({ options, value, onChange, disabled, ...rest }) {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
      noOptionsMessage={() => '선택 옵션이 없습니다.'}
      // defaultValue={options[0]}
      isSearchable={false}
      maxMenuHeight={400}
      isDisabled={disabled}
      {...rest}
    />
  );
}

const customStyles = {
  // select box
  container: provided => ({
    ...provided,
  }),
  // input box
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    padding: '0 0.75rem',
    fontSize: '1rem',
    cursor: 'pointer',
    fontWeight: 500,
    border: '1px solid #d4d8df',
    color: state.isDisabled ? '#6c757d' : '#18191c',
    background: state.isDisabled ? '#F0F2F4' : '#fff',
  }),
  // input
  valueContainer: (provided, state) => ({
    display: 'flex',
    width: '100%',
    height: '40px',
    lineHeight: '40px',
    borderRadius: 4,
    background: state.isDisabled
      ? `#F0F2F4 url(${images.icDown}) no-repeat 100% 50% / 10px`
      : `#fff url(${images.icDown}) no-repeat 100% 50% / 10px`,
    textAlign: 'left',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontWeight: 500,
    fontSize: 'var(--fs-16)',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    color: '#a4a5b6',
  }),
  // 우측
  clearIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    // display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    svg: {
      display: 'none',
    },
  }),
  // multi value label
  multiValueLabel: provided => ({
    ...provided,
    // width: 0,
    // height: 0,
    // overflow: 'hidden',
  }),
  multiValue: provided => ({
    ...provided,
    // width: 0,
    // height: 0,
    // overflow: 'hidden',
  }),
  // options
  menu: provided => ({
    ...provided,
    width: '100%',
    padding: '6px 8px',
    border: '1px solid #dedede',
    borderRadius: 4,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0)',
    maxHeight: 400,
    backgroundColor: '#fff',
    fontSize: '1rem',
    margin: '5px 0',
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    textAlign: 'left',
    padding: '6px 10px',
    borderRadius: 4,
    fontWeight: 500,
    color: state.isSelected ? '#18191c' : '#18191c',
    backgroundColor: state.isSelected ? '#f2f2f2' : '#fff',
  }),
  menuList: base => ({
    ...base,
    maxHeight: 300,
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
};

export default CustomSelect;
