/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';
import Moment from 'react-moment';
// import Swal from 'sweetalert2';

//* API
import { selectStudentNcsList } from '@api/teacher/ncs';

//* Main
export default React.memo(function TenacityActionReport(props) {
  const { studentIdx } = useParams();

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // NCS 목록
  const [ncsList, setNcsList] = useState([]);

  //* #################################################################
  //* [ API ] NCS 리스트
  //* #################################################################
  const getStudentNcsList = async () => {
    // Axios
    try {
      const { data } = await selectStudentNcsList({ studentIdx });

      if (data.code === 0) {
        setNcsList(data.data);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getStudentNcsList();
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-ncs">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/teacher/studentMgmt/studentMgmt">학생정보</Link>
            </li>
            <li>
              <Link to="#none">NCS 관리</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>NCS 관리</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section>
            <div className="sub-title">
              <h5>결과 보고서</h5>
            </div>
            <Table>
              <colgroup>
                <col width={160} />
                <col width={160} />
                <col width="*" />
                <col width={200} />
                <col width={120} />
              </colgroup>
              <thead>
                <tr>
                  <th>추가일</th>
                  <th>분류번호</th>
                  <th>NCS분류</th>
                  <th>능력단위</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {ncsList.length > 0 ? (
                  ncsList.map((item, idx) => {
                    return (
                      <tr key={`ncs-${idx}`}>
                        {/* 추가일 */}
                        <td>
                          <Moment date={item.reg_date} format="YYYY.MM.DD" />
                        </td>
                        {/* 분류번호 */}
                        <td>{item.learn_module_seq}</td>
                        {/* NCS 분류 (대분류 > 중분류 > 소분류 > 세분류) */}
                        <td className="text-start">{`${item.l_code_name} > ${item.m_code_name} > ${item.s_code_name} > ${item.sub_code_name}`}</td>
                        {/* 능력단위 */}
                        <td>{item.learn_module_name}</td>
                        {/* 관리 */}
                        <td>{}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <p className="no-data">등록된 NCS가 없습니다.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </section>
        </article>
      </Container>
    </main>
  );
});
