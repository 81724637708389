/**
 *   [ 교사관리 API ]
 *
 */
import Axios from '@api/axios';

const API_TEACHER = 'api/v1/teacher';

const SELECT_TEACHER_LIST = `${API_TEACHER}/teacher-list`;
const RESET_TEACHER_PASSWORD = `${API_TEACHER}/reset-teacher-password`;
const TEACHER_DETAIL = `${API_TEACHER}/teacher-detail`;
const DELETE_TEACHER = `${API_TEACHER}/delete-teacher`;
const TEACHER = `${API_TEACHER}/teacher`;
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 교사관리 : 교사 리스트 조회
export const selectTeacherList = param => {
  return Axios.post(`${SELECT_TEACHER_LIST}`, param);
};
// 교사관리 : 선생 비밀번호 초기화(교사관리 페이지)
export const resetPw = param => {
  return Axios.post(`${RESET_TEACHER_PASSWORD}`, param);
};

// 교사관리 : 선생님 정보 조회
export const selectTeacherDetail = param => {
  return Axios.post(`${TEACHER_DETAIL}`, param);
};

// 교사관리 : 선생님 정보 삭제
export const deleteTeacher = param => {
  return Axios.post(`${DELETE_TEACHER}`, param);
};

// 교사관리 : 선생님 등록/수정
export const upsertTeacher = param => {
  return Axios.post(`${TEACHER}`, param);
};
