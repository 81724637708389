/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { images } from '@assets';
import Select, { components } from 'react-select';

function CheckboxSelect({ options, onChange, submit, value, ...rest }) {
  return (
    <Select
      options={options}
      onChange={onChange}
      styles={customStyles}
      submit={submit}
      components={{ MultiValueLabel }}
      value={value}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isSearchable={false}
      maxMenuHeight={400}
      {...rest}
    />
  );
}

function MultiValueLabel({ children, ...props }) {
  return (
    <components.MultiValueLabel {...props}>
      {children}
      {props.selectProps.value.indexOf(props.data) !==
        props.selectProps.value.length - 1 && ', '}
    </components.MultiValueLabel>
  );
}
const customStyles = {
  // select box
  container: provided => ({
    ...provided,
  }),
  // input box
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    border: '1px solid var(--bs-border) !important',
    borderRadius: 4,
    padding: '0 1rem',
    backgroundImage: `url(${images.icDown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0.75rem center',
    backgroundSize: '10px auto',
    backgroundColor: state.isDisabled ? '#f6f7f9' : '#fff',
    ':focus, :active': {
      borderColor: 'var(--bs-border)',
    },
  }),
  // input
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    textAlign: 'left',
    width: '100%',
    height: '40px',
  }),
  // 우측
  clearIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    display: 'none',
  }),
  // multi value label
  multiValue: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: '0 0.15rem 0 0',
    fontWeight: 600,
    backgroundColor: 'transparent',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    padding: '0 !important',
  }),
  multiValueRemove: provided => ({
    ...provided,
    display: 'none',
  }),
  // options
  menu: provided => ({
    ...provided,
    border: '1px solid var(--bs-border) !important',
    borderRadius: 4,
    boxShadow: 'none',
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    textAlign: 'left',
    padding: '6px 10px',
    backgroundImage: state.isSelected
      ? `url(${images.Checked})`
      : `url(${images.Checkbox})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0.65rem center',
    backgroundColor: 'transparent',
    color: 'var(--bs-dark)',
    borderRadius: 4,
    fontWeight: 600,
  }),
};

export default CheckboxSelect;
