/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { CustomSelect } from '@components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//* Utils
// import Utils from '@common/Utils';

//* API
import {
  getMatchingCompany,
  postMatchingCompanyByInput,
  patchMatchingCompany,
} from '@api/eduadmin/systemConfig';
import { useDaumPostcodePopup } from 'react-daum-postcode';

//* Consts
import {
  COMPANY_GUBUN,
  COMPANY_APPLY_TYPE,
  COMPANY_PAYMENT,
} from '@common/consts';

// IP Input 초기값
const initialCompanyInfo = {
  seq: null, // SEQ
  gubun: '', // 구분(참여기업, 선도기업)
  name: '', // 업체명
  number: '', // 사업자번호
  addr: '', // 주소
  addrDetail: '', // 상세주소
  applyGubun: '', // 취업형태(대기업, 중소기업)
  applyField: '', // 취업업종
  applyJob: '', // 취업직무
  jobStandard: '', // 표준직업분류
  schoolYn: 'N', // 산학일체형 도제학교(Y, N)
  ausYn: 'N', // 아우스 빌둥 참여(Y, N)
  applyStatus: '', // 취업상태
  applyDate: new Date(), // 취업일
  payment: '', // 연봉
  suitYn: 'N', // 전공적합여부
  internCnt: '', // 현장실습건수
  directYn: 'N', // 실습 후 동일기업 취업여부(Y, N)
  stockYn: 'N', // 상장여부
  workerCnt: '', // 종업원수(3년)
  outcome: '', // 매출액(3년)
  paymentAvg: '', // 임시 1(평균연봉)
  legalAddr: '', // 소재지
  legalAddrDetail: '', // 소재지 상세주소
};

//* [ Main ]
export default React.memo(function AdmEduSystemCompWrite(props) {
  const { seq = 0 } = useParams();

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 기업 정보
  const [companyInfo, setCompanyInfo] = useState(initialCompanyInfo);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################

  // ------------------------------------------------------------
  // Daum API
  // ------------------------------------------------------------
  const open = useDaumPostcodePopup();

  // 주소 데이터 전처리
  const handleComplete = (type, data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    // R : 도로명, J : 지번
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    inputHandler(type, fullAddress);
  };

  // 주소 상태 업데이트
  const searchAddress = type => {
    open({
      onComplete: data => handleComplete(type, data),
      defaultQuery: companyInfo.addr,
    });
  };

  // ------------------------------------------------------------
  // Input 입력
  // ------------------------------------------------------------
  const inputHandler = (type, value) => {
    switch (type) {
      // 구분(참여기업, 선도기업)
      case 'GUBUN':
        companyInfo.gubun = value;
        break;
      // 업체명
      case 'NAME':
        companyInfo.name = value;
        break;
      // 사업자번호
      case 'NUMBER':
        companyInfo.number = value;
        break;
      // 주소
      case 'ADDR':
        companyInfo.addr = value;
        break;
      // 주소
      case 'ADDR_DETAIL':
        companyInfo.addrDetail = value;
        break;
      // 취업형태(대기업, 중소기업)
      case 'APPLY_GUBUN':
        companyInfo.applyGubun = value;
        break;
      // 취업업종
      case 'APPLY_FIELD':
        companyInfo.applyField = value;
        break;
      // 취업직무
      case 'APPLY_JOB':
        companyInfo.applyJob = value;
        break;
      // 표준직업분류
      case 'JOB_STANDARD':
        companyInfo.jobStandard = value;
        break;
      // 산학일체형 도제학교(Y, N)
      case 'SCHOOL_YN':
        companyInfo.schoolYn = value ? 'Y' : 'N';
        break;
      // 아우스 빌둥 참여(Y, N)
      case 'AUS_YN':
        companyInfo.ausYn = value ? 'Y' : 'N';
        break;
      // 취업상태
      case 'APPLY_STATUS':
        companyInfo.applyStatus = value ? 'Y' : 'N';
        break;
      // 취업일
      case 'APPLY_DATE':
        companyInfo.applyDate = value;
        break;
      // 연봉
      case 'PAYMENT':
        companyInfo.payment = value;
        break;
      // 전공적합여부
      case 'SUIT_YN':
        companyInfo.suitYn = value ? 'Y' : 'N';
        break;
      // 현장실습건수
      case 'INTERN_CNT':
        companyInfo.internCnt = value;
        break;
      // 실습 후 동일기업 취업여부(Y, N)
      case 'DIRECT_YN':
        companyInfo.directYn = value ? 'Y' : 'N';
        break;
      // 상장여부
      case 'STOCK_YN':
        companyInfo.stockYn = value ? 'Y' : 'N';
        break;
      // 종업원수(3년)
      case 'WORKER_CNT':
        companyInfo.workerCnt = value;
        break;
      // 매출액(3년)
      case 'OUTCOME':
        companyInfo.outcome = value;
        break;
      // 임시 1(평균연봉)
      case 'PAYMENT_AVG':
        companyInfo.paymentAvg = value;
        break;
      // 소재지
      case 'LEGAL_ADDR':
        companyInfo.legalAddr = value;
        break;
      // 소재지 상세
      case 'LEGAL_ADDR_DETAIL':
        companyInfo.legalAddrDetail = value;
        break;
      default:
        break;
    }

    setCompanyInfo({ ...companyInfo });
  };

  // ------------------------------------------------------------
  // Input 입력 검증
  // ------------------------------------------------------------
  const isValidForm = () => {
    const rtnObj = {
      result: false,
      message: '',
    };

    // Input
    const {
      gubun,
      name,
      number,
      addr,
      applyGubun,
      applyField,
      applyJob,
      jobStandard,
      applyStatus,
      applyDate,
      payment,
      workerCnt,
      outcome,
      paymentAvg,
      legalAddr,
    } = companyInfo;

    // if-else
    if (!gubun) {
      rtnObj.message = '구분값을 확인해주세요.';
    }
    if (!name) {
      rtnObj.message = '업체명을 확인해주세요.';
    }
    if (!number) {
      rtnObj.message = '사업자번호를 확인해주세요';
    }
    if (!addr) {
      rtnObj.message = '주소를 확인해주세요.';
    }
    if (!applyGubun) {
      rtnObj.message = '취업형태를 확인해주세요.';
    }
    if (!applyField) {
      rtnObj.message = '업종을 확인해주세요.';
    }
    if (!applyJob) {
      rtnObj.message = '직무를 확인해주세요.';
    }
    if (!jobStandard) {
      rtnObj.message = '표준직업분류를 확인해주세요.';
    }
    if (!applyStatus) {
      rtnObj.message = '취업상태를 확인해주세요.';
    }
    if (!applyDate || applyDate > new Date()) {
      rtnObj.message = '취업일을 확인해주세요.';
    }
    if (!payment) {
      rtnObj.message = '연봉을 확인해주세요.';
    }
    if (!workerCnt) {
      rtnObj.message = '구분값을 확인해주세요.';
    }
    if (!outcome) {
      rtnObj.message = '매출액 확인해주세요.';
    }
    if (!paymentAvg) {
      rtnObj.message = '평균연봉을 확인해주세요.';
    }
    if (!legalAddr) {
      rtnObj.message = '소재지를 확인해주세요.';
    }

    rtnObj.result = true;
    return rtnObj;
  };

  //* #################################################################
  //* [ API ] 기업정보 가져오기 (수정 Only)
  //* #################################################################
  const getCompanyInfo = async () => {
    // Axios
    try {
      const { data } = await getMatchingCompany(seq);
      if (data.code === 0) {
        const info = data.data;

        // 기업정보 갱신
        setCompanyInfo({
          seq: info.seq,
          gubun: info.gubun,
          name: info.name,
          number: info.number,
          applyGubun: info.applyGubun,
          applyField: info.applyField,
          applyJob: info.applyJob,
          jobStandard: info.jobStandard,
          schoolYn: info.schoolYn,
          ausYn: info.ausYn,
          applyStatus: info.applyStatus,
          applyDate: new Date(info.applyDate),
          payment: info.payment,
          suitYn: info.suitYn,
          internCnt: info.internCnt,
          directYn: info.directYn,
          stockYn: info.stockYn,
          workerCnt: info.workerCnt,
          outcome: info.outcome,
          paymentAvg: info.paymentAvg,

          // 주소, 소재지 후처리
          addr: info.addr.split('/')[0].trim(),
          addrDetail:
            info.addr.split('/').length > 0
              ? info.addr.split('/')[1].trim()
              : '',
          legalAddr: info.legalAddr.split('/')[0].trim(),
          legalAddrDetail:
            info.legalAddr.split('/').length > 0
              ? info.legalAddr.split('/')[1].trim()
              : '',
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 등록 & 수정
  //* #################################################################
  const requestHandler = async () => {
    // Axios
    try {
      // Input 확인 > Swal
      const { result, message } = isValidForm();

      if (!result) {
        Swal.fire({
          text: message,
          confirmButtonText: '확인',
        });
        return;
      }

      // 주소 & 소재지 정보 후처리 ( 기본 + 상세 )
      companyInfo.addr = `${companyInfo.addr} / ${companyInfo.addrDetail}`;
      companyInfo.legalAddr = `${companyInfo.legalAddr} / ${companyInfo.legalAddrDetail}`;

      // 수정
      if (seq && seq > 0) {
        const { data } = await patchMatchingCompany(companyInfo);
        if (data.code === 0) {
          props.history.push('/admEdu/system/comp'); // 목록으로
        }
      }
      // 등록
      else {
        const { data } = await postMatchingCompanyByInput(companyInfo);
        if (data.code === 0) {
          props.history.push('/admEdu/system/comp'); // 목록으로
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    // 수정 > 기업정보 가져오기
    if (seq > 0) {
      getCompanyInfo();
    }
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="admEdu-system-comp" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>기업추가</h5>
            </div>
          </section>

          <Card>
            <Card.Body>
              {/* -------------------- 기본정보 -------------------- */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table type-simple">
                  {/* 구분 & 취업형태 */}
                  <Row className="table-row">
                    {/* 구분 */}
                    <Col className="th col-2">구분</Col>
                    <Col className="td col-4">
                      {COMPANY_GUBUN.map((item, index) => (
                        <Form.Check
                          key={`company-gubun-${index}`}
                          className="me-3"
                          type="radio"
                          name="company-gubun"
                          value={item.value}
                          label={item.label}
                          onChange={e => inputHandler('GUBUN', e.target.value)}
                          checked={+companyInfo.gubun === +item.value}
                        />
                      ))}
                    </Col>
                    {/* 취업형태 */}
                    <Col className="th col-2">취업형태</Col>
                    <Col className="td col-4">
                      {COMPANY_APPLY_TYPE.map((item, index) => (
                        <Form.Check
                          key={`company-apply-type-${index}`}
                          className="me-3"
                          type="radio"
                          name="company-apply-type"
                          value={item.value}
                          label={item.label}
                          onChange={e =>
                            inputHandler('APPLY_GUBUN', e.target.value)
                          }
                          checked={+companyInfo.applyGubun === +item.value}
                        />
                      ))}
                    </Col>
                  </Row>

                  {/* 업체명 */}
                  <Row className="table-row">
                    <Col className="th col-2">업체명</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="업체명을 입력하세요."
                        maxLength="30"
                        value={companyInfo.name}
                        onChange={e => inputHandler('NAME', e.target.value)}
                      />
                    </Col>
                  </Row>

                  {/* 주소 */}
                  <Row className="table-row">
                    <Col className="th col-2">주소</Col>
                    <Col className="td col-10 d-block">
                      <div className="d-flex">
                        <Form.Control
                          type="text"
                          placeholder="주소를 입력하세요."
                          className="input-search mb-1"
                          maxLength="60"
                          value={companyInfo.addr}
                          onChange={e => inputHandler('ADDR', e.target.value)}
                        />
                        <Button
                          className="ms-1"
                          size="sm"
                          variant="primary"
                          onClick={e => {
                            searchAddress('ADDR');
                          }}
                        >
                          검색
                        </Button>
                      </div>
                      <div>
                        <Form.Control
                          type="text"
                          placeholder="상세주소를 입력하세요."
                          className="w-100"
                          maxLength="20"
                          value={companyInfo.addrDetail}
                          onChange={e =>
                            inputHandler('ADDR_DETAIL', e.target.value)
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* 사업자번호 */}
                  <Row className="table-row">
                    <Col className="th col-2">사업자번호</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="사업자번호를 입력하세요."
                        maxLength="20"
                        value={companyInfo.number}
                        onChange={e => inputHandler('NUMBER', e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </section>

              {/* -------------------- 취업정보 -------------------- */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>취업정보</h5>
                </div>
                <div className="split-table type-simple">
                  {/* 취업일 */}
                  <Row className="table-row">
                    <Col className="th col-2">취업일</Col>
                    <Col className="td col-10">
                      <div className="mw-input">
                        <DatePicker
                          className="input-datepicker"
                          name="acquisition-date"
                          dateFormat="yyyy.MM.dd"
                          // maxDate={new Date()}
                          customInput={
                            <Form.Control style={{ width: '100%' }} />
                          }
                          showPopperArrow={false}
                          selected={companyInfo.applyDate}
                          onChange={date => inputHandler('APPLY_DATE', date)}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* 연봉 */}
                  <Row className="table-row">
                    <Col className="th col-2">연봉</Col>
                    <Col className="td col-10">
                      <CustomSelect
                        className="w-25"
                        options={COMPANY_PAYMENT}
                        value={
                          COMPANY_PAYMENT.filter(
                            item => +item.value === +companyInfo.payment,
                          )[0]
                        }
                        onChange={option =>
                          inputHandler('PAYMENT', option.value)
                        }
                        placeholder="선택"
                      />
                    </Col>
                  </Row>

                  {/* 업종 */}
                  <Row className="table-row">
                    <Col className="th col-2">업종</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="업종을 입력하세요."
                        maxLength="15"
                        value={companyInfo.applyField}
                        onChange={e =>
                          inputHandler('APPLY_FIELD', e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 직무 */}
                  <Row className="table-row">
                    <Col className="th col-2">직무</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="직무를 입력하세요."
                        maxLength="15"
                        value={companyInfo.applyJob}
                        onChange={e =>
                          inputHandler('APPLY_JOB', e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 취업상태 */}
                  <Row className="table-row">
                    <Col className="th col-2">취업상태</Col>
                    <Col className="td col-10">
                      <Form.Check
                        type="checkbox"
                        label=""
                        checked={companyInfo.applyStatus === 'Y'}
                        onChange={e =>
                          inputHandler('APPLY_STATUS', e.target.checked)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 표준직업분류 */}
                  <Row className="table-row">
                    <Col className="th col-2">표준직업분류</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="표준직업분류를 입력하세요."
                        maxLength="15"
                        value={companyInfo.jobStandard}
                        onChange={e =>
                          inputHandler('JOB_STANDARD', e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 산학일체형 도제학교 */}
                  <Row className="table-row">
                    <Col className="th col-2">산학일체형 도제학교</Col>
                    <Col className="td col-10">
                      <Form.Check
                        type="checkbox"
                        label=""
                        checked={companyInfo.schoolYn === 'Y'}
                        onChange={e =>
                          inputHandler('SCHOOL_YN', e.target.checked)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 아우스 빌둥 참여 */}
                  <Row className="table-row">
                    <Col className="th col-2">아우스 빌둥 참여</Col>
                    <Col className="td col-10">
                      <Form.Check
                        type="checkbox"
                        label=""
                        checked={companyInfo.ausYn === 'Y'}
                        onChange={e => inputHandler('AUS_YN', e.target.checked)}
                      />
                    </Col>
                  </Row>

                  {/* 현장실습건수 */}
                  <Row className="table-row">
                    <Col className="th col-2">현장실습건수</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="현장실습건수를 입력하세요."
                        maxLength="10"
                        value={companyInfo.internCnt}
                        onChange={e =>
                          inputHandler('INTERN_CNT', e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 전공적합여부 */}
                  <Row className="table-row">
                    <Col className="th col-2">전공적합여부</Col>
                    <Col className="td col-10">
                      <Form.Check
                        type="checkbox"
                        label=""
                        checked={companyInfo.suitYn === 'Y'}
                        onChange={e =>
                          inputHandler('SUIT_YN', e.target.checked)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 실습 후 동일기업 취업여부 */}
                  <Row className="table-row">
                    <Col className="th col-2">실습 후 동일기업 취업여부</Col>
                    <Col className="td col-10">
                      <Form.Check
                        type="checkbox"
                        label=""
                        checked={companyInfo.directYn === 'Y'}
                        onChange={e =>
                          inputHandler('DIRECT_YN', e.target.checked)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 상장여부 */}
                  <Row className="table-row">
                    <Col className="th col-2">상장여부</Col>
                    <Col className="td col-10">
                      <Form.Check
                        type="checkbox"
                        label=""
                        checked={companyInfo.stockYn === 'Y'}
                        onChange={e =>
                          inputHandler('STOCK_YN', e.target.checked)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 종업원수(3년) */}
                  <Row className="table-row">
                    <Col className="th col-2">종업원수(3년, 명)</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="3년 평균 종업원수를 입력하세요."
                        maxLength="10"
                        value={companyInfo.workerCnt}
                        onChange={e =>
                          inputHandler('WORKER_CNT', e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 매출액(3년) */}
                  <Row className="table-row">
                    <Col className="th col-2">매출액(3년, 억)</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="3년 평균 매출액을 입력하세요."
                        maxLength="50"
                        value={companyInfo.outcome}
                        onChange={e => inputHandler('OUTCOME', e.target.value)}
                      />
                    </Col>
                  </Row>

                  {/* 평균연봉 */}
                  <Row className="table-row">
                    <Col className="th col-2">평균연봉(만원)</Col>
                    <Col className="td col-10">
                      <Form.Control
                        className="w-50"
                        placeholder="평균연봉을 입력하세요."
                        maxLength="15"
                        value={companyInfo.paymentAvg}
                        onChange={e =>
                          inputHandler('PAYMENT_AVG', e.target.value)
                        }
                      />
                    </Col>
                  </Row>

                  {/* 소재지 */}
                  <Row className="table-row">
                    <Col className="th col-2">소재지</Col>
                    <Col className="td col-10 d-block">
                      <div className="d-flex">
                        <Form.Control
                          type="text"
                          placeholder="주소를 입력하세요."
                          className="input-search mb-1"
                          maxLength="60"
                          value={companyInfo.legalAddr}
                          onChange={e =>
                            inputHandler('LEGAL_ADDR', e.target.value)
                          }
                        />
                        <Button
                          className="ms-1"
                          size="sm"
                          variant="primary"
                          onClick={e => {
                            searchAddress('LEGAL_ADDR');
                          }}
                        >
                          검색
                        </Button>
                      </div>
                      <div>
                        <Form.Control
                          placeholder="상세주소를 입력하세요."
                          maxLength="20"
                          value={companyInfo.legalAddrDetail}
                          onChange={e =>
                            inputHandler('LEGAL_ADDR_DETAIL', e.target.value)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>

              {/* 버튼 */}
              <div className="btn-area mb-0">
                {/* 취소 */}
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={() => props.history.goBack()}
                >
                  취소
                </Button>

                {/* 등록 & 수정 */}
                <Button size="lg" variant="primary" onClick={requestHandler}>
                  {seq === 0 ? '등록' : '수정'}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});
