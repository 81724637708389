/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { Utils } from '@common';
import { CustomModal } from '@components';
import {
  resetPw,
  selectTeacherDetail,
  deleteTeacher,
} from '@api/teacher/teacherMgmt';
import { TEACHER_ROLE_DETAIL_HAN } from '@common/consts';
import Swal from 'sweetalert2';

export default React.memo(function TeacherDetail(props) {
  const history = useHistory();

  const { seq } = useParams();
  // 제대로 된 페이지로 들어왔는지 확인

  if (!window.parseInt(seq)) {
    history.replace('/home');
  }

  /** modal */
  // 비밀번호 초기화
  const [pwdInitModalShow, setPwdInitModalShow] = useState(false);
  const openPwdInitModal = () => {
    setPwdInitModalShow(true);
  };
  const closePwdInitModal = () => {
    setPwdInitModalShow(false);
  };
  const submitPwdInitModal = e => {
    resetTeacherPw(e);
  };

  /** form state */
  const [teacherInfo, setTeacherInfo] = useState({});

  /** API */
  // 선생 정보 조회
  const getTeacherInfo = async () => {
    const params = {
      teacher_idx: seq,
    };
    try {
      const { data } = await selectTeacherDetail(params);
      if (data.code === 0) {
        if (data.data && data.data.teacherInfo) {
          setTeacherInfo(data.data.teacherInfo);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // 선생 비밀번호 초기화
  const resetTeacherPw = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    const params = {
      teacher_idx: seq,
    };
    try {
      const { data } = await resetPw(params);
      if (data.code === 0) {
        Swal.fire({
          html: '비밀번호가 초기화되었습니다.<br/>초기화된 비밀번호는 계정 아이디와 동일합니다.',
          confirmButtonText: '확인',
        });
        closePwdInitModal();
      }
    } catch (e) {
      target.disabled = false;
      closePwdInitModal();
    }
    target.disabled = false;
  };

  // 선생 삭제
  const removeTeacher = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    // eslint-disable-next-line no-restricted-globals
    if (
      !(await Swal.fire({
        text: '해당 선생님에 대한 정보를 삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }
    const params = {
      teacher_idx: seq,
    };
    try {
      const { data } = await deleteTeacher(params);
      if (data.code === 0) {
        Swal.fire({
          text: '해당 선생님에 대한 정보가 삭제되었습니다.',
          confirmButtonText: '확인',
        });
        history.push('/teacher/teacherMgmt');
      }
    } catch (e) {
      target.disabled = false;
    }
    target.disabled = false;
  };

  /** useEffect */
  useEffect(() => {
    getTeacherInfo();
  }, []);

  return (
    <main id="teacher-mgmt-detail" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/teacher/teacherMgmt">교사관리</Link>
            </li>
            <li>
              <Link to="#none">교사정보</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>교사정보</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Header>
              <h5>{Utils.decrypt(teacherInfo.teacher_name)}</h5>
            </Card.Header>
            <Card.Body>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>학교정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">학교</Col>
                    <Col className="td col-10">{teacherInfo.school_name}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">담당학급</Col>
                    <Col className="td col-10">
                      {teacherInfo.grade &&
                        `${
                          +teacherInfo.grade === 0 ? '전체' : teacherInfo.grade
                        }학년`}
                      {teacherInfo.ban_no &&
                        `${window.parseInt(teacherInfo.ban_no)}반`}
                      {!teacherInfo.grade && !teacherInfo.ban_no && '-'}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">아이디</Col>
                    <Col className="td col-10">{teacherInfo.teacher_id}</Col>
                  </Row>
                </div>
              </section>

              <section className="mb-5">
                <div className="sub-title">
                  <h5>교사관리</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">직책</Col>
                    <Col className="td col-10">
                      {teacherInfo.role_detail
                        ? TEACHER_ROLE_DETAIL_HAN[teacherInfo.role_detail]
                        : '-'}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">학적상태</Col>
                    <Col className="td col-10">
                      {teacherInfo.teacher_gubun_han !== '결과없음'
                        ? teacherInfo.teacher_gubun_han
                        : '-'}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">비밀번호</Col>
                    <Col className="td col-10">
                      <Button
                        variant="outline-primary"
                        onClick={openPwdInitModal}
                      >
                        비밀번호 초기화
                      </Button>
                    </Col>
                  </Row>
                </div>
              </section>

              {/* ////////// ↓ 하단 버튼 //////////  */}
              <div className="btn-area mb-0">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={removeTeacher}
                >
                  삭제
                </Button>
                <Button
                  onClick={() =>
                    props.history.push(`/teacher/teacherMgmt/edit/${seq}`)
                  }
                  size="lg"
                  variant="primary"
                >
                  수정
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() => history.push('/teacher/teacherMgmt')}
                >
                  목록으로
                </Button>
              </div>
            </Card.Body>
          </Card>
        </article>
      </Container>

      <CustomModal
        title="알림"
        show={pwdInitModalShow}
        onHide={closePwdInitModal}
        size="sm"
      >
        <Modal.Body className="border-0">
          <p className="text-center">비밀번호를 초기화 하시겠습니까?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closePwdInitModal}>
            취소
          </Button>
          <Button onClick={submitPwdInitModal}>초기화</Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
