/**
 *   [ 인성 자존감 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const MY_INFO = `${API_PROGRAM}/my-info`;
const UPDATE_MY_INFO = `${API_PROGRAM}/update-my-info`;
const UPDATE_PASSWORD = `${API_PROGRAM}/password`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 내 정보 가져오기
export const selectStudentInfo = () => {
  return Axios.post(`${MY_INFO}`);
};
// 내 정보 업데이트
export const updateStudentInfo = params => {
  return Axios.post(`${UPDATE_MY_INFO}`, params);
};
// 비밀번호 변경
export const updatePw = params => {
  return Axios.post(`${UPDATE_PASSWORD}`, params);
};
