/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  Card,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import { CustomSelect, PdfPreview, PreviewModal } from '@components';

//* Utils
import Utils from '@common/Utils';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import { useReactToPrint } from 'react-to-print';

//* CONSTS
import {
  APPROVE_STATE_TYPE_LIST,
  FILE_FORM_TYPE,
  FormStatus,
  LOGIN_TYPE,
  RESEARCH_POINT_TYPE_LIST,
  ROLE,
} from '@common/consts';

//* API
import {
  selectEduProgramInfoByTeacher,
  updateStatusFormProgramInfo,
} from '@api/teacher/selfEduProgram';
import { getFilePathFromRedis } from '@api/file';

// 유저 정보 Form
const initialUserInfo = {
  user: '',
  id: '',
  grade: '',
  status: 'NONE',
  point: '0',
  name: '',
  date: '',
  gubun: '',
  title: '',
  contents: '',
  files: [],
};

//* [ Main ]
export default React.memo(function SelfEduProgramComment(props) {
  const history = useHistory();

  // 유저 권한 타입
  const authType = props.userInfo.type;

  const { loginType, seq, studentIdx } = useParams();

  if (loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(seq)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  //* #################################################################
  //* [ States ]
  //* #################################################################
  const [approveStateType, setApproveStateType] = useState(
    APPROVE_STATE_TYPE_LIST[0].value,
  );

  // Form 데이터
  const [formInfo, setFormInfo] = useState(initialUserInfo);
  const [researchResult, setResearchResult] = useState({});
  // const [isValidForm, setIsValidForm] = useState(false);

  // 설문조사 Form
  const [researchFormList, setResearchFormList] = useState([]);

  // 교사 코멘트
  const [teacherComments, setTeacherComments] = useState([]);

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  const [comment, setComment] = useState('');
  const [teacherCommentList, setTeacherCommentList] = useState([]);
  const [firstCallDone, setFirstCallDone] = useState(false);

  // select
  const scoreOptions = [
    { label: '1점', value: '1' },
    { label: '2점', value: '2' },
    { label: '3점', value: '3' },
    { label: '4점', value: '4' },
    { label: '5점', value: '5' },
  ];

  const [scoreSelected, setScoreSelected] = useState(scoreOptions[2]);

  const [linkList, setLinkList] = useState([]);

  // PDF 미리보기 ( react-pdf )
  const [fileUrl, setFileUrl] = useState(null);
  const [pdfPageNum, setPdfPageNum] = useState(1);
  const [pdfTotalPages, setPdfTotalPages] = useState(1);

  //* #################################################################
  //* [ Preview Modal ]
  //* #################################################################
  // Flag
  const [previewModalShow, setPreviewModalShow] = useState(false);

  // Open
  const openPreviewModal = () => {
    openFile({ fileSeq: null });
    setPreviewModalShow(true);
  };

  // Close
  const closePreviewModal = () => {
    setPreviewModalShow(false);
  };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // PDF 파일 로딩 완료
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfPageNum(1);
    setPdfTotalPages(numPages);
  };

  // 인쇄
  const printWindowRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  //* #################################################################
  //* [ API ] 승인, 승인취소
  //* #################################################################
  const updateRequest = async (target, reqStatus) => {
    target.disabled = true;

    // Axios
    const params = {
      formSeq: seq,
      status: reqStatus,
      requestPoint: scoreSelected.value,
      comment,
    };
    try {
      const { data } = await updateStatusFormProgramInfo(params);

      if (data.code === 0) {
        window.location.reload();
      }
    } catch (e) {
      target.disabled = false;
      // alert(e.response.data.message);
    }

    target.disabled = false;
  };

  //* #################################################################
  //* [ API ] Form 정보 가져오기
  //* #################################################################
  const getInfo = async () => {
    // 파라미터
    const param = {
      formSeq: seq,
      studentIdx,
    };

    // Axios
    try {
      const { data } = await selectEduProgramInfoByTeacher(param);

      if (data.code === 0) {
        const formData = data.data;

        // Form 갱신
        setFormInfo({
          user: formData.name,
          id: formData.id,
          grade: formData.grade,

          status: formData.status,
          point: formData.point,
          orgPoint: formData.org_point,
          confirmDate: formData.confirm_date,

          name: formData.program_name,
          date: formData.program_date,
          gubun: formData.program_gubun,
          title: formData.program_title,
          contents: formData.program_contents,
          files: formData.files || [],
        });

        setTeacherCommentList(formData.commentList);

        // 설문조사 Form 갱신
        setResearchFormList(formData.researchList || []);

        // 설문조사 응답 갱신
        const {
          research_idx: researchIdx = '',
          research_response: resarchResponse = '',
        } = formData;

        if (researchIdx && resarchResponse) {
          const updateMap = {};

          const idxs = researchIdx.split(';'); // 설문 항목
          const responses = resarchResponse.split(';'); // 설문 항목별 입력값

          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in idxs) {
            updateMap[`${idxs[i]}`] = responses[i];
          }

          setResearchResult(updateMap);
        }

        // 교사 코멘트
        setTeacherComments([...formData.commentList]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 파일 조회 ( 첨부파일, 승인이수 )
  //* #################################################################
  const openFile = async ({ fileSeq }) => {
    let paramMap;

    // [ CASE 1 ] 첨부파일 조회
    if (fileSeq) {
      paramMap = {
        formType: FILE_FORM_TYPE.FILE_FREE_PROGRAM,
        fileSeq,
      };
    }
    // [ CASE 2 ] 승인이수 조회
    else {
      let formType;

      switch (+formInfo.grade) {
        case 1:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_FREE_1;
          break;
        case 2:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_FREE_2;
          break;
        case 3:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_FREE_3;
          break;
        default:
          Swal.fire({
            text: '잘못된 접근입니다.',
            confirmButtonText: '확인',
          });
          return;
      }

      paramMap = {
        formType,
        fileSeq,
      };
    }

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        // 파일 다운로드 (첨부파일)
        if (fileSeq) {
          window.open(url);
        }
        // PDF 미리보기 (이수기준)
        else {
          setFileUrl(url);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
          {
            name: `${formInfo.grade}학년 자율 교육 프로그램`,
            path: `/teacher/selfEduProgram-teacher/${formInfo.grade}/${studentIdx}`,
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
          {
            name: `${formInfo.grade}학년 자율 교육 프로그램`,
            path: `/teacher/selfEduProgram-teacher/${formInfo.grade}/${studentIdx}`,
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType, formInfo]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-selfEduProgram-comment" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">결과 보고서</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title mb-60">
              <h5>{`${formInfo.grade}학년 자율 교육 프로그램 결과 보고서`}</h5>
              <div className="dl-box type-bar lg">
                {/* Form 상태 */}
                <dl className="status-text">
                  <strong className={FormStatus[formInfo.status].class}>
                    {FormStatus[formInfo.status].text}
                    <br />
                    {formInfo.status === 'CON' && (
                      <small>
                        {Utils.getJustDateString(formInfo.confirmDate)}
                      </small>
                    )}
                  </strong>
                </dl>
                {/* 점수 */}
                <dl>
                  <dd>
                    <strong>{formInfo.point}</strong>
                  </dd>
                </dl>
              </div>
            </div>

            <div className="mb-4">
              <Button variant="text-underline" onClick={openPreviewModal}>
                승인이수 보기
              </Button>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Body>
              {/* 기본정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table">
                  {/* 학번 */}
                  <Row className="table-row">
                    <Col className="th col-2">학번</Col>
                    <Col className="td col-10">{`${formInfo.id}`}</Col>
                  </Row>
                  {/* 이름 */}
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">{`${Utils.decrypt(
                      formInfo.user,
                    )}`}</Col>
                  </Row>
                </div>
              </section>
              {/* 교육정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>교육정보</h5>
                </div>
                <div className="split-table">
                  <Row className="table-row">
                    <Col className="th col-2">교육 프로그램</Col>
                    <Col className="td col-10">{formInfo.name}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">교육일</Col>
                    <Col className="td col-10">{formInfo.date}</Col>
                  </Row>
                </div>
              </section>
              {/* 교육 프로그램명 */}
              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5>교육 프로그램명</h5>
                </div>
                <div className="comment-box">
                  <ul className="comment-list">
                    <li>
                      <p className="text">{formInfo.title}</p>
                    </li>
                  </ul>
                </div>
              </section>
              {/* 교육내용 요약 및 정리 */}
              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5>교육내용 요약 및 정리</h5>
                </div>
                <div className="comment-box">
                  <ul className="comment-list">
                    <li>
                      <p className="text">{formInfo.contents}</p>
                    </li>
                  </ul>
                </div>
              </section>

              {/* 파일 첨부 */}
              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5>파일첨부</h5>
                </div>
                {formInfo.files && formInfo.files.length > 0 ? (
                  <ul className="attachment-list">
                    {formInfo.files.map((file, idx) => {
                      return (
                        <li key={idx}>
                          <Button
                            className="btn-icon ic-down"
                            onClick={() => openFile({ fileSeq: file.file_seq })}
                          >
                            {file.file_name_org}
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  firstCallDone && (
                    <ul className="attachment-list">
                      <li>없음</li>
                    </ul>
                  )
                )}
              </section>

              {/* 만족도 조사 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>만족도 조사</h5>
                </div>
                <div className="split-table h-56">
                  <Row className="table-row">
                    <Col className="th col-2 text-gray">조사내용</Col>
                    <Col className="th col-4 text-center text-gray">질문</Col>
                    <Col className="th col-6">
                      <ul className="type-flex-1">
                        <li>매우 그렇다</li>
                        <li>그렇다</li>
                        <li>보통</li>
                        <li>그렇지 않다</li>
                        <li>매우 그렇지 않다</li>
                      </ul>
                    </Col>
                  </Row>
                  {researchFormList.length > 0 &&
                    researchFormList.map((item, idx) => {
                      return (
                        <Row className="table-row" key={idx}>
                          {/* 조사내용 */}
                          <Col className="th col-2">
                            {item.research_contents}
                          </Col>
                          {/* 질문 */}
                          <Col className="td col-4">
                            {item.research_question}
                          </Col>
                          {/* 만족도 입력 */}
                          <Col className="td col-6 flex-between">
                            <div className="radio-group type-flex-1">
                              {RESEARCH_POINT_TYPE_LIST.map(
                                (pointItem, index) => {
                                  return (
                                    <Form.Check
                                      key={`${index}-${pointItem.value}`}
                                      name={idx}
                                      type="radio"
                                      label=""
                                      value={pointItem.value}
                                      id={pointItem.value}
                                      disabled
                                      onChange={e => {
                                        setResearchResult({
                                          ...researchResult,
                                          [item.seq]: e.target.value,
                                        });
                                      }}
                                      checked={
                                        researchResult[item.seq] ===
                                        pointItem.value
                                      }
                                    />
                                  );
                                },
                              )}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                </div>
              </section>

              {authType !== ROLE.ADMIN &&
                formInfo.status === FormStatus.REQ.value && (
                  <section className="mb-5">
                    <div className="sub-title flex-start">
                      <h5>승인여부</h5>
                    </div>
                    <div className="card-radio-group">
                      {APPROVE_STATE_TYPE_LIST.map((item, index) => {
                        return (
                          <Form.Check
                            key={`approve-state-type-${item.value}`}
                            name="approve-state-type"
                            type="radio"
                            label={item.label}
                            value={item.value}
                            id={item.value}
                            onChange={e => setApproveStateType(e.target.value)}
                            checked={approveStateType === item.value}
                          />
                        );
                      })}
                    </div>
                  </section>
                )}

              {/* 교사 코멘트 */}
              <section className="mb-5">
                {((authType !== ROLE.ADMIN &&
                  formInfo.status === FormStatus.REQ.value) ||
                  formInfo.status === FormStatus.CON.value) && (
                  <div>
                    <div className="sub-title">
                      <h5>교사 코멘트</h5>

                      {/* {formInfo.status === FormStatus.REQ.value && ( */}
                      {/*  // ↓ 이 화면에서만 보임 (자율 교육 프로그램) */}
                      {/*  <div className="sw-input smallest"> */}
                      {/*    <CustomSelect */}
                      {/*      value={scoreSelected} */}
                      {/*      onChange={setScoreSelected} */}
                      {/*      options={scoreOptions} */}
                      {/*      isDisabled */}
                      {/*    /> */}
                      {/*  </div> */}
                      {/* )} */}
                    </div>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="최대 700자 이내로 입력하세요."
                      onChange={e => {
                        setComment(e.target.value);
                      }}
                      value={comment || ''}
                      maxLength="700"
                    />
                    <small className="text-gray flex-end mt-2">{`${
                      comment ? comment.length : '0'
                    }/700자`}</small>
                  </div>
                )}

                <div className="comment-box disabled mt-4">
                  <ul className="comment-list">
                    {teacherCommentList.length > 0 &&
                      teacherCommentList.map((item, index) => {
                        return (
                          <li key={`comment-${index}`}>
                            <p className="date">
                              {item.reg_date && (
                                <Moment
                                  format="YYYY.MM.DD"
                                  date={item.reg_date}
                                />
                              )}
                            </p>
                            <p className="text">{item.comment}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </section>

              {/* 교사 코멘트 */}
              {/* {teacherComments && teacherComments.length > 0 &&
                (formInfo.status === 'CON' || formInfo.status === 'REJ') && (
                  <section className="mb-5">
                    <div className="sub-title flex-start">
                      <h5 className="text-primary">교사 코멘트</h5>
                    </div>
                    <div className="comment-box type-primary">
                      {teacherComments.map((v, index) => {
                        return (
                          <ul className="comment-list" key={index}>
                            <li>
                              <p className="date">
                                {
                                  new Date(v.reg_date)
                                    .toISOString()
                                    .split('T')[0]
                                }
                              </p>
                              <p className="text">{v.comment}</p>
                            </li>
                            <br />
                          </ul>
                        );
                      })}
                    </div>
                  </section>
                )} */}
              {/* ////////// ↓ 하단 버튼 //////////  */}
              {authType !== ROLE.ADMIN &&
                formInfo.status === FormStatus.CON.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={e => {
                        const target = e.currentTarget;

                        Swal.fire({
                          title: '알림',
                          text: '취소 하시겠습니까?',
                          confirmButtonText: '확인',
                          cancelButtonText: '취소',
                          showCancelButton: true,
                          reverseButtons: true,
                        }).then(result => {
                          if (result.isConfirmed) {
                            updateRequest(target, FormStatus.REJ.value);
                          }
                        });
                      }}
                    >
                      승인취소
                    </Button>
                  </div>
                )}
              {authType !== ROLE.ADMIN &&
                formInfo.status === FormStatus.REQ.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={e => {
                        const target = e.currentTarget;

                        Swal.fire({
                          title: '알림',
                          text: '제출 하시겠습니까?',
                          confirmButtonText: '확인',
                          cancelButtonText: '취소',
                          showCancelButton: true,
                          reverseButtons: true,
                        }).then(result => {
                          if (result.isConfirmed) {
                            updateRequest(target, approveStateType);
                          }
                        });
                      }}
                    >
                      제출
                    </Button>
                  </div>
                )}
              {authType !== ROLE.ADMIN &&
                formInfo.status !== FormStatus.CON.value &&
                formInfo.status !== FormStatus.REQ.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                  </div>
                )}
              {authType === ROLE.ADMIN && (
                <div className="btn-area mb-0">
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                    variant="outline-primary"
                    size="lg"
                  >
                    목록
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </article>
      </Container>

      {/* PDF 미리보기 모달 */}
      <PreviewModal
        className="preview-modal"
        title="승인이수"
        show={previewModalShow}
        onHide={closePreviewModal}
      >
        <Modal.Body className="border-0 text-center">
          <PdfPreview
            fileUrl={fileUrl}
            loadHandler={onDocumentLoadSuccess}
            pageNum={pdfPageNum}
            totalPages={pdfTotalPages}
            isPageDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closePreviewModal}>
            닫기
          </Button>
        </Modal.Footer>
      </PreviewModal>
    </main>
  );
});
