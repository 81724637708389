/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import { CustomSelect } from '@components';
import { useReactToPrint } from 'react-to-print';

//* Utils
import Utils from '@common/Utils';
import Swal from 'sweetalert2';

//* CONSTS
import { FormStatus, SEMESTER_DROPDOWN } from '@common/consts';

//* API
import {
  selectFormCareer,
  insertUpdateFormCareer,
} from '@api/student/careerDesign';

// 인적사항 Form
const initialUserInfo = {
  name: '',
  major: '',
  grade: '',
  class: '',
  num: '',
};

// 취업, 진학 Form
const initialCareerForm = {
  seq: '',
  grade: '',
  status: '',
  point: '',

  hopeDirection: '', // 진로방향 (코드)
  hopeDirectionHan: '', // 진로방향 (한글)

  hopeJobName: '', // 희망 직업
  hopeJobGubun: '', // 기업형태 (코드)
  hopeJobGubunHan: '', // 기업형태 (한글)
  hopeJobIndustry: '', // 희망 산업체
  hopeJobReason: '', // 진로희망 사유
  hopeJobCertificate: '', // 희망직업 관련 자격증
  hopeJobWork: '', // 희망직업 업무 내용
  hopeJobSkill: '', // 필요기술 및 지식

  hopeUnivName: '', // 희망대학
  hopeUnivGubun: '', // 대학유형 (코드)
  hopeUnivGubunHan: '', // 대학유형 (한글)
  hopeUnivApply: '', // 지원형태 (코드)
  hopeUnivApplyHan: '', // 지원형태 (한글)
  hopeUnivMajor: '', // 희망학과
  hopeUnivReason: '', // 진학희망 사유
  hopeUnivGrade: '', // 필요등급
};

// 교과이수 Form
const initialStudyPlan = {
  '1-1': {
    mon: ';;;;;;;',
    tue: ';;;;;;;',
    wed: ';;;;;;;',
    thu: ';;;;;;;',
    fri: ';;;;;;;',
  },
};

// 테이블 행-열
const scheduleDays = ['월', '화', '수', '목', '금']; // 요일
const scheduleClasses = [1, 2, 3, 4, 5, 6, 7]; // 교시

//* [ Main ]
export default React.memo(function CareerDesignReport(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 인적사항
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  // 진로방향
  const [careerForm, setCareerForm] = useState(initialCareerForm);

  // 교과이수
  const [curSemester, setCurSemester] = useState('1-1'); // 학기정보
  // const [selectedOption, setSelectedOption] = useState(SEMESTER_DROPDOWN[0]); // 선택 학기정보 (옵션)
  // const [semesterDropdown, setSemesterDropdown] = useState(SEMESTER_DROPDOWN); // 학기정보 드랍다운
  // const [studyPlan, setStudyPlan] = useState(initialStudyPlan);

  // 자격증
  const [certList, setCertList] = useState([]);

  // 특별활동
  const [actList, setActList] = useState([]);

  // 교사 코멘트
  const [commentList, setCommentList] = useState([]);

  // const [type, setType] = useState('student'); // viewType (student, teacher, eduAdm)

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 학기 선택
  // const semesterHandler = data => {
  //   setSelectedOption(data);
  // };

  // 인쇄
  const printWindowRef = useRef();

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  //* #################################################################
  //* [ API ] 진로설계 정보 가져오기
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const selectFormData = async () => {
    // Axios
    try {
      const { data } = await selectFormCareer({});

      if (data.code === 0) {
        setFirstCallDone(true);
        const { career_form: career } = data.data;

        // 인적사항
        setUserInfo({
          name: career.name,
          major: career.major,
          grade: career.grade,
          class: career.ban_no,
          num: career.student_no,
        });

        // 진로뱡향, 교과이수, 자격증, 체험활동
        if (career.has_form) {
          const {
            study_plan: study = {},
            certi_list: certi = [],
            act_list: act = [],
            comment_list: comment = [],
          } = data.data;

          // 자격증 취득계획
          setCertList([...certi]);

          // 창의적 체험활동
          setActList([...act]);

          // 교사 코멘트
          setCommentList([...comment]);

          // 진로뱡향
          setCareerForm({
            seq: career.form_seq,
            status: career.status,
            point: career.point,

            hopeDirection: career.hope_direction, // 진로유형 (코드)
            hopeDirectionHan: career.hope_direction_han, // 진로유형 (한글)

            hopeJobName: career.hope_job_name, // 희망 직업
            hopeJobGubun: career.hope_job_gubun, // 기업형태 (코드)
            hopeJobGubunHan: career.hope_job_gubun_han, // 기업형태 (한글)
            hopeJobIndustry: career.hope_job_industry, // 희망 산업체
            hopeJobReason: career.hope_job_reason, // 진로희망 사유
            hopeJobCertificate: career.hope_job_certificate, // 희망직업 관련 자격증
            hopeJobWork: career.hope_job_work, // 희망직업 업무 내용
            hopeJobSkill: career.hope_job_skill, // 필요기술 및 지식

            hopeUnivName: career.hope_univ_name, // 희망대학
            hopeUnivGubun: career.hope_univ_gubun, // 대학유형 (코드)
            hopeUnivGubunHan: career.hope_univ_gubun_han, // 대학유형 (한글)
            hopeUnivApply: career.hope_univ_apply, // 지원형태 (코드)
            hopeUnivApplyHan: career.hope_univ_apply_han, // 지원형태 (한글)
            hopeUnivMajor: career.hope_univ_major, // 희망학과
            hopeUnivReason: career.hope_univ_reason, // 진학희망 사유
            hopeUnivGrade: career.hope_univ_grade, // 필요등급
          });

          // 현재 학기
          // setStudyPlan(study);

          // 학기 리스트 필터링
          const storedSemsterList = Object.keys(study);

          const filteredSemesterList = SEMESTER_DROPDOWN.filter(item =>
            storedSemsterList.includes(item.value),
          ).sort(
            (a, b) => b.value.replaceAll('-', '') - a.value.replaceAll('-', ''),
          );

          // setSemesterDropdown(filteredSemesterList);

          // 최신 학기 > default 선택
          // setCurSemester(filteredSemesterList[0].value);
          // setSelectedOption(filteredSemesterList[0]);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 진로설계 신규등록 & 수정
  //* #################################################################
  const updateRequest = async reqStatus => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 승인요청 필수값 체크
    if (reqStatus === 'REQ') {
      if (+careerForm.hopeDirection === 1) {
        if (!careerForm.hopeJobName) {
          Swal.fire({
            text: '희망직업이 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeJobGubun) {
          Swal.fire({
            text: '기업형태가 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeJobIndustry) {
          Swal.fire({
            text: '희망산업체가 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeJobReason) {
          Swal.fire({
            text: '진로희망 사유가 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeJobCertificate) {
          Swal.fire({
            text: '희망직업 관련 자격증이 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeJobWork) {
          Swal.fire({
            text: '희망직업이 하는 일이 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeJobSkill) {
          Swal.fire({
            text: '',
            confirmButtonText: '확인',
          });
          return;
        }
      } else if (+careerForm.hopeDirection === 2) {
        if (!careerForm.hopeUnivName) {
          Swal.fire({
            text: '희망대학이 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeUnivApply) {
          Swal.fire({
            text: '지원형태가 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeUnivMajor) {
          Swal.fire({
            text: '희망학과가 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeUnivReason) {
          Swal.fire({
            text: '진학희망 사유가 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
        if (!careerForm.hopeUnivGrade) {
          Swal.fire({
            text: '필요등급이 입력되지 않았습니다.',
            confirmButtonText: '확인',
          });
          return;
        }
      }
    }

    // JSON 파라미터
    const param = {
      formSeq: careerForm.seq,
      status: reqStatus,
    };

    // 중복요청 방지 해제
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await insertUpdateFormCareer(param);

      if (data.code === 0) {
        // 새로고침
        window.location.reload();
      }
    } catch (e) {
      // 중복요청 방지 해제
      setIsSentRequest(false);

      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    selectFormData();

    // return () => {}
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (삭제, 수정, 취소)
  const customButton = type => {
    let variant;
    let event;
    let text;

    switch (type) {
      case 'print':
        variant = 'outline-primary';
        event = e => {
          handlePrint();
        };
        text = '인쇄';
        break;
      case 'delete':
        variant = 'outline-primary';
        event = e => {
          Swal.fire({
            title: '알림',
            text: '삭제하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest('DEL');
            }
          });
        };
        text = '삭제';
        break;
      case 'edit':
        variant = 'primary';
        event = e => {
          props.history.push(
            `/student/indivAbility/careerDesign/write/${careerForm.seq}`,
          );
        };
        text = '수정';
        break;
      case 'cancel':
        variant = 'primary';
        event = e => {
          Swal.fire({
            title: '알림',
            text: '승인요청을 취소하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest('ING');
            }
          });
        };
        text = '승인요청 취소';
        break;
      case 'request':
        variant = 'primary';
        event = e => {
          Swal.fire({
            title: '알림',
            text: '승인요청하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest('REQ');
            }
          });
        };
        text = '승인요청';
        break;
      default:
        break;
    }

    return (
      <Button size="lg" variant={variant} onClick={event}>
        {text}
      </Button>
    );
  };

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-careerDesign-report">
      {careerForm.seq ? (
        // 작성된 글이 있을 때
        <Container ref={printWindowRef}>
          <article className="content">
            <ul className="path">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="#none">진로설계</Link>
              </li>
            </ul>

            {/* ////////// ↓ 상단 타이틀영역 ////////// */}
            <section>
              <div className="title">
                <h5>진로설계</h5>
                <div className="dl-box type-bar lg">
                  {/* 승인상태 */}
                  <dl>
                    <strong className={FormStatus[careerForm.status].class}>
                      {FormStatus[careerForm.status].text}
                    </strong>
                  </dl>
                  {/* 점수 */}
                  <dl>
                    <dd>
                      <strong>{careerForm.point || 0}</strong>
                    </dd>
                  </dl>
                </div>
              </div>
            </section>

            {/* ////////// ↓ 컨텐츠 ////////// */}

            {/* 인적사항 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>인적사항</h5>
              </div>
              <div className="split-table">
                <Row className="table-row">
                  <Col className="th col-2">이름</Col>
                  <Col className="td col-10">
                    {Utils.decrypt(userInfo.name)}
                  </Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">학과</Col>
                  <Col className="td col-10">{userInfo.major}</Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">학년/반/번호</Col>
                  <Col className="td col-10">
                    {+userInfo.grade || ''}학년 {+userInfo.class || ''}반{' '}
                    {+userInfo.num || ''}번
                  </Col>
                </Row>
              </div>
            </section>

            {/* 진로방향 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>진로방향</h5>
              </div>
              {careerForm.hopeDirection === '1' && (
                <div className="split-table">
                  <Row className="table-row">
                    <Col className="th col-2">진로방향</Col>
                    <Col className="td col-10">
                      {careerForm.hopeDirection && careerForm.hopeDirectionHan}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">희망직업</Col>
                    <Col className="td col-10">
                      {careerForm.hopeJobName || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">기업형태</Col>
                    <Col className="td col-10">
                      {careerForm.hopeJobGubun && careerForm.hopeJobGubunHan}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">희망산업체</Col>
                    <Col className="td col-10">
                      {careerForm.hopeJobIndustry || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">진로희망 사유</Col>
                    <Col className="td col-10">
                      {careerForm.hopeJobReason || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">희망직업 관련 자격증</Col>
                    <Col className="td col-10">
                      {careerForm.hopeJobCertificate || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">희망직업이 하는 일</Col>
                    <Col className="td col-10">
                      {careerForm.hopeJobWork || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">필요기술 및 지식</Col>
                    <Col className="td col-10">
                      {careerForm.hopeJobSkill || ''}
                    </Col>
                  </Row>
                </div>
              )}
              {careerForm.hopeDirection === '2' && (
                <div className="split-table">
                  <Row className="table-row">
                    <Col className="th col-2">진로방향</Col>
                    <Col className="td col-10">
                      {careerForm.hopeDirection && careerForm.hopeDirectionHan}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">희망대학</Col>
                    <Col className="td col-10">
                      {careerForm.hopeUnivName || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">대학형태</Col>
                    <Col className="td col-10">
                      {careerForm.hopeUnivGubun && careerForm.hopeUnivGubunHan}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">지원형태</Col>
                    <Col className="td col-10">
                      {careerForm.hopeUnivApply && careerForm.hopeUnivApplyHan}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">희망학과</Col>
                    <Col className="td col-10">
                      {careerForm.hopeUnivMajor || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">진학희망 사유</Col>
                    <Col className="td col-10">
                      {careerForm.hopeUnivReason || ''}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">필요등급</Col>
                    <Col className="td col-10">
                      {careerForm.hopeUnivGrade || ''}
                    </Col>
                  </Row>
                </div>
              )}
            </section>

            {/* 교과 이수 */}
            {/* <section className="mb-5">
              <div className="sub-title">
                <h5>교과 이수</h5>
                <div className="sw-input">
                  <CustomSelect
                    options={semesterDropdown}
                    value={selectedOption}
                    onChange={semesterHandler}
                  />
                </div>
              </div>
              <Table>
                <colgroup>
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    {scheduleDays.map((day, index) => (
                      <th key={index}>{day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {scheduleClasses.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th>{item}교시</th>
                        <td>
                          {studyPlan[selectedOption.value] &&
                            studyPlan[selectedOption.value].mon.split(';')[
                              item - 1
                            ]}
                        </td>
                        <td>
                          {studyPlan[selectedOption.value] &&
                            studyPlan[selectedOption.value].tue.split(';')[
                              item - 1
                            ]}
                        </td>
                        <td>
                          {studyPlan[selectedOption.value] &&
                            studyPlan[selectedOption.value].wed.split(';')[
                              item - 1
                            ]}
                        </td>
                        <td>
                          {studyPlan[selectedOption.value] &&
                            studyPlan[selectedOption.value].thu.split(';')[
                              item - 1
                            ]}
                        </td>
                        <td>
                          {studyPlan[selectedOption.value] &&
                            studyPlan[selectedOption.value].fri.split(';')[
                              item - 1
                            ]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </section> */}

            {/* 자격증 취득계획 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>자격증 취득계획</h5>
              </div>
              <Table>
                <colgroup>
                  <col width={200} />
                  <col width="*" />
                </colgroup>
                {/* <thead></thead> */}
                <tbody>
                  <tr>
                    <th>전공자격증</th>
                    <td className="text-start">
                      {certList
                        .filter(item => +item.certi_gubun === 1)
                        .map(item => item.certi_info)
                        .join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <th>정보화 자격증</th>
                    <td className="text-start">
                      {certList
                        .filter(item => +item.certi_gubun === 2)
                        .map(item => item.certi_info)
                        .join(', ')}
                    </td>
                  </tr>
                  <tr>
                    <th>외국어 능력</th>
                    <td className="text-start">
                      {certList
                        .filter(item => +item.certi_gubun === 3)
                        .map(item => item.certi_info)
                        .join(', ')}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </section>

            {/* 창의적 체험활동 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>창의적 체험활동</h5>
              </div>
              <div className="has-bt">
                {/* 동아리 */}
                <div className="sub-title type-dot">
                  <h5>동아리</h5>
                </div>
                <Table className="bt-none">
                  <colgroup>
                    <col width={400} />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="text-start">동아리명</th>
                      <th className="text-start">활동내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actList
                      .filter(item => +item.act_gubun === 1)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-start">{item.act_info}</td>
                            <td className="text-start">{item.act_detail}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {/* 봉사활동 */}
                <div className="sub-title type-dot">
                  <h5>봉사활동</h5>
                </div>
                <Table className="bt-none">
                  <colgroup>
                    <col width="*" />
                    <col width={200} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="text-start">봉사활동명</th>
                      <th>봉사시간</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actList
                      .filter(item => +item.act_gubun === 2)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-start">{item.act_info}</td>
                            <td className="text-start">{item.act_detail}</td>
                          </tr>
                        );
                      })}
                    {actList.filter(item => +item.act_gubun === 2).length >
                      0 && (
                      <tr>
                        <td className="text-start">
                          <strong>총 봉사시간</strong>
                        </td>
                        <td className="text-start">
                          <strong>
                            {actList
                              .filter(item => +item.act_gubun === 2)
                              .reduce((sum, el) => {
                                return sum + +el.act_detail;
                              }, 0)}
                          </strong>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* 독서계획 */}
                <div className="sub-title type-dot">
                  <h5>독서계획</h5>
                </div>
                <Table className="bt-none">
                  <colgroup>
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="text-start">도서명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actList
                      .filter(item => +item.act_gubun === 3)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-start">{item.act_info}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {/* 기타활동 */}
                <div className="sub-title type-dot">
                  <h5>기타활동</h5>
                </div>
                <Table className="bt-none">
                  <colgroup>
                    <col width={400} />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="text-start">활동명</th>
                      <th className="text-start">활동내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actList
                      .filter(item => +item.act_gubun === 4)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-start">{item.act_info}</td>
                            <td className="text-start">{item.act_detail}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </section>

            {/* 교사 코멘트 */}
            {commentList.length > 0 &&
              (careerForm.status === 'CON' || careerForm.status === 'REJ') && (
                <section className="mb-5">
                  <div className="sub-title flex-start">
                    <h5 className="text-primary">교사 코멘트</h5>
                  </div>
                  <div className="comment-box type-primary">
                    {commentList.map((comment, index) => {
                      return (
                        <ul className="comment-list" key={index}>
                          <li>
                            <p className="date">
                              {
                                new Date(comment.reg_date)
                                  .toISOString()
                                  .split('T')[0]
                              }
                            </p>
                            <p className="text">{comment.comment}</p>
                          </li>
                          <br />
                        </ul>
                      );
                    })}
                  </div>
                </section>
              )}

            {/* ////////// ↓ 하단 버튼 //////////  */}
            <div className="btn-area">
              {/* 승인 */}
              {careerForm.status === 'CON' && <>{customButton('print')}</>}
              {/* 승인요청 */}
              {careerForm.status === 'REQ' && (
                <>
                  {customButton('print')}
                  {customButton('cancel')}
                </>
              )}
              {/* 보완 */}
              {careerForm.status === 'REJ' && (
                <>
                  {customButton('print')}
                  {customButton('edit')}
                </>
              )}
              {/* 작성중 */}
              {careerForm.status === 'ING' && (
                <>
                  {customButton('print')}
                  {customButton('delete')}
                  {customButton('edit')}
                  {customButton('request')}
                </>
              )}
            </div>
          </article>
        </Container>
      ) : (
        firstCallDone && (
          // 작성된 글이 없을 때
          <Container>
            <article className="content">
              <ul className="path">
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="#none">진로설계</Link>
                </li>
              </ul>

              {/* ////////// ↓ 상단 타이틀영역 ////////// */}
              <section>
                <div className="title">
                  <h5 style={{ margin: '0' }}>
                    진로설계{' '}
                    <span
                      style={{
                        fontSize: 'var(--fs-14)',
                        color: 'blue',
                        fontWeight: 'bold',
                        marginLeft: '5px',
                      }}
                    >
                      (진로설계 입력은 전학년 공통사항이나 바우처 점수는 1학년만
                      해당됩니다.)
                    </span>
                  </h5>
                  <div className="btn-area m-0">
                    <Button
                      onClick={() =>
                        props.history.push(
                          '/student/indivAbility/careerDesign/write',
                        )
                      }
                      variant="primary"
                    >
                      신규작성
                    </Button>
                  </div>
                </div>
              </section>

              {/* ////////// ↓ 컨텐츠 ////////// */}

              <section className="no-data">
                작성한 학업계획서가 없습니다.
              </section>
            </article>
          </Container>
        )
      )}
    </main>
  );
});
