/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Form,
  Button,
  Card,
  Table,
  Tab,
  Nav,
} from 'react-bootstrap';

//* Components
import { CustomSelect, SearchGradeClass } from '@components';

//* Utils
import Utils from '@common/Utils';

//* CONSTS
import { NTH_LIST, FormStatus, TEACHER_ROLE_DETAIL } from '@common/consts';

//* API
import { selectStudentMgmtList } from '@api/teacher/studentMgmt';
import ReactPaginate from 'react-paginate';

// 학년별 탭 리스트
const initialGradeList = [1, 2, 3];

//* #################################################################
//* [ Main ]
//* #################################################################
export default React.memo(function StudentMgmt(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################

  // ------------------------------------------------------------
  // 히스토리
  // ------------------------------------------------------------
  const history = useHistory();
  const historyState = history.location.state;

  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  // ------------------------------------------------------------
  // 유저 타입 ( 학교 관리자 vs 참여교사 )
  // ------------------------------------------------------------
  const { userInfo } = props;

  let teacherType = null; // basic/director

  if (userInfo) {
    if (
      TEACHER_ROLE_DETAIL.SCHOOL_HEAD === userInfo.roleDetail ||
      TEACHER_ROLE_DETAIL.GRADE_HEAD === userInfo.roleDetail ||
      TEACHER_ROLE_DETAIL.PROGRAM === userInfo.roleDetail
    ) {
      teacherType = 'director';
    } else if (
      TEACHER_ROLE_DETAIL.CLASS === userInfo.roleDetail ||
      TEACHER_ROLE_DETAIL.SUB_CLASS === userInfo.roleDetail
    ) {
      teacherType = 'basic';
    }
  }

  // ------------------------------------------------------------
  // 최초 실행 Flag > 차수 드랍다운, 최신 차수, 학교 이름 갱신
  // ------------------------------------------------------------
  const [isInit, setIsInit] = useState(true);
  const [resetFlag, setResetFlag] = useState(false);

  // ------------------------------------------------------------
  // 차수
  // ------------------------------------------------------------
  // 드랍다운 List
  const [nthDropdown, setNthDropdown] = useState(NTH_LIST);

  // 현재 차수 Value
  const [currentNth, setCurrentNth] = useState(NTH_LIST[0].value);

  // 선택 차수 Option
  const [selectedNthOption, setSelectedNthOption] = useState(NTH_LIST[0]);

  // ------------------------------------------------------------
  // 학년별 TAB
  // ------------------------------------------------------------
  // TAB 학년 목록
  const [tabGrades, setTabGrades] = useState(initialGradeList);

  // 선택 학년 ( 학교 관리자, 참여교사 )
  const [selectedGrade, setSelectedGrade] = useState(
    teacherType === 'basic' ? userInfo.grade : 1,
  );

  // 선택 반 ( 참여교사 )
  const [selectedClass, setSelectedClass] = useState('');

  // ------------------------------------------------------------
  // 체크박스 선택 옵션
  // ------------------------------------------------------------
  // 학년 & 반 체크박스 옵션 리스트
  const [checkedOptions, setCheckedOptions] = useState([]);

  // ------------------------------------------------------------
  // 프로그램 리스트
  // ------------------------------------------------------------
  const [programs, setPrograms] = useState([]);

  // ------------------------------------------------------------
  // 학생 리스트
  // ------------------------------------------------------------
  const [students, setStudents] = useState([]);

  // ------------------------------------------------------------
  // 조회 학교
  // ------------------------------------------------------------
  // 학교 IDX
  const [schoolSeq, setSchoolSeq] = useState(0);

  // 학교 이름
  const [schoolName, setSchoolName] = useState('');

  // ------------------------------------------------------------
  // 검색어 (학생 이름)
  // ------------------------------------------------------------
  const [search, setSearch] = useState('');

  // ------------------------------------------------------------
  // 페이징
  // ------------------------------------------------------------
  // 현재 페이지
  const grade1Page = (historyState && historyState.grade1Page) || 1;
  const grade2Page = (historyState && historyState.grade2Page) || 1;
  const grade3Page = (historyState && historyState.grade3Page) || 1;

  let currentPage;

  switch (+selectedGrade) {
    case 1:
      currentPage = grade1Page;
      break;
    case 2:
      currentPage = grade2Page;
      break;
    case 3:
      currentPage = grade3Page;
      break;
    default:
      break;
  }

  // 총 페이지
  const [totalPages, setTotalPages] = useState(1);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // ------------------------------------------------------------
  // 페이지 이동 > 히스토리 저장
  // ------------------------------------------------------------
  const pushPageWithState = ({
    pageNum = 1,
    nth = selectedNthOption.value,
    grade = selectedGrade,
    tabList = tabGrades,
    nthOption = selectedNthOption,
    filterOptions = checkedOptions,
    searchKeyworkd = search,
  }) => {
    // 선택 학년
    const fixedGrade = grade !== undefined ? grade : selectedGrade;

    // 현재 차수
    const fixedNth = nth !== undefined ? nth : selectedNthOption.value;

    // 학년 Tab 목록
    const fixedTabGrades = tabList !== undefined ? tabList : tabGrades;

    // 차수 선택 Option
    const fixedNthOption =
      nthOption !== undefined ? nthOption : selectedNthOption;

    // 학년 & 반 체크박스
    const fixedOptions =
      filterOptions !== undefined ? filterOptions : checkedOptions;

    // 검색어
    const fixedSearch = searchKeyworkd !== undefined ? searchKeyworkd : search;

    // 히스토리 객체
    const state = {
      isInit,
      selectedNthOption: fixedNthOption,
      currentNth: fixedNth,
      tabGrades: fixedTabGrades,
      selectedGrade: fixedGrade,
      checkedOptions: fixedOptions,
      search: fixedSearch,
      grade1Page,
      grade2Page,
      grade3Page,
      pageRefresh: !pageRefresh,
    };

    // 학년별 페이지 정보 갱신 ( 학년 TAB 히스토리 관리 )
    switch (+fixedGrade) {
      case 1:
        state.grade1Page = pageNum;
        break;
      case 2:
        state.grade2Page = pageNum;
        break;
      case 3:
        state.grade3Page = pageNum;
        break;
      default:
        break;
    }

    // history > push
    history.push({
      pathname: `/teacher/studentMgmt/studentMgmt`,
      state,
    });
  };

  // ------------------------------------------------------------
  // 차수 선택 > API
  // ------------------------------------------------------------
  const nthHandler = selectedOption => {
    // setSelectedNthOption(selectedOption);

    // 선택된 차수로 리스트 갱신
    pushPageWithState({
      nth: selectedOption.value,
      nthOption: selectedOption,
    });
  };

  // ------------------------------------------------------------
  // TAB 이벤트 > API
  // ------------------------------------------------------------
  const tabHandler = grade => {
    // 선택된 학년으로 리스트 갱신
    let changePageNum = 1;

    switch (+grade) {
      case 1:
        changePageNum = grade1Page;
        break;
      case 2:
        changePageNum = grade2Page;
        break;
      case 3:
        changePageNum = grade3Page;
        break;
      default:
        break;
    }

    pushPageWithState({
      pageNum: changePageNum,
      grade,
    });
  };

  // ------------------------------------------------------------
  // 검색 버튼 > API
  // ------------------------------------------------------------
  const searchHandler = () => {
    let defaultGrade = 1;
    let filteredGradeList = [...tabGrades];

    // 학년 탭 갱신
    //  > 체크박스 입력 O
    if (checkedOptions.length > 0) {
      // 체크박스 선택 학년 리스트
      const optionGradeList = checkedOptions.map(item => item.grade);

      // TAB 학년 리스트 정렬 ( 배열 합 > 중복제거 )
      filteredGradeList = initialGradeList.filter(item =>
        optionGradeList.includes(item),
      );

      // TAB 갱신
      // setTabGrades(filteredGradeList);

      // 선택 학년 ( Default )
      defaultGrade = +filteredGradeList[0];
    }

    // 선택된 검색어, 필터 옵션으로 리스트 갱신
    pushPageWithState({
      grade: defaultGrade,
      tabList: filteredGradeList,
    });
  };

  //* #################################################################
  //* [ API ] 학년별 점수, 바우처 정보 가져오기
  //* #################################################################
  const getStudentList = async (
    pageNum,
    nth,
    grade,
    keyword,
    newCheckedOptions,
  ) => {
    // 필터 옵션 전처리
    let checkedClassList = [];

    const newGrade =
      teacherType === 'basic'
        ? userInfo.grade
        : grade !== undefined
        ? grade
        : selectedGrade;

    // 체크박스 옵션 있을 경우 ( 학년 > 반 )
    if (newCheckedOptions && newCheckedOptions.length > 0) {
      const gradeList = historyState.checkedOptions.filter(
        item => +item.grade === +newGrade,
      );

      // Key 검증 ( classess )
      if (gradeList.length > 0 && gradeList[0].classes !== undefined) {
        checkedClassList = gradeList[0].classes;
      }
    }

    // JSON 파라미터
    const param = {
      selectedNth: nth !== undefined ? nth : selectedNthOption.value, // 조회 차수
      selectedGrade: newGrade, // 선택 학년
      checkedOptions: checkedClassList, // 선택 반 (리스트)
      search: keyword !== undefined ? keyword : search, // 학생이름
      page: pageNum,
      size: 10,
    };

    // Axios
    try {
      const { data } = await selectStudentMgmtList(param);

      if (data.code === 0) {
        const {
          programList,
          studentList,
          nthList,
          schoolName: school,
          schoolIdx,
          pageResponse,
          classTeacherBanNo,
        } = data.data;

        // 최초 실행
        if (isInit) {
          // 차수 정보 ( 차수 리스트, 선택 차수 )
          const joinedNthList = nthList.map(item => {
            return {
              label: `${item}차`,
              value: `${item}`,
            };
          });

          setSelectedNthOption(joinedNthList[0]); // 최신 차수
          setCurrentNth(joinedNthList[0].value);

          // 드랍다운
          setNthDropdown(joinedNthList);

          // 최초 실행 분기
          setIsInit(false);
        }

        // (참여교사) 학급 지정
        setSelectedClass(classTeacherBanNo);

        // 프로그램 리스트 갱신
        setPrograms(programList);

        // 학교 정보 갱신
        setSchoolSeq(schoolIdx);
        setSchoolName(school);

        // 학생 리스트 갱신 && 페이징 갱신 ( 참여교사 예외 )
        if (
          teacherType === 'basic' ||
          (newCheckedOptions && newCheckedOptions.length > 0)
        ) {
          setStudents(studentList);
          setTotalPages(pageResponse.totalPages);
        } else {
          setStudents([]);
          setTotalPages(0);
        }

        // 담임 & 부참여교사 선택 학년 (TAB)
        if (
          TEACHER_ROLE_DETAIL.CLASS === userInfo.roleDetail ||
          TEACHER_ROLE_DETAIL.SUB_CLASS === userInfo.roleDetail
        ) {
          setSelectedGrade(userInfo.grade);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  // 학생 리스트 가져오기 ( 검색, 탭선택, 페이지 변경, 차수 변경 )
  useEffect(() => {
    // 유저 정보 조회
    if (userInfo) {
      // 히스토리 확인
      if (historyState) {
        setCurrentNth(historyState.selectedNthOption.value);
        setSelectedNthOption(historyState.selectedNthOption);
        setSelectedGrade(
          teacherType === 'basic'
            ? userInfo.grade
            : historyState.selectedGrade || 1,
        );
        setTabGrades(historyState.tabGrades);
        setCheckedOptions(historyState.checkedOptions);
        setSearch(historyState.search);
        setResetFlag(!resetFlag);

        let changePageNum = 1;

        switch (+historyState.selectedGrade) {
          case 1:
            changePageNum = historyState.grade1Page;
            break;
          case 2:
            changePageNum = historyState.grade2Page;
            break;
          case 3:
            changePageNum = historyState.grade3Page;
            break;
          default:
            break;
        }

        getStudentList(
          changePageNum,
          historyState.currentNth,
          historyState.selectedGrade,
          historyState.search,
          historyState.checkedOptions,
        );
      }
      // 최초 접근
      else {
        getStudentList(1);
      }
    }
    return () => {
      // setSelectedGrade(userInfo.grade);
    };
  }, [pageRefresh, userInfo]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="teacher-studentMgmt" className="type-02">
      <Container>
        <article className="content">
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>학생관리</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          {programs.length > 0 && (
            <Card>
              <Card.Header>
                <div className="flex-start">
                  {/* 학년/반 검색 */}
                  {teacherType === 'director' && (
                    <SearchGradeClass
                      schoolidx={schoolSeq}
                      setCheckedGradeList={setCheckedOptions}
                      checkedGradeList={checkedOptions}
                      reset={resetFlag}
                      resetData={historyState && historyState.checkedOptions}
                    />
                  )}
                  {/* 학생이름 검색 */}
                  <div className="input-has-btn ms-2">
                    {/* 검색어 입력 */}
                    <Form.Control
                      type="text"
                      placeholder="학생 이름을 검색하세요."
                      className="input-search"
                      maxLength={20}
                      onChange={e => setSearch(e.target.value.trim())}
                      value={search}
                    />
                    {/* 검색 버튼 */}
                    <Button size="sm" variant="primary" onClick={searchHandler}>
                      검색
                    </Button>
                  </div>
                </div>
                <div className="table-title mt-4">
                  <div>
                    <h6 className="mb-1">{currentNth}차 진행중</h6>
                    <h5>{schoolName}</h5>
                  </div>
                  {/* 차수 선택 드랍다운 */}
                  <div className="sw-input">
                    <CustomSelect
                      options={nthDropdown}
                      value={selectedNthOption}
                      onChange={nthHandler}
                      placeholder="차수선택"
                    />
                  </div>
                </div>
              </Card.Header>
              {/* TAP 컨테이너 */}
              <Card.Body className="pt-0">
                <Tab.Container
                  // defaultActiveKey="1"
                  activeKey={selectedGrade}
                  id="grade-tab"
                >
                  {/* TAB 헤더 */}
                  <Nav className="text-tabs">
                    <Nav.Item>
                      {/* 
                      TAP 항목 
                        1. 학교 관리자 - 1, 2, 3 학년
                        2. 담입교사 - 학년 + 반
                     */}
                      {teacherType === 'director' ? (
                        tabGrades.map((grade, index) => {
                          return (
                            <Nav.Link
                              key={index}
                              eventKey={grade}
                              onClick={() => tabHandler(grade)}
                            >
                              {grade}학년
                            </Nav.Link>
                          );
                        })
                      ) : (
                        <div className="table-sub-title">
                          <h5>
                            {selectedGrade}학년 {+selectedClass}반
                          </h5>
                        </div>
                      )}
                    </Nav.Item>
                  </Nav>
                  {/* TAB 컨텐츠 */}
                  <Tab.Content>
                    {/* ↓ 학년별 탭 */}
                    {teacherType === 'director' ? (
                      tabGrades.map((grade, index) => {
                        return (
                          <Tab.Pane key={index} eventKey={grade}>
                            <Table className="bt-none type-narrow tr-even-bg">
                              <colgroup>
                                <col width={62} />
                                <col width={62} />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width={92} />
                                {/* <col width={92} /> */}
                                <col width={114} />
                              </colgroup>
                              {/* 테이블 헤더 */}
                              <thead>
                                <tr>
                                  {/* 공통 컬럼 (반, 번호, 이름) */}
                                  <th>반</th>
                                  <th>번호</th>
                                  <th className="text-center">이름</th>
                                  {/* 학년별 컬럼 (프로그램) */}
                                  {programs.map((program, idx) => {
                                    return (
                                      <th key={idx}>{program.program_name}</th>
                                    );
                                  })}
                                  {/* 바우처 대상점수 */}
                                  <th>
                                    바우처
                                    <br />
                                    대상점수
                                  </th>
                                  {/* 누계점수 */}
                                  {/* <th> */}
                                  {/*  누계 */}
                                  {/*  <br /> */}
                                  {/*  점수 */}
                                  {/* </th> */}
                                  {/* 바우처 */}
                                  <th className="text-end">바우처</th>
                                </tr>
                              </thead>
                              {/* 테이블 컨텐츠 */}
                              <tbody>
                                {students && students.length > 0 ? (
                                  students.map((student, idx) => {
                                    return (
                                      <tr key={idx}>
                                        {/* 반 */}
                                        <td>{student.ban_no}</td>

                                        {/* 번호 */}
                                        <td>{student.student_no}</td>

                                        {/* 이름 */}
                                        <td className="text-center">
                                          {Utils.decrypt(student.student_name)}
                                        </td>

                                        {/* 학년별 프로그램 */}
                                        {programs.map((program, i) => {
                                          // 상태 변수
                                          const status =
                                            student[
                                              Utils.convertProgramData(
                                                program.program_id,
                                              ).status
                                            ];

                                          // 점수 변수
                                          const point =
                                            student[
                                              Utils.convertProgramData(
                                                program.program_id,
                                              ).point
                                            ];

                                          // return
                                          return (
                                            <td
                                              key={i}
                                              className="go-detail"
                                              aria-hidden="true"
                                              onClick={() =>
                                                props.history.push(
                                                  Utils.convertProgramData(
                                                    program.program_id,
                                                    student.grade,
                                                    student.seq,
                                                  ).url,
                                                )
                                              }
                                            >
                                              {/* 상태 */}
                                              <strong
                                                className={
                                                  FormStatus[status].class
                                                }
                                              >
                                                {FormStatus[status].text}
                                              </strong>
                                              <br />

                                              {/* 점수 */}
                                              {status === 'CON' && (
                                                <small>점수: {point}</small>
                                              )}
                                            </td>
                                          );
                                        })}

                                        {/* 바우처 대상점수 */}
                                        <td>{student.point_sum}</td>

                                        {/* 누계 점수 */}
                                        {/* <td>{student.total_point}</td> */}

                                        {/* 바우처 */}
                                        <td className="text-end">
                                          {Utils.numberComma(
                                            student.voucher_sum,
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={programs.length + 5}
                                      className="no-data"
                                    >
                                      조회된 학생이 없습니다.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                            {/* 페이징 */}
                            <ReactPaginate
                              pageRangeDisplayed={3}
                              nextLabel=""
                              previousLabel=""
                              breakLabel="..."
                              renderOnZeroPageCount={null}
                              containerClassName="pagination mt-4"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              activeClassName="active"
                              // 총 페이지
                              pageCount={totalPages}
                              // 페이지 이벤트
                              onPageChange={e => {
                                pushPageWithState({ pageNum: e.selected + 1 });
                              }}
                              // 부모 Prop에 현재 패이지 오버라이드
                              forcePage={currentPage - 1}
                            />
                          </Tab.Pane>
                        );
                      })
                    ) : (
                      <Tab.Pane eventKey={selectedGrade}>
                        <Table className="bt-none type-narrow tr-even-bg">
                          <colgroup>
                            <col width={62} />
                            <col width={62} />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width={92} />
                            {/* <col width={92} /> */}
                            <col width={114} />
                          </colgroup>
                          {/* 테이블 헤더 */}
                          <thead>
                            <tr>
                              {/* 공통 컬럼 (반, 번호, 이름) */}
                              <th>반</th>
                              <th>번호</th>
                              <th className="text-center">이름</th>
                              {/* 학년별 컬럼 (프로그램) */}
                              {programs.map((program, idx) => {
                                return (
                                  <th
                                    key={idx}
                                    style={{ wordBreak: 'keep-all' }}
                                  >
                                    {program.program_name}
                                  </th>
                                );
                              })}
                              {/* 바우처 대상점수 */}
                              <th>
                                바우처
                                <br />
                                대상점수
                              </th>
                              {/* 누계점수 */}
                              {/* <th> */}
                              {/*  누계 */}
                              {/*  <br /> */}
                              {/*  점수 */}
                              {/* </th> */}
                              {/* 바우처 */}
                              <th className="text-end">바우처</th>
                            </tr>
                          </thead>
                          {/* 테이블 컨텐츠 */}
                          <tbody>
                            {students.length > 0 ? (
                              students.map((student, idx) => {
                                return (
                                  <tr key={idx}>
                                    {/* 반 */}
                                    <td>{student.ban_no}</td>

                                    {/* 번호 */}
                                    <td>{student.student_no}</td>

                                    {/* 이름 */}
                                    <td className="text-center">
                                      {Utils.decrypt(student.student_name)}
                                    </td>

                                    {/* 학년별 프로그램 */}
                                    {programs.map((program, i) => {
                                      // 상태 변수
                                      const status =
                                        student[
                                          Utils.convertProgramData(
                                            program.program_id,
                                          ).status
                                        ];

                                      // 점수 변수
                                      const point =
                                        student[
                                          Utils.convertProgramData(
                                            program.program_id,
                                          ).point
                                        ];

                                      // return
                                      return (
                                        <td
                                          key={i}
                                          className="go-detail"
                                          aria-hidden="true"
                                          onClick={() =>
                                            props.history.push(
                                              Utils.convertProgramData(
                                                program.program_id,
                                                student.grade,
                                                student.seq,
                                              ).url,
                                            )
                                          }
                                        >
                                          {/* 상태 */}
                                          <strong
                                            className={FormStatus[status].class}
                                          >
                                            {FormStatus[status].text}
                                          </strong>
                                          <br />

                                          {/* 점수 */}
                                          {status === 'CON' && (
                                            <small>점수: {point}</small>
                                          )}
                                        </td>
                                      );
                                    })}

                                    {/* 바우처 대상점수 */}
                                    <td>{student.point_sum}</td>

                                    {/* 누계 점수 */}
                                    {/* <td>{student.total_point}</td> */}

                                    {/* 바우처 */}
                                    <td className="text-end">
                                      {Utils.numberComma(student.voucher_sum)}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={programs.length + 5}
                                  className="no-data"
                                >
                                  조회된 학생이 없습니다.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          )}
        </article>
      </Container>
    </main>
  );
});
