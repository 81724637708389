/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

//* API
import { getMatchingCompany } from '@api/teacher/matchingInfo';

import Utils from '@common/Utils';

// IP Input 초기값
const initialCompanyInfo = {
  seq: null, // SEQ
  value: '0', // 점수
  grade: '0', // 등급
  gubunHan: '', // 구분(참여기업, 선도기업)
  name: '', // 업체명
  number: '', // 사업자번호
  addr: '', // 주소
  addrDetail: '', // 상세주소
  applyGubunHan: '', // 취업형태(대기업, 중소기업)
  applyField: '', // 취업업종
  applyJob: '', // 취업직무
  jobStandard: '', // 표준직업분류
  schoolYn: 'N', // 산학일체형 도제학교(Y, N)
  ausYn: 'N', // 아우스 빌둥 참여(Y, N)
  applyStatus: '', // 취업상태
  applyDate: new Date(), // 취업일
  paymentHan: '', // 연봉
  suitYn: 'N', // 전공적합여부
  internCnt: '', // 현장실습건수
  directYn: 'N', // 실습 후 동일기업 취업여부(Y, N)
  stockYn: 'N', // 상장여부
  workerCnt: '', // 종업원수(3년)
  outcome: '', // 매출액(3년)
  paymentAvg: '', // 임시 1(평균연봉)
  legalAddr: '', // 소재지
  legalAddrDetail: '', // 소재지 상세주소
};

//* [ Main ]
export default React.memo(function AdmEduSystemCompWrite(props) {
  const { seq = 0 } = useParams();

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 기업 정보
  const [companyInfo, setCompanyInfo] = useState(initialCompanyInfo);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################

  //* #################################################################
  //* [ API ] 기업정보 가져오기 (수정 Only)
  //* #################################################################
  const getCompanyInfo = async () => {
    // Axios
    try {
      const { data } = await getMatchingCompany(seq);
      if (data.code === 0) {
        const info = data.data;

        // 기업정보 갱신
        setCompanyInfo({
          seq: info.seq,
          value: info.value,
          grade: info.grade,
          gubunHan: info.gubunHan,
          name: info.name,
          number: info.number,
          applyGubunHan: info.applyGubunHan,
          applyField: info.applyField,
          applyJob: info.applyJob,
          jobStandard: info.jobStandard,
          schoolYn: info.schoolYn,
          ausYn: info.ausYn,
          applyStatus: info.applyStatus,
          applyDate: new Date(info.applyDate),
          paymentHan: info.paymentHan,
          suitYn: info.suitYn,
          internCnt: info.internCnt,
          directYn: info.directYn,
          stockYn: info.stockYn,
          workerCnt: info.workerCnt,
          outcome: info.outcome,
          paymentAvg: info.paymentAvg,
          addr: info.addr.replace('/', ''),
          legalAddr: info.legalAddr.replace('/', ''),
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    // 상세 > 기업정보 가져오기
    if (seq > 0) {
      getCompanyInfo();
    } else {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      }).then(result => {
        if (result.isConfirmed) {
          props.history.goBack();
        }
      });
    }
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="admEdu-system-comp" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>기업정보</h5>
            </div>
          </section>

          <Card>
            <Card.Body>
              {/* -------------------- 기업평가 -------------------- */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table type-simple">
                  {/* 점수 */}
                  <Row className="table-row">
                    <Col className="th col-2">점수</Col>
                    <Col className="td col-10">{companyInfo.value}</Col>
                  </Row>

                  {/* 등급 */}
                  <Row className="table-row">
                    <Col className="th col-2">등급</Col>
                    <Col className="td col-10">{companyInfo.value}</Col>
                  </Row>
                </div>
              </section>

              {/* -------------------- 기본정보 -------------------- */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table type-simple">
                  {/* 구분 & 취업형태 */}
                  <Row className="table-row">
                    {/* 구분 */}
                    <Col className="th col-2">구분</Col>
                    <Col className="td col-4">{companyInfo.gubunHan}</Col>

                    {/* 취업형태 */}
                    <Col className="th col-2">취업형태</Col>
                    <Col className="td col-4">{companyInfo.applyGubunHan}</Col>
                  </Row>

                  {/* 업체명 */}
                  <Row className="table-row">
                    <Col className="th col-2">업체명</Col>
                    <Col className="td col-10">{companyInfo.name}</Col>
                  </Row>

                  {/* 주소 */}
                  <Row className="table-row">
                    <Col className="th col-2">주소</Col>
                    <Col className="td col-10">{companyInfo.addr}</Col>
                  </Row>

                  {/* 사업자번호 */}
                  <Row className="table-row">
                    <Col className="th col-2">사업자번호</Col>
                    <Col className="td col-10">{companyInfo.number}</Col>
                  </Row>
                </div>
              </section>

              {/* -------------------- 취업정보 -------------------- */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>취업정보</h5>
                </div>
                <div className="split-table type-simple">
                  {/* 취업일 */}
                  <Row className="table-row">
                    <Col className="th col-2">취업일</Col>
                    <Col className="td col-10">
                      {Utils.dateToString(companyInfo.applyDate)}
                    </Col>
                  </Row>

                  {/* 연봉 */}
                  <Row className="table-row">
                    <Col className="th col-2">연봉</Col>
                    <Col className="td col-10">{companyInfo.paymentHan}</Col>
                  </Row>

                  {/* 업종 */}
                  <Row className="table-row">
                    <Col className="th col-2">업종</Col>
                    <Col className="td col-10">{companyInfo.applyField}</Col>
                  </Row>

                  {/* 직무 */}
                  <Row className="table-row">
                    <Col className="th col-2">직무</Col>
                    <Col className="td col-10">{companyInfo.applyJob}</Col>
                  </Row>

                  {/* 취업상태 */}
                  <Row className="table-row">
                    <Col className="th col-2">취업상태</Col>
                    <Col className="td col-10">{companyInfo.applyStatus}</Col>
                  </Row>

                  {/* 표준직업분류 */}
                  <Row className="table-row">
                    <Col className="th col-2">표준직업분류</Col>
                    <Col className="td col-10">{companyInfo.jobStandard}</Col>
                  </Row>

                  {/* 산학일체형 도제학교 */}
                  <Row className="table-row">
                    <Col className="th col-2">산학일체형 도제학교</Col>
                    <Col className="td col-10">{companyInfo.schoolYn}</Col>
                  </Row>

                  {/* 아우스 빌둥 참여 */}
                  <Row className="table-row">
                    <Col className="th col-2">아우스 빌둥 참여</Col>
                    <Col className="td col-10">{companyInfo.ausYn}</Col>
                  </Row>

                  {/* 현장실습건수 */}
                  <Row className="table-row">
                    <Col className="th col-2">현장실습건수</Col>
                    <Col className="td col-10">
                      {' '}
                      {companyInfo.internCnt
                        ? `${companyInfo.internCnt} 건`
                        : ''}
                    </Col>
                  </Row>

                  {/* 전공적합여부 */}
                  <Row className="table-row">
                    <Col className="th col-2">전공적합여부</Col>
                    <Col className="td col-10">{companyInfo.suitYn}</Col>
                  </Row>

                  {/* 실습 후 동일기업 취업여부 */}
                  <Row className="table-row">
                    <Col className="th col-2">실습 후 동일기업 취업여부</Col>
                    <Col className="td col-10">{companyInfo.directYn}</Col>
                  </Row>

                  {/* 상장여부 */}
                  <Row className="table-row">
                    <Col className="th col-2">상장여부</Col>
                    <Col className="td col-10">{companyInfo.stockYn}</Col>
                  </Row>

                  {/* 종업원수(3년) */}
                  <Row className="table-row">
                    <Col className="th col-2">종업원수(3년)</Col>
                    <Col className="td col-10">
                      {companyInfo.workerCnt
                        ? `${companyInfo.workerCnt} 명`
                        : ''}
                    </Col>
                  </Row>

                  {/* 매출액(3년) */}
                  <Row className="table-row">
                    <Col className="th col-2">매출액(3년)</Col>
                    <Col className="td col-10">
                      {companyInfo.outcome ? `${companyInfo.outcome} 억` : ''}
                    </Col>
                  </Row>

                  {/* 평균연봉 */}
                  <Row className="table-row">
                    <Col className="th col-2">평균연봉</Col>
                    <Col className="td col-10">
                      {companyInfo.paymentAvg
                        ? `${companyInfo.paymentAvg} 만원`
                        : ''}
                    </Col>
                  </Row>

                  {/* 소재지 */}
                  <Row className="table-row">
                    <Col className="th col-2">소재지</Col>
                    <Col className="td col-10">{companyInfo.legalAddr}</Col>
                  </Row>
                </div>
              </section>

              {/* -------------------- 버튼 -------------------- */}
              <div className="btn-area mb-0">
                {/* 수정 */}
                <Button
                  size="lg"
                  variant="outline- primary"
                  onClick={e =>
                    props.history.push(`/teacher/matchingInfo/comp`)
                  }
                >
                  목록
                </Button>
              </div>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});
