import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfPreview({
    fileUrl,
    loadHandler,
    pageNum,
    totalPages,
    isPageDisabled = true,
}) {
    return (
        <div className='m-lg-auto text-center'>
            <Document file={fileUrl} onLoadSuccess={loadHandler} loading="이미지 로딩중입니다." error="이미지 로딩중입니다.">
                <Page pageNumber={pageNum} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
            <p className={isPageDisabled ? '' : 'd-none'}>
                페이지 {pageNum} / {totalPages}
            </p>
        </div>
    )
};
