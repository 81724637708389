/**
 *   [ 취업역량강화 API ]
 *
 */
import Axios from '../axios';

const API_TEACHER = 'api/v1/teacher';

const WORK_LIST = `${API_TEACHER}/work-list`;
const WORK = `${API_TEACHER}/work`;
const WORK_INFO = `${API_TEACHER}/work-info`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 취업역량강화 > '취업마인드 함양, 취업 마인드 강화, 취업 역량 강화' 결과 보고서 리스트 조회
export const selectFormEmployAbilityAllbyTeacher = param => {
  return Axios.post(`${WORK_LIST}`, param);
};

// 취업역량강화 > '취업마인드 함양, 취업 마인드 강화, 취업 역량 강화' 결과 보고서 정보 조회 // 리서치 목록 포함
export const selectFormEmployAbilityInfobyTeacher = param => {
  return Axios.post(`${WORK_INFO}`, param);
};

// 취업역량강화 > '취업 마인드 함양,강화 ,역량강화 임시저장, 승인요청, 승인요청 취소, 삭제'
export const upsertFormEmployAbilitybyTeacher = param => {
  return Axios.post(`${WORK}`, param);
};
