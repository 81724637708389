/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Table,
  Tab,
  Nav,
} from 'react-bootstrap';
import { CustomSelect, SearchSchool, SearchGradeClass } from '@components';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import LoadingSpinner from '@components/LoadingSpinner';
import { FormStatus } from '@common/consts';
import Utils from '@common/Utils';
import { format } from 'date-fns';
import { getNthList, getProgramList } from '@api/common/common';
import {
  downloadStudentProgram,
  getStudentProgram,
} from '@api/eduadmin/student';

export default React.memo(function AdmEduProgramStatus(props) {
  const history = useHistory();

  const refresh =
    (props.history.location.state && props.history.location.state.refresh) ||
    'refresh';

  const [firstCallDone, setFirstCallDone] = useState(true);

  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const studentName =
    (history.location.state && history.location.state.studentName) || '';

  const school =
    (history.location.state && history.location.state.school) || {};

  const gradeList =
    (history.location.state && history.location.state.gradeList) || [];

  const grade = (history.location.state && history.location.state.grade) || [];

  const banList =
    (history.location.state && history.location.state.banList) || [];

  const gradeBanList =
    (history.location.state && history.location.state.gradeBanList) || [];

  const nth = (history.location.state && history.location.state.nth) || {};

  const pages = (history.location.state && history.location.state.pages) || {
    1: 1,
    2: 1,
    3: 1,
  };

  const [totalPages, setTotalPages] = useState();

  const [searchedStudentName, setSearchedStudentName] = useState(studentName);
  // const [searchedSchool, setSearchedSchool] = useState(school);
  // const [searchedBanList, setSearchedBanList] = useState(banList);
  // const [searchedGrade, setSearchedGrade] = useState(grade);

  const [selectedSchool, setSelectedSchool] = useState(school);
  const [selectedGradeList, setSelectedGradeList] = useState(gradeList);
  const [selectedBanList, setSelectedBanList] = useState(gradeBanList);

  const [studentList, setStudentList] = useState([]);

  const [checkedGradeList, setCheckedGradeList] = useState(gradeBanList);

  const [selectedNth, setSelectedNth] = useState(nth || null);
  const [currentNth, setCurrentNth] = useState({});
  const [nthList, setNthList] = useState([]);

  // 프로그램 리스트
  const [programs, setPrograms] = useState([]);

  const getStudentList = async () => {
    const params = {
      page: pages[`${grade}`],
      size: 10,
      schoolIdx: school.value === 'all' ? null : school.value,
      grade,
      nth: nth.value,
      banNoList: banList,
      studentName: searchedStudentName,
    };

    const { data } = await getStudentProgram(params);
    if (data.code === 0) {
      setTotalPages(data.data.totalPages);
      setStudentList(data.data.list);
    }
  };

  const onSearch = () => {
    if (!checkedGradeList.length > 0) {
      return;
    }
    const tempGradeList = checkedGradeList.map((v, i) => {
      return v.grade;
    });

    setSelectedGradeList(tempGradeList);
    setSelectedBanList(checkedGradeList);
    setIsSearch(true);
  };

  const movePage = (pageNum, isSearch, newGrade, newNth) => {
    const tempPages = pages;
    tempPages[`${grade}`] = pageNum;

    const initPages = {
      1: 1,
      2: 1,
      3: 1,
    };

    const state = {
      pages: isSearch ? initPages : tempPages,
      grade: newGrade || (isSearch ? selectedGradeList.sort()[0] : grade),
      nth: newNth || (isSearch ? selectedNth : nth),
      studentName: isSearch ? searchedStudentName : studentName,
      school: isSearch ? selectedSchool : school,
      gradeList: isSearch ? selectedGradeList : gradeList,
      banList: newGrade
        ? gradeBanList.find(v => v.grade === newGrade).classes
        : isSearch
        ? checkedGradeList.find(v => v.grade === selectedGradeList.sort()[0])
            .classes
        : banList,
      gradeBanList: isSearch ? selectedBanList : gradeBanList,
      pageRefresh: !pageRefresh,
    };

    history.push({
      pathname: `/admEdu/student/program`,
      state,
    });
  };

  const settingState = () => {
    // setSearchedBanList(banList);
    setSelectedNth(nth);
    setSelectedSchool(school);
    // setSearchedSchool(school);
    // setSearchedGrade(grade);
    setCheckedGradeList([...gradeBanList]);
    setSearchedStudentName(studentName);
    setIsSearch(false);
    setReset(!reset);
  };

  const [isSearch, setIsSearch] = useState(false);
  useEffect(() => {
    if (isSearch) {
      movePage(1, true);
    }
  }, [isSearch]);

  useEffect(() => {
    if (school.value) {
      settingState();
      getStudentList();
    }
  }, [pageRefresh]);

  useEffect(() => {
    getNths();
    getPrograms();
    settingState();
  }, [refresh]);

  const getNths = async () => {
    const { data } = await getNthList();
    if (data.code === 0) {
      const tempList = data.data.nthList.map((v, i) => {
        const tempData = {
          value: v,
          label: `${v}차`,
        };

        return tempData;
      });
      const newCurrentNth = tempList.find(
        v => v.value === data.data.currentNth,
      );
      setCurrentNth(newCurrentNth);
      setSelectedNth(newCurrentNth);
      setNthList(tempList);
    }
  };

  const getPrograms = async () => {
    const { data } = await getProgramList();
    if (data.code === 0) {
      setPrograms(data.data);
    }
  };

  const [reset, setReset] = useState(false);

  const onDownload = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const params = {
        nth: nth.value,
        schoolIdx: school.value === 'all' ? null : school.value,
      };
      setShowSpinner(true);
      const { data } = await downloadStudentProgram(params);
      const blob = new Blob([data]);
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      const fileName = format(new Date(), 'yyyyMMdd ').toString();
      link.download = `${fileName}.xlsx`;
      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      target.disabled = false;
      setShowSpinner(false);
    }
    target.disabled = false;
    setShowSpinner(false);
  };

  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <main id="admEdu-student-program" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>프로그램 현황</h5>
            </div>
          </section>
          {showSpinner ? (
            <LoadingSpinner show={showSpinner} message="다운로드 중입니다." />
          ) : (
            <Card>
              <Card.Header>
                <div className="flex-start align-items-stretch">
                  <SearchSchool
                    selectedSchool={selectedSchool}
                    setSelectedSchool={item => {
                      setSelectedSchool(item);
                      setCheckedGradeList([]);
                    }}
                  />
                  <SearchGradeClass
                    className="ms-2"
                    reset={reset}
                    resetData={gradeBanList}
                    schoolidx={selectedSchool.value}
                    setCheckedGradeList={setCheckedGradeList}
                    checkedGradeList={checkedGradeList}
                  />
                  <div className="input-has-btn lg ms-2">
                    <Form.Control
                      type="text"
                      placeholder="학생 이름을 검색하세요."
                      className="input-search"
                      value={searchedStudentName}
                      onChange={e => {
                        setSearchedStudentName(e.target.value);
                      }}
                    />
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => {
                        onSearch();
                      }}
                    >
                      검색
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pt-0">
                {school.value ? (
                  <>
                    <div className="flex-between mt-4">
                      <div>
                        <h6 className="text-dark mb-1">
                          {currentNth.value}차 진행중
                        </h6>
                        <h5>{school.label}</h5>
                      </div>
                      <div className="btn-area m-0">
                        <Button
                          variant="outline-primary"
                          onClick={e => {
                            onDownload(e);
                          }}
                        >
                          다운로드
                        </Button>
                        <div className="sw-input ms-2">
                          <CustomSelect
                            onChange={e => {
                              setReset(!reset);
                              movePage(1, false, false, e);
                            }}
                            options={nthList}
                            value={selectedNth}
                          />
                        </div>
                      </div>
                    </div>
                    <Tab.Container defaultActiveKey="freshman" id="grade-tab">
                      <Nav className="text-tabs">
                        <Nav.Item>
                          {selectedGradeList.map((v, i) => {
                            return (
                              <Nav.Link
                                key={`a_grades_${v}`}
                                eventKey={`grades_${v}`}
                                active={v === grade}
                                onClick={() => {
                                  movePage(pages[`${grade}`], false, v);
                                }}
                              >
                                {v}학년
                              </Nav.Link>
                            );
                          })}
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        {/* ↓ 1학년 탭 */}
                        {grade && (
                          <>
                            <Table className="bt-none type-narrow tr-even-bg">
                              <colgroup>
                                <col width={62} />
                                <col width={62} />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width={92} />
                                {/* <col width={92} /> */}
                                <col width={114} />
                              </colgroup>
                              <thead>
                                <tr>
                                  {/* 공통 컬럼 (반, 번호, 이름) */}
                                  <th>반</th>
                                  <th>번호</th>
                                  <th className="text-center">이름</th>
                                  {/* 학년별 컬럼 (프로그램) */}
                                  {programs
                                    .filter(v =>
                                      String(v.program_grade).includes(grade),
                                    )
                                    .map((program, idx) => {
                                      return (
                                        <th key={idx}>
                                          {program.program_name}
                                        </th>
                                      );
                                    })}
                                  {/* 바우처 대상점수 */}
                                  <th>
                                    바우처
                                    <br />
                                    대상점수
                                  </th>
                                  {/* 누계점수 */}
                                  {/* <th> */}
                                  {/*  누계 */}
                                  {/*  <br /> */}
                                  {/*  점수 */}
                                  {/* </th> */}
                                  {/* 바우처 */}
                                  <th className="text-end">바우처</th>
                                </tr>
                              </thead>
                              <tbody>
                                {studentList.map((student, idx) => {
                                  return (
                                    <tr key={idx}>
                                      {/* 반 */}
                                      <td>{student.ban_no}</td>

                                      {/* 번호 */}
                                      <td>{student.student_no}</td>

                                      {/* 이름 */}
                                      <td className="text-center">
                                        {Utils.decrypt(student.student_name)}
                                      </td>

                                      {/* 학년별 프로그램 */}
                                      {programs
                                        .filter(v =>
                                          String(v.program_grade).includes(
                                            grade,
                                          ),
                                        )
                                        .map((program, i) => {
                                          // 상태 변수
                                          const status =
                                            student[
                                              Utils.convertProgramData(
                                                program.program_id,
                                              ).status
                                            ];

                                          // 점수 변수
                                          const point =
                                            student[
                                              Utils.convertProgramData(
                                                program.program_id,
                                              ).point
                                            ];

                                          // return
                                          return (
                                            <td
                                              key={i}
                                              className="go-detail"
                                              aria-hidden="true"
                                              onClick={() =>
                                                props.history.push(
                                                  Utils.convertProgramData(
                                                    program.program_id,
                                                    student.grade,
                                                    student.seq,
                                                  ).url,
                                                )
                                              }
                                            >
                                              {/* 상태 */}
                                              <strong
                                                className={
                                                  FormStatus[status].class
                                                }
                                              >
                                                {FormStatus[status].text}
                                              </strong>
                                              <br />

                                              {/* 점수 */}
                                              {status === 'CON' && (
                                                <small>점수: {point}</small>
                                              )}
                                            </td>
                                          );
                                        })}

                                      {/* 바우처 대상점수 */}
                                      <td>{student.point_sum}</td>

                                      {/* 누계 점수 */}
                                      {/* <td>{student.total_point}</td> */}

                                      {/* 바우처 */}
                                      <td className="text-end">
                                        {Utils.numberComma(student.voucher_sum)}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <ReactPaginate
                              pageRangeDisplayed={3}
                              nextLabel=""
                              previousLabel=""
                              breakLabel="..."
                              pageCount={totalPages}
                              renderOnZeroPageCount={null}
                              containerClassName="pagination mt-4"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              activeClassName="active"
                              onPageChange={e => {
                                movePage(e.selected + 1);
                              }}
                              forcePage={
                                pages[`${grade}`] ? pages[`${grade}`] - 1 : 0
                              }
                            />
                          </>
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  </>
                ) : (
                  <div className="w-100 text-center pb-3">
                    <p>검색하고 싶은 학교, 학년, 반을 검색해주세요.</p>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </article>
      </Container>
    </main>
  );
});

const turnOptions = [
  { label: '1차', value: 'turn-01' },
  { label: '2차', value: 'turn-02' },
  { label: '3차', value: 'turn-03' },
];
