/**
 *   [ 파일 조회 API ]
 *
 */
import Axios from './axios';

const API_FILE = 'api/v1/file';

const GET_TEMP_URL_TO_REDIS = `${API_FILE}/getTempUrl`;
const PUBLIC_DOWNLOAD = `${API_FILE}/public-download`;

// 파일 Redis Temp URL '등록' 및 '조회'
export const getFilePathFromRedis = data => {
  return Axios.post(`${GET_TEMP_URL_TO_REDIS}`, data);
};

export const publicDownload = seq => {
  return Axios.get(`${PUBLIC_DOWNLOAD}/${seq}`, {
    responseType: 'blob',
  });
};
