/**
 *   [ 자율교육 프로그램 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/teacher';

const SELECT_EDU_PROGRAME_LIST = `${API_PROGRAM}/edu-program-list`;
const SELECT_EDU_PROGRAME_INFO = `${API_PROGRAM}/edu-program-info`;
const INSERT_UPDATE_EDU_PROGRAM = `${API_PROGRAM}/edu-program`;
const UPDATE_STATUS = `${API_PROGRAM}/update-status-form-program-info`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 자율교육 프로그램 > [ SELECT ] 리스트 (결과보고서)
export const selectEduProgramListByTeacher = param => {
  return Axios.post(`${SELECT_EDU_PROGRAME_LIST}`, param);
};

// 자율교육 프로그램 > [ SELECT ] 개인정보 & Form 데이터
export const selectEduProgramInfoByTeacher = param => {
  return Axios.post(`${SELECT_EDU_PROGRAME_INFO}`, param);
};

// 자율교육 프로그램 > [ INSERT, UPDATE ] 신규등록, 임시저장, 삭제, 취소
export const updateStatusFormProgramInfo = param => {
  return Axios.post(`${UPDATE_STATUS}`, param);
};
