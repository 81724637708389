import IcClose from './ic_close.png';
import IcDown from './ic_down.png';
import IcUpDown from './ic_updown_arrow.png';
import Logo from './logo.png';
import IcCancel from './ic_cancel.png';
import IcAddImg from './ic_add_img.png';
import IcSchool from './ic_school.png';
import Checked from './checkbox_checked.png';
import Checkbox from './checkbox.png';
import IcArrowRight from './ic_arrow_right_on.png';

export default {
  icClose: IcClose,
  icDown: IcDown,
  icUpDown: IcUpDown,

  logo: Logo,
  icCancel: IcCancel,
  icAddImg: IcAddImg,
  icSchool: IcSchool,

  Checked,
  Checkbox,
  IcArrowRight,
};
