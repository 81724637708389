import moment from 'moment';
import crypto from 'crypto-js';

const secretkey = process.env.REACT_APP_SECRET_KEY;
const teacherIdPrefix = process.env.REACT_APP_TEACHER_ID_PREFIX;
const key = crypto.enc.Utf8.parse(secretkey.substring(0, 16));
const iv = crypto.enc.Utf8.parse(secretkey.substring(0, 16));

const utils = {
  logStyleErr: 'color:red',
  logStyleWarn: 'color:yellow',
  logStyleInfo: 'color:blue',
  logStyleSuccess: 'color:green',

  checkSpace: str => {
    if (str.search(/\s/) !== -1) {
      return true;
    }
    return false;
  },

  // 인풋 입력숫자 콤마표기
  changeNumberComma: str => {
    const result = (Number(str.replace(/\D/g, '')) || '').toLocaleString();
    return result;
  },

  // 숫자 콤마표기, 소수점 3자리까지
  numberComma: number => {
    const result = Number(number).toLocaleString(undefined, {
      maximumFractionDigits: 3,
    });
    return result;
  },

  // HTML 태그 제거
  removeHTML: text => {
    const imgTag = /<IMG(.*?)>/gi;
    const result = text
      .replace(imgTag, '')
      .replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, ' ');
    return result;
  },
  // date to String : yyyy-MM-dd
  dateToString: dateObject => {
    if (!dateObject) return null;
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const date = dateObject.getDate();
    return `${year}-${month >= 10 ? month : `0${month}`}-${
      date >= 10 ? date : `0${date}`
    }`;
  },

  // 날짜 하이픈 추가 (숫자만 입력)
  dateHypenFormat: date => {
    let result = date.trim();
    result = result
      .replace(/[^0-9]/g, '')
      .replace(/(\d{4})(\d{2})?(\d{2})/, '$1-$2-$3')
      .replace('--', '-');
    return result;
  },

  // 날짜 닷 추가 (숫자만 입력)
  dateDotFormat: date => {
    let result = date.trim();
    result = result
      .replace(/[^0-9]/g, '')
      .replace(/(\d{4})(\d{2})?(\d{2})/, '$1.$2.$3')
      .replace('..', '.');
    return result;
  },

  // 날짜 년/월/일 텍스트 추가 (숫자만 입력)
  dateTextFormat: date => {
    let result = date.trim();
    result = result
      .replace(/[^0-9]/g, '')
      .replace(/(\d{4})(\d{2})?(\d{2})/, '$1년 $2월 $3일');
    return result;
  },

  // 날짜 닷 추가 (숫자만 입력)
  phoneNumberHypenFormat: phoneNo => {
    let result = phoneNo.trim();
    result = result
      .replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{4})?(\d{4})/, '$1-$2-$3')
      .replace('..', '.');
    return result;
  },

  // 이메일 유효성 검사
  emailTest: email => {
    const reg =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i; // eslint-disable-line
    return !!reg.test(email);
  },

  // 모든 전화번호 유효성 검사
  phoneNumberTest: phoneNumber => {
    let removeHyphen = phoneNumber.replace(/\-/g, ''); // eslint-disable-line
    const reg = /^01(?:0|1|[6-9])-?([0-9]{3,4})-?([0-9]{4})$/;
    return !!reg.test(removeHyphen);
  },

  pwRuleCheck: pw => {
    const reg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    return !!reg.test(pw);
  },
  sleep: time => {
    return new Promise(resolve => {
      setTimeout(resolve, time);
    });
  },
  idRuleCheck: id => {
    const reg = /[~!@#$%^&*()_+|<>?:{}.*\\//₩=-]/;
    return !!reg.test(id);
  },

  // Null 검증
  checkNull: value => {
    if (value) return true;
    return false;
  },

  // 날짜 검증 ( 어제까지 )
  checkDateTime: value => {
    return value <= new Date().setDate(new Date().getDate());
  },

  // 숫자 검증
  checkNumber: value => {
    return value && Number.isInteger(+value) && +value > 0;
  },

  // 파일 조회 주소 가져오기
  getFileOpenUrl: (hostName, redisKey) => {
    let host;

    if (hostName === 'localhost') {
      host = `http://${hostName}:18080/api/v1/file/open/view/${redisKey}`;
    } else if (hostName === '192.168.0.169') {
      host = `http://${hostName}:18080/api/v1/file/open/view/${redisKey}`;
    } else if (hostName === 'school-dev.upchain.kr') {
      host = `http://${hostName}/api/v1/file/open/view/${redisKey}`;
    } else if (hostName === 'job.pen.go.kr') {
      host = `https://${hostName}/api/v1/file/open/view/${redisKey}`;
    }

    return host;
  },

  getPublicFileOpenUrl: (hostName, fileSeq) => {
    let host;

    if (hostName === 'localhost') {
      host = `http://${hostName}:18080/api/v1/file/open/public-download/${fileSeq}`;
    } else if (hostName === '192.168.0.169') {
      host = `http://${hostName}:18080/api/v1/file/open/public-download/${fileSeq}`;
    } else if (hostName === 'job.pen.go.kr') {
      host = `http://${hostName}/api/v1/file/open/public-download/${fileSeq}`;
    }

    return host;
  },

  // Form 요청 상태 정렬 ( REJ > REQ > ING > CON )
  sortFormStatus: (prevStatus, curStatus) => {
    const order = {
      REJ: '1',
      REQ: '2',
      ING: '3',
      CON: '4',
    };
    return order[prevStatus] < order[curStatus] ? prevStatus : curStatus;
  },

  limitCheck: (phrases, min, max) => {
    const phrasesLength = phrases ? phrases.length : 0;
    if (phrasesLength < min || max < phrasesLength) return false;
    return true;
  },
  rangeCheck: (num, min, max) => {
    const number = num || 0;
    if (number < min || max < number) return false;
    return true;
  },

  // yyyy-MM-dd 형태의 string 반환
  getJustDateString: dateString => {
    if (!dateString) return null;
    return dateString.match(/^\d{4}-\d{2}-\d{2}/)[0];
  },

  // 만나이 계산기 (1) > yyyy-MM-dd
  getFullAge: birthDateString => {
    if (!birthDateString) return null;

    const today = new Date();
    let age;
    let birthDate;

    // [ CASE 1 ] yyyy-MM-dd > birthDate
    if (birthDateString.split('-').length > 1) {
      birthDate = new Date(birthDateString.match(/^\d{4}-\d{2}-\d{2}/)[0]);
    }
    // [ CASE 2 ] yyyyMMdd > yyyy-MM-dd > birthDate
    else {
      birthDate = new Date(
        birthDateString
          .match(/^\d{4}\d{2}\d{2}/)[0]
          .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
      );
    }

    age = today.getFullYear() - birthDate.getFullYear();

    // 만나이 검증
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }

    return !Number.isNaN(age) ? age : '-';
  },

  // 암/복호화
  encrypt: text => {
    if (!text) return text;
    const cipher = crypto.AES.encrypt(text, key, {
      iv,
      padding: crypto.pad.Pkcs7,
      mode: crypto.mode.CBC,
    }).toString();
    return cipher;
  },

  encryptSHA256: text => {
    if (!text) return text;
    return crypto.SHA256(text).toString();
  },

  decrypt: ciphertext => {
    if (!ciphertext) return ciphertext;
    const decrypted = crypto.AES.decrypt(ciphertext, key, {
      iv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    }).toString(crypto.enc.Utf8);
    return decrypted;
  },

  fillZero(width, text) {
    const str = String(text);
    return str.length >= width
      ? str
      : new Array(width - str.length + 1).join('0') + str; // 남는 길이만큼 0으로 채움
  },

  createTeachId: schoolData => {
    try {
      const teacherIdMiddle = schoolData.schoolId;
      const teacherIdLast = Number(schoolData.teacherCnt) + 1;

      return `${teacherIdPrefix}${teacherIdMiddle}${utils.fillZero(
        3,
        teacherIdLast,
      )}`;
    } catch (error) {
      return false;
    }
  },

  // 프로그램 URL 반환 ( 학생 ) - FORM 페이지 이동
  convertProgram4Student: (programId, grade) => {
    let url;

    switch (programId) {
      case '1-1': // 진로설계
        url = '/student/indivAbility/careerDesign';
        break;
      case '1-2': // 직무기초 포트폴리오
        url = '/student/indivAbility/portfolio';
        break;
      case '1-3': // 산업체 채용면접
        url = '/student/indivAbility/visitIndustry';
        break;
      case '1-4': // NCS
        url = '/student/indivAbility/ncs';
        break;
      case '2-1': // 산업체 현장견학
        url = '/student/practiceAbility/excursions';
        break;
      case '2-2': // 산업체 현장체험
        url = '/student/practiceAbility/experience';
        break;
      case '2-3': // 산업체 현장실습
        url = '/student/practiceAbility/training';
        break;
      case '3-1': // 전공자격증 취득
        url = '/student/majorJobCert';
        break;
      case '4-1': // 전공기초 교육
        url = '/student/majorJobEdu/basic';
        break;
      case '4-2': // 전공심화 교육
        url = '/student/majorJobEdu/advanced';
        break;
      case '4-3': // 전공응용 교육
        url = '/student/majorJobEdu/applied';
        break;
      case '5-1': // 인성자존감 활동
        url = '/student/basicJob/tenacityAction';
        break;
      case '5-2': // 직업기초 능력평가
        url = '/student/employAbility/jobAssess';
        break;
      case '6-1': // 취업마인트 함양
        url = '/student/employAbility/mind-development';
        break;
      case '6-2': // 취업마인트 강화
        url = '/student/employAbility/mind-reinforcing';
        break;
      case '6-3': // 취업역량 강화
        url = '/student/employAbility/skills-reinforcing';
        break;
      case '7-1': // 자율교육 프로그램
        url = `/student/selfEduProgram/${grade}`;
        break;
      default:
        url = '/home';
        break;
    }

    return url;
  },

  // 프로그램 정보 반환 ( URL, Column )
  convertProgramData: (programId, grade, studentIdx) => {
    let url;
    let point;
    let status;

    switch (programId) {
      case '1-1': // 진로설계
        url = `/teacher/studentMgmt/careerDesign/comment/${studentIdx}`;
        point = 'form_career';
        status = 'form_career_status';
        break;
      case '1-2': // 직무기초 포트폴리오
        url = `/teacher/indivAbility/portfolio/comment/${studentIdx}`;
        point = 'form_portfolio';
        status = 'form_portfolio_status';
        break;
      case '1-3': // 산업체 채용면접
        url = `/teacher/indivAbility/visitIndustry/${studentIdx}`;
        point = 'form_industry_interview';
        status = 'form_industry_interview_status';
        break;
      case '1-4': // NCS
        url = `/teacher/indivAbility/ncs/${studentIdx}`;
        point = 'form_ncs';
        status = 'form_ncs_status';
        break;
      case '2-1': // 산업체 현장견학
        url = `/teacher/practiceAbility-teacher/excursions/${studentIdx}`;
        point = 'form_industry_visit';
        status = 'form_industry_visit_status';
        break;
      case '2-2': // 산업체 현장체험
        url = `/teacher/practiceAbility-teacher/experience/${studentIdx}`;
        point = 'form_industry_experience';
        status = 'form_industry_experience_status';
        break;
      case '2-3': // 산업체 현장실습
        url = `/teacher/practiceAbility-teacher/training/${studentIdx}`;
        point = 'form_industry_training';
        status = 'form_industry_training_status';
        break;
      case '3-1': // 전공자격증 취득
        // url = '/teacher/majorJobCert';
        url = `/teacher/studentMgmt/majorJobCert-teacher/comment/${studentIdx}`;
        point = 'form_certificate';
        status = 'form_certificate_status';
        break;
      case '4-1': // 전공기초 교육
        url = `/teacher/majorJobEdu-teacher/basic/${studentIdx}`;
        point = 'form_major_education_1';
        status = 'form_major_education_1_status';
        break;
      case '4-2': // 전공심화 교육
        url = `/teacher/majorJobEdu-teacher/advanced/${studentIdx}`;
        point = 'form_major_education_2';
        status = 'form_major_education_2_status';
        break;
      case '4-3': // 전공응용 교육
        url = `/teacher/majorJobEdu-teacher/applied/${studentIdx}`;
        point = 'form_major_education_3';
        status = 'form_major_education_3_status';
        break;
      case '5-1': // 인성자존감 활동
        url = `/teacher/studentMgmt/basicJob/tenacityAction-teacher/comment/${studentIdx}`;
        point = 'form_insung';
        status = 'form_insung_status';
        break;
      case '5-2': // 직업기초 능력평가
        url = `/teacher/basicJob-teacher/jobAssess/${studentIdx}`;
        point = 'form_ability_test';
        status = 'form_ability_test_status';
        break;
      case '6-1': // 취업마인트 함양
        url = `/teacher/employAbility-teacher/mind-development/${studentIdx}`;
        point = 'form_work_program_1';
        status = 'form_work_program_1_status';
        break;
      case '6-2': // 취업마인트 강화
        url = `/teacher/employAbility-teacher/mind-reinforcing/${studentIdx}`;
        point = 'form_work_program_2';
        status = 'form_work_program_2_status';
        break;
      case '6-3': // 취업역량 강화
        url = `/teacher/employAbility-teacher/skills-reinforcing/${studentIdx}`;
        point = 'form_work_program_3';
        status = 'form_work_program_3_status';
        break;
      case '7-1': // 자율교육 프로그램
        url = `/teacher/selfEduProgram-teacher/${grade}/${studentIdx}`;
        point = 'form_free_program';
        status = 'form_free_program_status';
        break;
      default:
        url = '/home';
        point = '';
        status = '';
        break;
    }

    return { url, point, status };
  },
  formatFileSize: bytes => {
    if (bytes < 1024) {
      return `${bytes} bytes`;
    }
    if (bytes < 1024 * 1024 * 1024) {
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    }
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  },

  convertIndustryProgramTitle: grade => {
    switch (+grade) {
      case 1:
        return '산업체 현장견학';
      case 2:
        return '산업체 현장체험';
      case 3:
        return '산업체 현장실습';
      default:
        return '';
    }
  },
  convertWorkProgramTitle: grade => {
    switch (+grade) {
      case 1:
        return '취업마인드 함양';
      case 2:
        return '취업마인드 강화';
      case 3:
        return '취업역량 강화';
      default:
        return '';
    }
  },
  // 생년월일 변환 (yyyyMMdd > yyyy-MM-dd)
  convertBirth: birth => {
    let result = birth;

    if (birth.length === 8) {
      const year = birth.substring(0, 4);
      const month = birth.substring(4, 6);
      const day = birth.substring(6, 8);

      result = `${year}-${month}-${day}`;
    }

    return result;
  },

  // 반(Class), 번호(Number) 드랍다운 생성
  createClassDropdownList: (length = 0) => {
    // 두자릿수 숫자 문자열
    const setDoubleDigitNumberString = num => {
      num = isNaN(+num) ? 0 : +num;
      num = num > 99 ? 99 : num;

      return num < 10 ? `0${num}` : `${num}`;
    };

    return Array.from({ length }, (v, i) => {
      return {
        label: i + 1,
        value: setDoubleDigitNumberString(i + 1),
      };
    });
  },
};

export default utils;
