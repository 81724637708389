import Axios from '@api/axios';

/**
 *   [ 시스템 관리자 > 메뉴관리 API ]
 *
 */

const API_ADMIN_SYSTEM = 'api/v1/adminSystem';

// [ 메뉴관리 ] 메뉴 리스트, 옵션들 조회
export const getMenuAdnOptions = () => {
  return Axios.get(`${API_ADMIN_SYSTEM}/menu-and-options`);
};

// [ 메뉴관리 ] 메뉴 등록
export const postMenu = data => {
  return Axios.post(`${API_ADMIN_SYSTEM}/menu`, data);
};

// [ 메뉴관리 ] 메뉴 수정
export const patchMenu = data => {
  return Axios.patch(`${API_ADMIN_SYSTEM}/menu`, data);
};

// [ 메뉴관리 ] 메뉴 삭제
export const deleteMenu = menuIdx => {
  return Axios.delete(`${API_ADMIN_SYSTEM}/menu/${menuIdx}`);
};

// [ 메뉴관리 ] Redis 업데이트
export const putAllWebMenu = () => {
  return Axios.put(`${API_ADMIN_SYSTEM}/all-web-menu`);
};
