/** @jsxImportSource @emotion/react */
import React from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import { css } from '@emotion/react';

function CustomModal({ title, children, ...rest }) {
  return (
    <Modal css={customModal} centered {...rest}>
      <Modal.Header closeButton>
        <Modal.Title as="h6">{title}</Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
}
const customModal = css`
  .modal-dialog {
    border: none;
    &.modal-xs {
      max-width: 400px;
    }
    &.modal-sm {
      max-width: 450px;
    }
    &.modal-md {
      max-width: 590px;
    }
    &.modal-lg {
      max-width: 720px;
    }
    &.modal-xl {
      max-width: 1080px;
    }
    .modal-content {
      .modal-header {
        padding: 1.3rem 1.4rem;
        justify-content: center;
        border-bottom: none;
        .modal-title {
          color: #191919;
          font-weight: 600;
          font-size: 20px;
          line-height: normal;
        }
        .btn-close {
          display: none;
        }
      }
      .modal-body {
        max-height: 80vh;
        margin: 0 14px;
        padding: 14px;
        border: 1px solid var(--bs-border);
        font-weight: 500;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.3);
          border-radius: 8px;
        }
        &::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 8px;
        }
        .form-label {
          font-size: 14px;
          color: #6f6f7f;
        }
      }
      .modal-footer {
        justify-content: center;
        border-top: none;
        .btn {
          min-width: 90px;
        }
      }
    }
  }
`;
export default CustomModal;
