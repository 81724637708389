/**
 *   [ 홈 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const SELECT_DASHBOARD_INFO = `${API_PROGRAM}/dashboard-info`;
const SELECT_POINT_VOUCHER_SUMMARY = `${API_PROGRAM}/point-voucher-summary`;
const SELECT_STATUS_SUMMARY = `${API_PROGRAM}/status-summary`;

// 홈 > 대시보드 정보 (유저 정보, 누계점수, 바우처 금액)
export const selectDashboardInfo = param => {
  return Axios.post(`${SELECT_DASHBOARD_INFO}`, param);
};

// // 홈 > 누계점수, 바우처 금액
// export const selectPointVoucherSummary = param => {
//   return Axios.post(`${SELECT_POINT_VOUCHER_SUMMARY}`, param);
// };

// 홈 > 학년별 프로그램 리스트
export const selectStatusSummary = param => {
  return Axios.post(`${SELECT_STATUS_SUMMARY}`, param);
};
