/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { selectStudentInfo } from '@api/student/mypage';
import { Utils } from '@common';
import Moment from 'react-moment';

export default React.memo(function Mypage(props) {
  const history = useHistory();

  // studentInfo
  const [studentInfo, setStudentInfo] = useState({});

  /** API */
  const getMyInfo = async () => {
    try {
      const { data } = await selectStudentInfo();
      if (data.code === 0) {
        setStudentInfo(data.data);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  /** useEffect */
  useEffect(() => {
    getMyInfo();
  }, []);

  return (
    <main id="mypage">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">마이페이지</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>마이페이지</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>학교정보</h5>
            </div>
            <div className="split-table type-simple">
              <Row className="table-row">
                <Col className="th col-2">학교명</Col>
                <Col className="td col-10">{studentInfo.school_name}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">이름</Col>
                <Col className="td col-10">
                  {Utils.decrypt(studentInfo.student_name)}
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">성별</Col>
                <Col className="td col-10">{studentInfo.sex}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">전공</Col>
                <Col className="td col-10">{studentInfo.student_depart}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">학년/반/번호</Col>
                <Col className="td col-10">
                  {`${studentInfo.grade}학년 ${
                    studentInfo.ban_no && window.parseInt(studentInfo.ban_no)
                  }반 ${
                    studentInfo.student_no &&
                    window.parseInt(studentInfo.student_no)
                  }번`}
                </Col>
              </Row>
            </div>
          </section>

          {/* 인적사항 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>인적사항</h5>
            </div>
            <div className="split-table type-simple">
              <Row className="table-row">
                <Col className="th col-2">주소</Col>
                <Col className="td col-10">
                  {studentInfo.addr1 ? Utils.decrypt(studentInfo.addr1) : '-'}
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">상세주소</Col>
                <Col className="td col-10">
                  {studentInfo.addr2 ? Utils.decrypt(studentInfo.addr2) : '-'}
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">생일</Col>
                <Col className="td col-10">
                  {studentInfo.student_birth && (
                    <Moment
                      date={Utils.dateHypenFormat(
                        Utils.decrypt(studentInfo.student_birth),
                      )}
                      format="YYYY.MM.DD"
                    />
                  )}
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">연락처</Col>
                <Col className="td col-10">
                  {studentInfo.phone_no &&
                    Utils.phoneNumberHypenFormat(
                      Utils.decrypt(studentInfo.phone_no),
                    )}
                </Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">이메일</Col>
                <Col className="td col-10">
                  {studentInfo.e_mail && Utils.decrypt(studentInfo.e_mail)}
                </Col>
              </Row>
            </div>
          </section>

          {/* 비밀번호 */}
          <section>
            <div className="sub-title">
              <h5>비밀번호</h5>
            </div>
            <div className="split-table type-simple">
              <Row className="table-row">
                <Col className="th col-2">비밀번호</Col>
                <Col className="td col-10">**********</Col>
              </Row>
            </div>
          </section>
        </article>
      </Container>

      {/* ////////// ↓ 하단 버튼 //////////  */}
      <div className="btn-area">
        <Button
          onClick={() => props.history.push('/student/mypage/edit')}
          size="lg"
          variant="primary"
        >
          수정
        </Button>
      </div>
    </main>
  );
});
