/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Table,
  Tab,
  Nav,
  Modal,
} from 'react-bootstrap';
import { SearchSchool, SearchGradeClass, CustomModal } from '@components';
import { Utils } from '@common';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import LoadingSpinner from '@components/LoadingSpinner';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { getSchools } from '@api/common/common';
import {
  txtDownloadStudents,
  txtNewDownloadStudents,
  excelDownloadStudents,
  getStudents,
} from '@api/eduadmin/student';

export default React.memo(function AdmEduStudentStatus(props) {
  const history = useHistory();

  const refresh =
    (props.history.location.state && props.history.location.state.refresh) ||
    'refresh';

  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const studentName =
    (history.location.state && history.location.state.studentName) || '';

  const school =
    (history.location.state && history.location.state.school) || {};

  const gradeList =
    (history.location.state && history.location.state.gradeList) || [];

  const grade = (history.location.state && history.location.state.grade) || [];

  const banList =
    (history.location.state && history.location.state.banList) || [];

  const gradeBanList =
    (history.location.state && history.location.state.gradeBanList) || [];

  const pages = (history.location.state && history.location.state.pages) || {
    1: 1,
    2: 1,
    3: 1,
  };

  //* #################################################################
  //* [ State ]
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(true);

  const [totalPages, setTotalPages] = useState();

  const [searchedStudentName, setSearchedStudentName] = useState(studentName);

  const [selectedSchool, setSelectedSchool] = useState(school);
  const [selectedGradeList, setSelectedGradeList] = useState(gradeList);
  const [selectedBanList, setSelectedBanList] = useState(gradeBanList);

  const [studentList, setStudentList] = useState([]);

  const [checkedGradeList, setCheckedGradeList] = useState(gradeBanList);

  const [reset, setReset] = useState(false);

  const [isSearch, setIsSearch] = useState(false);

  const [schoolList, setSchoolList] = useState([]);

  const [showSpinner, setShowSpinner] = useState(false);

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  const [downloadModalShow, setDownloadModalShow] = useState(false);
  const [selectedDownloadData, setSelectedDownloadData] = useState('all');
  const [selectedDownloadSchool, setSelectedDownloadSchool] = useState([]);

  const openDownloadModal = () => {
    setDownloadModalShow(true);
  };

  const closeDownloadModal = () => {
    setDownloadModalShow(false);
    setSelectedDownloadData('all');
    setSelectedDownloadSchool([]);
  };

  const handleDownloadData = e => {
    setSelectedDownloadData(e.target.value);
  };

  const handleDownloadSchool = e => {
    if (e.target.checked) {
      if (e.target.value === 'all') {
        setSelectedDownloadSchool([
          ...schoolList.map((v, i) => {
            return String(v.seq);
          }),
        ]);
      } else {
        setSelectedDownloadSchool([...selectedDownloadSchool, e.target.value]);
      }
    } else if (e.target.value === 'all') {
      setSelectedDownloadSchool([]);
    } else {
      setSelectedDownloadSchool(
        selectedDownloadSchool.filter(val => val !== e.target.value),
      );
    }
  };

  //* #################################################################
  //* [ Util ]
  //* #################################################################

  // 검색
  const onSearch = () => {
    if (!checkedGradeList.length > 0) {
      return;
    }
    const tempGradeList = checkedGradeList.map((v, i) => {
      return v.grade;
    });

    setSelectedGradeList(tempGradeList);
    setSelectedBanList(checkedGradeList);
    setIsSearch(true);
  };

  // 페이지 이동
  const movePage = (pageNum, isSearch, newGrade) => {
    const tempPages = pages;
    tempPages[`${grade}`] = pageNum;

    const initPages = {
      1: 1,
      2: 1,
      3: 1,
    };

    const state = {
      pages: isSearch ? initPages : tempPages,
      grade: newGrade || (isSearch ? selectedGradeList.sort()[0] : grade),
      studentName: isSearch ? searchedStudentName : studentName,
      school: isSearch ? selectedSchool : school,
      gradeList: isSearch ? selectedGradeList : gradeList,
      banList: newGrade
        ? gradeBanList.find(v => v.grade === newGrade).classes
        : isSearch
        ? checkedGradeList.find(v => v.grade === selectedGradeList.sort()[0])
            .classes
        : banList,
      gradeBanList: isSearch ? selectedBanList : gradeBanList,
      pageRefresh: !pageRefresh,
    };

    history.push({
      pathname: `/admEdu/student/status`,
      state,
    });
  };

  // 전체 상태 관리
  const settingState = () => {
    setSelectedSchool(school);
    setCheckedGradeList([...gradeBanList]);
    setSearchedStudentName(studentName);
    setIsSearch(false);
    setReset(!reset);
  };

  //* #################################################################
  //* [ API ] 학생정보 가져오기
  //* #################################################################
  const getStudentList = async () => {
    const params = {
      page: pages[`${grade}`],
      size: 10,
      schoolIdx: school.value === 'all' ? null : school.value,
      grade,
      banNoList: banList,
      studentName: searchedStudentName,
    };

    setFirstCallDone(false);
    const { data } = await getStudents(params);
    if (data.code === 0) {
      setTotalPages(data.data.totalPages);
      setStudentList(data.data.list);
      setFirstCallDone(true);
    }
  };

  //* #################################################################
  //* [ API ] 학교 리스트 가져오기
  //* #################################################################
  const getSchoolList = async () => {
    const { data } = await getSchools();
    if (data.code === 0) {
      const tempList = data.data.map(v => {
        const tempData = {
          ...v,
          value: v.seq,
          label: v.schoolName,
        };
        return tempData;
      });

      setSchoolList(tempList);
    }
  };

  //* #################################################################
  //* [ API ] .txt 다운로드
  //* #################################################################
  const txtDownload = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const params = {
        downloadType: selectedDownloadData,
        schoolIdxList: selectedDownloadSchool,
      };
      setShowSpinner(true);
      const { data } = await txtDownloadStudents(params);
      const blob = new Blob([data]);
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      const fileName = format(new Date(), 'yyyyMMdd ').toString();
      link.download = fileName;
      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      target.disabled = false;
      setShowSpinner(false);
    }
    target.disabled = false;
    setShowSpinner(false);
  };

  const txtNewDownload = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const params = {
        downloadType: selectedDownloadData,
        schoolIdxList: selectedDownloadSchool,
      };
      setShowSpinner(true);
      const { data } = await txtNewDownloadStudents(params);
      const blob = new Blob([data]);
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      const fileName = format(new Date(), 'yyyyMMdd ').toString();
      link.download = fileName;
      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      target.disabled = false;
      setShowSpinner(false);
    }
    target.disabled = false;
    setShowSpinner(false);
  };

  //* #################################################################
  //* [ API ] .xlsx 다운로드
  //* #################################################################
  const excelDownload = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const params = {
        downloadType: selectedDownloadData,
        schoolIdxList: selectedDownloadSchool,
      };
      setShowSpinner(true);
      const { data } = await excelDownloadStudents(params);
      const blob = new Blob([data]);

      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = `${format(new Date(), 'yyyyMMdd')}.xlsx`;

      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      target.disabled = false;
      setShowSpinner(false);
    }
    target.disabled = false;
    setShowSpinner(false);
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    if (isSearch) {
      movePage(1, true);
    }
  }, [isSearch]);

  useEffect(() => {
    if (school.value) {
      settingState();
      getStudentList();
    }
  }, [pageRefresh]);

  useEffect(() => {
    settingState();
  }, [refresh]);

  useEffect(() => {
    getSchoolList();
  }, []);

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <main id="admEdu-student-newReg-status" className="type-02">
      <Container>
        {firstCallDone && (
          <article className="content py">
            <section>
              <div className="title">
                <h5>학생현황</h5>
              </div>
            </section>
            {showSpinner ? (
              <LoadingSpinner show={showSpinner} message="다운로드 중입니다." />
            ) : (
              <Card>
                <Card.Header>
                  <div className="flex-start">
                    <SearchSchool
                      selectedSchool={selectedSchool}
                      setSelectedSchool={item => {
                        setSelectedSchool(item);
                        setCheckedGradeList([]);
                      }}
                    />
                    <SearchGradeClass
                      className="ms-2"
                      reset={reset}
                      resetData={gradeBanList}
                      schoolidx={selectedSchool.value}
                      setCheckedGradeList={setCheckedGradeList}
                      checkedGradeList={checkedGradeList}
                    />
                    <div className="input-has-btn ms-2">
                      <Form.Control
                        type="text"
                        placeholder="학생 이름을 검색하세요."
                        className="input-search"
                        value={searchedStudentName}
                        onChange={e => {
                          setSearchedStudentName(e.target.value);
                        }}
                      />
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => {
                          onSearch();
                        }}
                      >
                        검색
                      </Button>
                    </div>
                  </div>
                </Card.Header>

                <Card.Body>
                  <div className="flex-between mb-3">
                    <h5>{school.label}</h5>
                    <div className="btn-area m-0">
                      <Button
                        variant="outline-primary"
                        onClick={openDownloadModal}
                      >
                        다운로드
                      </Button>
                    </div>
                  </div>
                  {school.value ? (
                    <Tab.Container defaultActiveKey="freshman" id="grade-tab">
                      <Nav className="text-tabs">
                        <Nav.Item>
                          {selectedGradeList.map((v, i) => {
                            return (
                              <Nav.Link
                                key={`a_grades_${v}`}
                                eventKey={`grades_${v}`}
                                active={v === grade}
                                onClick={() => {
                                  movePage(pages[`${grade}`], false, v);
                                }}
                              >
                                {v}학년
                              </Nav.Link>
                            );
                          })}
                          {/* <Nav.Link eventKey="freshman">1학년</Nav.Link>
                      <Nav.Link eventKey="junior">2학년</Nav.Link>
                      <Nav.Link eventKey="senior">3학년</Nav.Link> */}
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        {/* ↓ 1학년 탭 */}
                        {/* <Tab.Pane eventKey={`grades_${searchedGrade}`}> */}
                        {grade && (
                          <>
                            <Table className="bt-none tr-even-bg">
                              <colgroup>
                                <col width={80} />
                                <col width={80} />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>반</th>
                                  <th>번호</th>
                                  <th className="text-start">이름</th>
                                  <th>상태</th>
                                  <th>전공</th>
                                  <th>연락처</th>
                                </tr>
                              </thead>
                              <tbody>
                                {studentList.length > 0 ? (
                                  studentList.map((item, idx) => {
                                    return (
                                      <tr
                                        key={`freshman-${item.seq}`}
                                        className="go-detail"
                                        onClick={() =>
                                          history.push(
                                            `/admEdu/student/detail/${item.seq}`,
                                          )
                                        }
                                      >
                                        <td>{item.banNo}</td>
                                        <td>{item.studentNo}</td>
                                        <td className="text-start">
                                          {Utils.decrypt(item.studentName)}
                                        </td>
                                        <td>{item.status}</td>
                                        <td>{item.studentDepart}</td>
                                        <td>{Utils.decrypt(item.phoneNo)}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>
                                      <p className="no-data">
                                        데이터가 없습니다.
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                            <ReactPaginate
                              pageRangeDisplayed={3}
                              nextLabel=""
                              previousLabel=""
                              breakLabel="..."
                              // pageCount={totalPageCnt || page || 1}
                              pageCount={totalPages}
                              renderOnZeroPageCount={null}
                              containerClassName="pagination mt-4"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              activeClassName="active"
                              onPageChange={e => {
                                movePage(e.selected + 1);
                              }}
                              forcePage={
                                pages[`${grade}`] ? pages[`${grade}`] - 1 : 0
                              }
                            />
                          </>
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  ) : (
                    <div className="w-100 text-center pb-3">
                      <p>검색하고 싶은 학교, 학년, 반을 검색해주세요.</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </article>
        )}
      </Container>
      <CustomModal
        show={downloadModalShow}
        onHide={closeDownloadModal}
        title="다운로드"
        id="modal-download"
      >
        <Modal.Body>
          <p>자료를 선택해주세요.</p>
          <div className="border-box d-flex flex-column">
            <Form.Check
              type="radio"
              label="전체"
              name="download-data"
              id="download-data-all"
              value="all"
              checked={selectedDownloadData === 'all'}
              onChange={e => handleDownloadData(e)}
            />
            <Form.Check
              type="radio"
              label="신규학생"
              name="download-data"
              id="download-data-new"
              value="new"
              checked={selectedDownloadData === 'new'}
              onChange={e => handleDownloadData(e)}
            />
          </div>
          <p className="mt-3">학교를 선택해주세요.</p>
          <div className="border-box d-flex flex-column">
            <div className="search-box">
              <div className="input-has-btn">
                <Form.Control
                  type="text"
                  placeholder="학교를 검색하세요."
                  className="input-search"
                />
                <Button size="sm" variant="primary">
                  검색
                </Button>
              </div>
            </div>
            <SimpleBar style={{ paddingTop: '0.5rem', maxHeight: 160 }}>
              <Form.Check
                type="checkbox"
                label="전체"
                name="download-school"
                id="school-all"
                value="all"
                onChange={e => handleDownloadSchool(e)}
              />
              {schoolList &&
                schoolList.map((item, idx) => {
                  return (
                    <Form.Check
                      type="checkbox"
                      name="download-school"
                      id={`download-school-${idx}`}
                      key={`download-school-${idx}`}
                      label={item.label}
                      value={item.seq}
                      checked={
                        !!selectedDownloadSchool.find(
                          v => String(v) === String(item.seq),
                        )
                      }
                      onChange={e => {
                        handleDownloadSchool(e);
                      }}
                    />
                  );
                })}
            </SimpleBar>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeDownloadModal}>
            취소
          </Button>
          <Button
            variant="primary"
            onClick={excelDownload}
            disabled={
              !selectedDownloadData ||
              !selectedDownloadSchool ||
              selectedDownloadSchool.length === 0
            }
          >
            엑셀
          </Button>
          <Button
            variant="primary"
            onClick={txtDownload}
            disabled={
              !selectedDownloadData ||
              !selectedDownloadSchool ||
              selectedDownloadSchool.length === 0
            }
          >
            텍스트(기존)
          </Button>
          <Button
            variant="primary"
            onClick={txtNewDownload}
            disabled={
              !selectedDownloadData ||
              !selectedDownloadSchool ||
              selectedDownloadSchool.length === 0
            }
          >
            텍스트(신규)
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
