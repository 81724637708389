import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Image, Button } from 'react-bootstrap';
import { images } from '@assets';

export default React.memo(function Errors(props) {
  return (
    <main id="errors">
      <Container>
        <h2>404</h2>
        <p className="text-gray">페이지를 찾을 수 없습니다.</p>
        <Button
          variant="dark"
          size="lg"
          onClick={() => props.history.push('/')}
        >
          홈으로 돌아가기
        </Button>
      </Container>
    </main>
  );
});
