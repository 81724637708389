import Axios from '@api/axios';
// import { da } from 'date-fns/locale';

const API_SYSTEM_CONFIG = 'api/v1/system/config';
const API_AUTH_ADMIN = 'api/v1/auth/admin';

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// [시스템 관리 > IP 관리] IP 관리 리스트 조회
export const getIpList = () => {
  return Axios.get(`${API_SYSTEM_CONFIG}/ip-list`);
};

// [시스템 관리 > IP 관리] IP 수정
export const patchIp = data => {
  return Axios.patch(`${API_SYSTEM_CONFIG}/ip`, data);
};

// [시스템 관리 > IP 관리] IP 삭제
export const deleteIp = schoolIdx => {
  return Axios.delete(`${API_SYSTEM_CONFIG}/ip/${schoolIdx}`);
};

// [시스템 관리 > 일정관리] 비빌번호 인증
export const varifyPassword = param => {
  return Axios.post(`${API_AUTH_ADMIN}/checkPw`, param);
};

// [시스템 관리 > 일정관리] STEP 조회
export const getStep = () => {
  return Axios.get(`${API_SYSTEM_CONFIG}/step`);
};

// [시스템 관리 > 일정관리] STEP 전환
export const goNextStep = nextStep => {
  return Axios.patch(`${API_SYSTEM_CONFIG}/next-step/${nextStep}`);
};

// [시스템 관리 > 일정관리] 차수 전환
export const goNextNth = () => {
  return Axios.patch(`${API_SYSTEM_CONFIG}/next-nth`);
};

// [시스템 관리 > 기업관리] 조회 - 단일
export const getMatchingCompany = seq => {
  return Axios.get(`${API_SYSTEM_CONFIG}/matching-company/${seq}`);
};

// [시스템 관리 > 기업관리] 조회 - 리스트
export const getMatchingCompanyList = param => {
  return Axios.get(`${API_SYSTEM_CONFIG}/matching-company-list`, {
    params: param,
  });
};

// [시스템 관리 > 기업관리] 등록 ( INPUT )
export const postMatchingCompanyByInput = param => {
  return Axios.post(`${API_SYSTEM_CONFIG}/add-matching-company`, param);
};

// [시스템 관리 > 기업관리] 등록 ( Excel )
export const postMatchingCompanyByExcel = formData => {
  return Axios.post(
    `${API_SYSTEM_CONFIG}/upload-matching-company`,
    formData,
    formDataConfig,
  );
};

// [시스템 관리 > 기업관리] 수정
export const patchMatchingCompany = param => {
  return Axios.patch(`${API_SYSTEM_CONFIG}/modify-matching-company`, param);
};

// [시스템 관리 > 기업관리] 수정 ( Excel )
export const deleteMatchingCompany = seq => {
  return Axios.delete(`${API_SYSTEM_CONFIG}/remove-matching-company/${seq}`);
};
