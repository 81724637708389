/* eslint-disable prefer-const */
/* eslint-disable default-case */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, Form, Container } from 'react-bootstrap';
import { images } from '@assets';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { selectSchoolGradeAndBan } from '@api/common/common';

export default function SearchGradeClass({
  schoolidx,
  setCheckedGradeList,
  checkedGradeList,
  reset,
  resetData,
  ...rest
}) {
  const [activeGrade, setActiveGrade] = useState({}); // 펼쳐질 학년
  const [checkedGrades, setCheckedGrades] = useState(checkedGradeList || []); // 선택된 학년

  const resetChecked = () => {
    setCheckedGrades(resetData || []);
    setCheckedFmClasses(
      (resetData &&
        resetData.find(item => item.grade === 1) &&
        resetData.find(item => item.grade === 1).classes) ||
        [],
    );
    setCheckedJnClasses(
      (resetData &&
        resetData.find(item => item.grade === 2) &&
        resetData.find(item => item.grade === 2).classes) ||
        [],
    );
    setCheckedSnClasses(
      (resetData &&
        resetData.find(item => item.grade === 3) &&
        resetData.find(item => item.grade === 3).classes) ||
        [],
    );
  };

  useEffect(() => {
    if (reset !== undefined) {
      resetChecked();
    }
  }, [reset]);

  const [checkedFmClasses, setCheckedFmClasses] = useState(
    (checkedGradeList &&
      checkedGradeList.find(item => item.grade === 1) &&
      checkedGradeList.find(item => item.grade === 1).classes) ||
      [],
  ); // 1학년 선택된 반
  const [checkedJnClasses, setCheckedJnClasses] = useState(
    (checkedGradeList &&
      checkedGradeList.find(item => item.grade === 2) &&
      checkedGradeList.find(item => item.grade === 2).classes) ||
      [],
  ); // 2학년 선택된 반
  const [checkedSnClasses, setCheckedSnClasses] = useState(
    (checkedGradeList &&
      checkedGradeList.find(item => item.grade === 3) &&
      checkedGradeList.find(item => item.grade === 3).classes) ||
      [],
  ); // 3학년 선택된 반

  const [grades, setGrade] = useState({
    Fm: {
      grade: 1,
      classes: [],
    },
    Jn: {
      grade: 2,
      classes: [],
    },
    Sn: {
      grade: 3,
      classes: [],
    },
  });

  // 반옵션(반 전체)
  const gradeFmClasses = grades.Fm.classes;
  const gradeJnClasses = grades.Jn.classes;
  const gradeSnClasses = grades.Sn.classes;

  // 학년 전체 선택
  const onCheckedAllGrade = useCallback(
    checked => {
      if (checked) {
        onCheckedAllClasses(true, 1);
        onCheckedAllClasses(true, 2);
        onCheckedAllClasses(true, 3);
        setCheckedGrades([
          JSON.parse(JSON.stringify(grades.Fm)),
          JSON.parse(JSON.stringify(grades.Jn)),
          JSON.parse(JSON.stringify(grades.Sn)),
        ]);
      } else {
        setCheckedGrades([]);
        setCheckedFmClasses([]);
        setCheckedJnClasses([]);
        setCheckedSnClasses([]);
      }
    },
    [grades],
  );

  // 학년 개별 선택
  const onCheckedGrade = useCallback(
    (checked, grade) => {
      // 펼칠 학년 선택
      setActiveGrade(grade);

      // 학년 전체
      // if (checked) {
      //   // 1학년
      //   if (grade === grades.Fm && checkedFmClasses.length === 0) {
      //     onCheckedAllClasses(true, 1);
      //     return;
      //   }
      //   // 2학년
      //   if (grade === grades.Jn && checkedJnClasses.length === 0) {
      //     onCheckedAllClasses(true, 2);
      //     return;
      //   }h
      //   // 3학년
      //   if (grade === grades.Sn && checkedSnClasses.length === 0) {
      //     onCheckedAllClasses(true, 3);
      //   }
      // }
    },
    [
      grades,
      activeGrade,
      checkedGrades,
      checkedFmClasses,
      checkedJnClasses,
      checkedSnClasses,
    ],
  );

  // 반 전체 선택
  const onCheckedAllClasses = useCallback(
    (checked, grade) => {
      let thisGrade;
      let gradeClasses;
      switch (grade) {
        case 1:
          thisGrade = JSON.parse(JSON.stringify(grades.Fm));
          gradeClasses = gradeFmClasses;
          break;
        case 2:
          thisGrade = JSON.parse(JSON.stringify(grades.Jn));
          gradeClasses = gradeJnClasses;
          break;
        case 3:
          thisGrade = JSON.parse(JSON.stringify(grades.Sn));
          gradeClasses = gradeSnClasses;
          break;
      }
      // 선택될 때
      if (checked) {
        if (!checkedGrades.find(item => thisGrade.grade === item.grade)) {
          setCheckedGrades([...checkedGrades, thisGrade]);
        }
        const checkedListArray = [];
        gradeClasses.forEach(item => checkedListArray.push(item));
        if (grade === 1) setCheckedFmClasses(checkedListArray);
        if (grade === 2) setCheckedJnClasses(checkedListArray);
        if (grade === 3) setCheckedSnClasses(checkedListArray);
        return;
      }
      // 선택 해제
      setCheckedGrades(
        checkedGrades.filter(el => el.grade !== thisGrade.grade),
      );
      if (grade === 1) setCheckedFmClasses([]);
      if (grade === 2) setCheckedJnClasses([]);
      if (grade === 3) setCheckedSnClasses([]);
    },
    [grades, checkedGrades, gradeFmClasses, gradeJnClasses, gradeSnClasses],
  );

  // 반 개별 선택
  const onCheckedIndClasses = useCallback(
    (checked, grade, item) => {
      let thisGrade;
      let checkedClasses;
      switch (grade) {
        case 1:
          thisGrade = JSON.parse(JSON.stringify(grades.Fm));
          checkedClasses = checkedFmClasses;
          break;
        case 2:
          thisGrade = JSON.parse(JSON.stringify(grades.Jn));
          checkedClasses = checkedJnClasses;
          break;
        case 3:
          thisGrade = JSON.parse(JSON.stringify(grades.Sn));
          checkedClasses = checkedSnClasses;
          break;
      }
      // 선택될 때
      if (checked) {
        const gradeObj = checkedGrades.find(o => o.grade === grade);
        if (gradeObj) {
          if (gradeObj.classes && gradeObj.classes.indexOf(item) < 0) {
            gradeObj.classes.push(item);
          } else if (!gradeObj.classes) {
            gradeObj.classes = [item];
          }
          setCheckedGrades([...checkedGrades]);
        } else {
          setCheckedGrades([
            ...checkedGrades,
            {
              grade,
              classes: [item],
            },
          ]);
        }
        if (grade === 1) setCheckedFmClasses([...checkedClasses, item]);
        if (grade === 2) setCheckedJnClasses([...checkedClasses, item]);
        if (grade === 3) setCheckedSnClasses([...checkedClasses, item]);
        return;
      }
      // 선택 해제
      if (checkedClasses.length === 1) {
        setCheckedGrades(
          checkedGrades.filter(el => el.grade !== thisGrade.grade),
        );
      }
      if (grade === 1)
        setCheckedFmClasses(checkedClasses.filter(el => el !== item));
      if (grade === 2)
        setCheckedJnClasses(checkedClasses.filter(el => el !== item));
      if (grade === 3)
        setCheckedSnClasses(checkedClasses.filter(el => el !== item));

      const gradeObj = checkedGrades.find(o => o.grade === grade);
      if (gradeObj) {
        const index = gradeObj.classes.indexOf(item);
        gradeObj.classes.splice(index, 1);
        if (gradeObj.classes.length === 0) {
          const except = checkedGrades.filter(el => el !== gradeObj);
          setCheckedGrades([...except]);
        } else {
          setCheckedGrades([...checkedGrades]);
        }
      }
    },
    [
      grades,
      checkedGrades,
      checkedFmClasses,
      checkedJnClasses,
      checkedSnClasses,
    ],
  );

  // 표시될 학년/반
  const [gradeAndClassStr, setGradeAndClassStr] = useState();
  const toStringCheckedResults = () => {
    const resultArr = [];
    let resultText = '';

    let fmClassesText;
    let jnClassesText;
    let snClassesText;

    if (!schoolidx) {
      const resultStr = '학년/반 선택';
      setGradeAndClassStr(resultStr);
      return resultStr;
    }

    // 학년 전체
    if (
      checkedFmClasses.length === gradeFmClasses.length &&
      checkedJnClasses.length === gradeJnClasses.length &&
      checkedSnClasses.length === gradeSnClasses.length
    ) {
      resultText = '학년 전체';
      setGradeAndClassStr(resultText);
      return resultText;
    }

    // 1학년
    if (checkedFmClasses.length > 0) {
      if (checkedFmClasses.length === gradeFmClasses.length) {
        fmClassesText = '1학년 전체';
      } else {
        fmClassesText = `${checkedFmClasses
          .map(el => {
            return `1학년 ${window.parseInt(el) ? window.parseInt(el) : el}반`;
          })
          .join(', ')}`;
      }
    }

    // 2학년
    if (checkedJnClasses.length > 0) {
      if (checkedJnClasses.length === gradeJnClasses.length) {
        jnClassesText = '2학년 전체';
      } else {
        jnClassesText = checkedJnClasses
          .map(el => {
            return `2학년 ${window.parseInt(el) ? window.parseInt(el) : el}반`;
          })
          .join(', ');
      }
    }

    // 3학년
    if (checkedSnClasses.length > 0) {
      if (checkedSnClasses.length === gradeSnClasses.length) {
        snClassesText = '3학년 전체';
      } else {
        snClassesText = checkedSnClasses
          .map(el => {
            return `3학년 ${window.parseInt(el) ? window.parseInt(el) : el}반`;
          })
          .join(', ');
      }
    }
    resultArr.push(fmClassesText, jnClassesText, snClassesText);
    const result = resultArr
      .filter(el => el !== undefined)
      .join(', ')
      .trim();
    if (result === '') {
      const resultStr = '학년/반 선택';
      setGradeAndClassStr(resultStr);
      return resultStr;
    }
    setGradeAndClassStr(result);
    return result;
  };

  /** API */
  const getSchoolGradeBan = async idx => {
    const params = {
      school_idx: idx,
    };
    try {
      const { data } = await selectSchoolGradeAndBan(params);
      if (data.code === 0) {
        const result = {
          Fm: {
            grade: 1,
            classes: [...data.data.fmClassesList],
          },
          Jn: {
            grade: 2,
            classes: [...data.data.jnClassesList],
          },
          Sn: {
            grade: 3,
            classes: [...data.data.snClassesList],
          },
        };
        setGrade(result);
        if (checkedGradeList.length === 0) {
          setCheckedGrades([]);
          setCheckedFmClasses([]);
          setCheckedJnClasses([]);
          setCheckedSnClasses([]);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  /** useEffect */
  // 선택 학년, 반 문자열 생성 :: 디바운싱
  useEffect(() => {
    const handler = setTimeout(() => {
      toStringCheckedResults();
    }, 100);

    return () => {
      clearTimeout(handler);
    };
  }, [
    checkedGrades,
    checkedFmClasses,
    checkedJnClasses,
    checkedSnClasses,
    gradeFmClasses,
    gradeJnClasses,
    gradeSnClasses,
  ]);

  useEffect(() => {
    if (schoolidx) {
      getSchoolGradeBan(schoolidx);
    }
  }, [schoolidx]);

  useEffect(() => {
    if (setCheckedGradeList) {
      setCheckedGradeList([...checkedGrades]);
    }
    // console.log('checkedGrades', checkedGrades);
  }, [checkedGrades]);

  useEffect(() => {
    // console.log('checkedFmClasses', checkedFmClasses);
  }, [checkedFmClasses]);

  useEffect(() => {
    // console.log('checkedJnClasses', checkedJnClasses);
  }, [checkedJnClasses]);

  useEffect(() => {
    // console.log('checkedSnClasses', checkedSnClasses);
  }, [checkedSnClasses]);

  return (
    <Dropdown css={dropdown} {...rest}>
      <Dropdown.Toggle as="div">
        <strong>{gradeAndClassStr}</strong>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ul
          className="grades"
          style={{
            borderRight: activeGrade.grade
              ? '1px solid var(--bs-border)'
              : 'none',
          }}
        >
          <li onClick={() => setActiveGrade({})}>
            <Form.Check
              type="checkbox"
              label="학년 전체"
              id="grade-all"
              onChange={e => onCheckedAllGrade(e.target.checked)}
              checked={checkedGrades.length === 3}
            />
          </li>
          <li className={classNames({ active: activeGrade === grades.Fm })}>
            <Form.Check
              type="checkbox"
              label="1학년"
              id="grade-fm"
              onChange={e => onCheckedGrade(e.target.checked, grades.Fm)}
              checked={
                checkedFmClasses.length >= 1 || checkedGrades.length === 3
              }
            />
          </li>
          <li className={classNames({ active: activeGrade === grades.Jn })}>
            <Form.Check
              type="checkbox"
              label="2학년"
              id="grade-jn"
              onChange={e => onCheckedGrade(e.target.checked, grades.Jn)}
              checked={
                checkedJnClasses.length >= 1 || checkedGrades.length === 3
              }
            />
          </li>
          <li className={classNames({ active: activeGrade === grades.Sn })}>
            <Form.Check
              type="checkbox"
              label="3학년"
              id="grade-sn"
              onChange={e => onCheckedGrade(e.target.checked, grades.Sn)}
              checked={
                checkedSnClasses.length >= 1 || checkedGrades.length === 3
              }
            />
          </li>
        </ul>

        {/* 1학년 반 */}
        {activeGrade.grade === 1 && (
          <ul className="classes fm">
            <li>
              <Form.Check
                type="checkbox"
                label="반 전체"
                id="gradeFmClass-all"
                onChange={e => onCheckedAllClasses(e.target.checked, 1)}
                checked={checkedFmClasses.length === gradeFmClasses.length}
              />
            </li>
            {grades.Fm.classes.map(item => {
              return (
                <li key={`gradeFmClass{${item}}`}>
                  <Form.Check
                    type="checkbox"
                    id={`gradeFmClass-${item}`}
                    label={`${
                      window.parseInt(item) ? window.parseInt(item) : item
                    }반`}
                    onChange={e =>
                      onCheckedIndClasses(e.target.checked, 1, item)
                    }
                    checked={checkedFmClasses.includes(item)}
                  />
                </li>
              );
            })}
          </ul>
        )}

        {/* 2학년 반 */}
        {activeGrade.grade === 2 && (
          <ul className="classes jn">
            <li>
              <Form.Check
                type="checkbox"
                label="반 전체"
                id="gradeJnClass-all"
                onChange={e => onCheckedAllClasses(e.target.checked, 2)}
                checked={checkedJnClasses.length === gradeJnClasses.length}
              />
            </li>
            {grades.Jn.classes.map(item => {
              return (
                <li key={`gradeJnClass-{${item}}`}>
                  <Form.Check
                    type="checkbox"
                    id={`gradeJnClass-${item}`}
                    label={`${
                      window.parseInt(item) ? window.parseInt(item) : item
                    }반`}
                    onChange={e =>
                      onCheckedIndClasses(e.target.checked, 2, item)
                    }
                    checked={checkedJnClasses.includes(item)}
                  />
                </li>
              );
            })}
          </ul>
        )}

        {/* 3학년 반 */}
        {activeGrade.grade === 3 && (
          <ul className="classes sn">
            <li>
              <Form.Check
                type="checkbox"
                label="반 전체"
                id="gradeSnClass-all"
                onChange={e => onCheckedAllClasses(e.target.checked, 3)}
                checked={checkedSnClasses.length === gradeSnClasses.length}
              />
            </li>
            {grades.Sn.classes.map(item => {
              return (
                <li key={`gradeSnClass-{${item}}`}>
                  <Form.Check
                    type="checkbox"
                    id={`gradeSnClass-${item}`}
                    label={`${
                      window.parseInt(item) ? window.parseInt(item) : item
                    }반`}
                    onChange={e =>
                      onCheckedIndClasses(e.target.checked, 3, item)
                    }
                    checked={checkedSnClasses.includes(item)}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const dropdown = css`
  .dropdown-toggle {
    width: 400px;
    height: 52px;
    padding: 0 2rem 0 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
    cursor: pointer;
    &:before {
      top: 24px;
    }
    strong {
      line-height: 52px;
      font-weight: 500;
      font-size: var(--fs-16);
    }
  }
  .dropdown-menu {
    &.show {
      width: 600px;
      padding: 0;
      box-shadow: none;
      border: 1px solid var(--bs-border);
      border-radius: 0.25rem;
      display: flex;
      align-items: flex-start;
      ul {
        max-height: 300px;
        overflow: auto;
        flex: 0 0 50%;
        &.grades {
          flex: 0 0 200px;
          li {
            &.active {
              background: var(--bs-primary-light) url(${images.IcArrowRight})
                no-repeat right 0.75rem center / 8px auto;
              .form-check {
                .form-check-label {
                }
              }
            }
            .form-check {
              margin: 0;
              width: 100%;
              display: block;
              .form-check-input {
                width: 0;
                height: 0;
                overflow: hidden;
                border: 0;
                margin: 0;
                &:checked + .form-check-label {
                  background: transparent;
                  color: var(--bs-primary);
                  font-weight: bold;
                }
              }
              .form-check-label {
                margin: 0;
                padding: 0;
                line-height: inherit;
                display: block;
              }
            }
          }
        }
        &.classes {
          flex: 0 0 400px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          li {
            display: inline-flex;
            flex: 0 0 33.33%;
            position: relative;
            padding-left: 2.75rem;
            .form-check {
              .form-check-input {
                width: 0;
                height: 0;
                overflow: hidden;
                border: 0;
                margin: 0;
                &:checked + .form-check-label {
                  background: transparent;
                  color: var(--bs-primary);
                  font-weight: bold;
                  &:after {
                    display: block;
                  }
                }
              }
              .form-check-label {
                margin: 0;
                padding: 0;
                line-height: inherit;
                &:before,
                &:after {
                  content: '';
                  display: block;
                  width: 24px;
                  height: 24px;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 24px auto;
                  position: absolute;
                  left: 1rem;
                  top: calc(50% - 12px);
                }
                &:before {
                  background-image: url(${images.Checkbox});
                }
                &:after {
                  background-image: url(${images.Checked});
                  display: none;
                }
              }
            }
          }
        }
        li {
          cursor: pointer;
          font-size: var(--fs-16);
          padding: 0.5rem 0.75rem;
          font-weight: 400;
        }
      }
    }
  }
`;
