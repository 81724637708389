import { MoonLoader } from 'react-spinners';
import React from 'react';

export default function LoadingSpinner({ show, size, message, color }) {
  return (
    <div className={`spinner-overlay ${show ? 'show' : 'hide'}`}>
      <div className="spinner-center">
        <MoonLoader size={size || 90} color={color || 'blue'} loading={show} />
        {message && <div className="loading-text">{message}</div>}
      </div>
    </div>
  );
}
