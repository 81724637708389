import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { CustomModal } from '@components';
import { Utils } from '@common';
import Swal from 'sweetalert2';
import { resetPw } from '../../../api/eduadmin/teacher';

export default React.memo(function AdmEduTeacher(props) {
  const [selectedSchool, setSelectedSchool] = useState({});

  const teacherData =
    (props.history.location.state && props.history.location.state) || {};

  /** modal */
  // 비밀번호 초기화
  const [pwdInitModalShow, setPwdInitModalShow] = useState(false);
  const openPwdInitModal = () => {
    setPwdInitModalShow(true);
  };
  const closePwdInitModal = () => {
    setPwdInitModalShow(false);
  };
  const submitPwdInitModal = e => {
    resetTeacherPw(e);
  };

  // 선생 비밀번호 초기화
  const resetTeacherPw = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const { data } = await resetPw(teacherData.seq);
      if (data.code === 0) {
        Swal.fire({
          html: '비밀번호가 초기화되었습니다.<br/>초기화된 비밀번호는 계정 아이디와 동일합니다.',
          confirmButtonText: '확인',
        });
        closePwdInitModal();
      }
    } catch (e) {
      target.disabled = false;
      closePwdInitModal();
    }
    target.disabled = false;
  };
  return (
    <main id="admEdu-teacher-detail" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>학교 관리자 정보</h5>
            </div>
          </section>

          <Card>
            <Card.Body>
              <div className="sub-title mb-5">
                <h5>{Utils.decrypt(teacherData.teacherName)}</h5>
              </div>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>학교정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">학교</Col>
                    <Col className="td col-10">{teacherData.schoolName}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">아이디</Col>
                    <Col className="td col-10">{teacherData.teacherId}</Col>
                  </Row>
                </div>
              </section>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>교사관리</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">비밀번호</Col>
                    <Col className="td col-10">
                      <Button
                        variant="outline-primary"
                        onClick={openPwdInitModal}
                      >
                        비밀번호 초기화
                      </Button>
                    </Col>
                  </Row>
                </div>
              </section>
              <div className="btn-area mb-0">
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() =>
                    props.history.push({
                      pathname: '/admEdu/teacher/register',
                      state: { type: 'modify', teacherData },
                    })
                  }
                >
                  수정
                </Button>
              </div>
            </Card.Body>
          </Card>
        </article>
      </Container>

      <CustomModal
        title="알림"
        show={pwdInitModalShow}
        onHide={closePwdInitModal}
        size="sm"
      >
        <Modal.Body className="border-0">
          <p className="text-center">비밀번호를 초기화 하시겠습니까?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closePwdInitModal}>
            취소
          </Button>
          <Button onClick={submitPwdInitModal}>초기화</Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});

const schoolOptions = [
  { label: '무슨학교', value: '0' },
  { label: '무슨학교', value: '1' },
  { label: '무슨학교', value: '2' },
];
