/**
 *   [ 전공 자격증 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/teacher';

const SELECT_LIST_CERTIFICATE = `${API_PROGRAM}/certificate-list`;
const UPDATE_CERTIFICATE = `${API_PROGRAM}/update-status-certificate`;

// 직업기초 능력 > 전공자격증 리스트
export const selectCertificateList = param => {
  return Axios.post(`${SELECT_LIST_CERTIFICATE}`, param);
};

// 직업기초 능력 > 전공자격증 승인, 보완, 취소
export const updateStatusCertificate = param => {
  return Axios.post(`${UPDATE_CERTIFICATE}`, param);
};
