/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Moment from 'react-moment';

//* API
import { selectMyNcsList, insertUpdateNcs } from '@api/student/ncs';

//* Main
export default React.memo(function IndAbilityNcs(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  // NCS 목록
  const [ncsList, setNcsList] = useState([]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  const handleDelete = seq => {
    Swal.fire({
      text: '해당 항목을 삭제하시겠습니까?',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      showCancelButton: true,
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        removeNcs(seq); // API
      }
    });
  };

  //* #################################################################
  //* [ API ] NCS 리스트
  //* #################################################################
  const getMyNcsList = async () => {
    // Axios
    try {
      const { data } = await selectMyNcsList();

      if (data.code === 0) {
        setNcsList(data.data);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] NCS 삭제
  //* #################################################################
  const removeNcs = async seq => {
    const paramMap = {
      formSeq: seq,
      status: 'DEL',
    };

    // Axios
    try {
      const { data } = await insertUpdateNcs(paramMap);

      if (data.code === 0) {
        getMyNcsList();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getMyNcsList();
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-ncs">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">NCS관리</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5 style={{ margin: '0' }}>
                NCS관리{' '}
                <span
                  style={{
                    fontSize: 'var(--fs-14)',
                    color: 'blue',
                    fontWeight: 'bold',
                    marginLeft: '5px',
                  }}
                >
                  (NCS는 입력은 가능하나 바우처 점수에 해당되지 않습니다.)
                </span>
              </h5>

              <Button
                onClick={() =>
                  props.history.push('/student/indivAbility/ncs/register')
                }
                variant="primary"
              >
                신규추가
              </Button>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section>
            <Table className="bt-none">
              <colgroup>
                <col width={160} />
                <col width={160} />
                <col width="*" />
                <col width={200} />
                <col width={120} />
              </colgroup>
              <thead>
                <tr>
                  <th>추가일</th>
                  <th>분류번호</th>
                  <th>NCS분류</th>
                  <th>능력단위</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {ncsList.length > 0 ? (
                  ncsList.map((item, idx) => {
                    return (
                      <tr key={`ncs-${idx}`}>
                        {/* 추가일 */}
                        <td>
                          <Moment date={item.reg_date} format="YYYY.MM.DD" />
                        </td>
                        {/* 분류번호 */}
                        <td>{item.learn_module_seq}</td>
                        {/* NCS 분류 (대분류 > 중분류 > 소분류 > 세분류) */}
                        <td className="text-start">{`${item.l_code_name} > ${item.m_code_name} > ${item.s_code_name} > ${item.sub_code_name}`}</td>
                        {/* 능력단위 */}
                        <td>{item.learn_module_name}</td>
                        {/* 삭제 */}
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() => handleDelete(item.seq)}
                            size="sm"
                          >
                            삭제
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <p className="no-data">등록된 NCS가 없습니다.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </section>
        </article>
      </Container>
    </main>
  );
});
