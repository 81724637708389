/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Form, Row, Col, Button, Card } from 'react-bootstrap';
import { CustomSelect } from '@components';
import { Utils } from '@common';
import { selectTeacherDetail, upsertTeacher } from '@api/teacher/teacherMgmt';
import { TEACHER_ROLE_DETAIL } from '@common/consts';
import Swal from 'sweetalert2';

export default React.memo(function TeacherRegister(props) {
  const history = useHistory();

  // type
  const [teacherType, setTeacherType] = useState('normal'); // normal/director

  // select
  const schoolOptions = [{ label: '선택하세요.', value: '0' }];
  const gradeOptions = [
    { label: '1학년', value: '1' },
    { label: '2학년', value: '2' },
    { label: '3학년', value: '3' },
  ];
  const classOptions = [
    { label: '1반', value: '01' },
    { label: '2반', value: '02' },
    { label: '3반', value: '03' },
    { label: '4반', value: '04' },
    { label: '5반', value: '05' },
    { label: '6반', value: '06' },
    { label: '7반', value: '07' },
    { label: '8반', value: '08' },
    { label: '9반', value: '09' },
    { label: '10반', value: '10' },
    { label: '11반', value: '11' },
    { label: '12반', value: '12' },
    { label: '13반', value: '13' },
    { label: '14반', value: '14' },
    { label: '15반', value: '15' },
    { label: '16반', value: '16' },
    { label: '17반', value: '17' },
    { label: '18반', value: '18' },
    { label: '19반', value: '19' },
    { label: '20반', value: '20' },
  ];
  const [positionSelected, setPositionSelected] = useState({});
  const [positionOptions, setPositionOptions] = useState([]);
  const [academicSelected, setAcademicSelected] = useState({});
  const [academicOptions, setAcademicOptions] = useState([]);

  /** form state */
  const [schoolSelected, setSchoolSelected] = useState();
  const [gradeSelected, setGradeSelected] = useState(gradeOptions[0]);
  const [classSelected, setClassSelected] = useState(classOptions[0]);

  const [name, setName] = useState(); // 이름
  const [teacherId, setTeacherId] = useState(); // ID

  /** API */
  // 선생 정보 조회
  const getTeacherInfo = async () => {
    const params = {
      teacher_idx: null,
      options: true,
    };
    try {
      const { data } = await selectTeacherDetail(params);
      if (data.code === 0) {
        if (data.data) {
          // 학교명
          if (data.data.teacherInfo) {
            setSchoolSelected({
              label: data.data.teacherInfo.school_name,
              value: data.data.teacherInfo.school_name,
            });
          }
          // ID명 :: ET[학교코드][순번]
          setTeacherId(
            `ET${data.data.teacherInfo.school_id}${getIdCountForm(
              data.data.teacherInfo.teacher_count + 1,
            )}`,
          );
          // 직책
          if (data.data.roleDetailList && data.data.roleDetailList.length > 0) {
            setPositionOptions(data.data.roleDetailList);
            setPositionSelected(data.data.roleDetailList[0]);
          }
          // 학적상태
          if (
            data.data.teacherGubunList &&
            data.data.teacherGubunList.length > 0
          ) {
            setAcademicOptions(data.data.teacherGubunList);
            setAcademicSelected(data.data.teacherGubunList[0]);
          }
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // 교사 등록
  const registTeacher = async event => {
    const target = event.currentTarget;
    target.disabled = true;

    if (!name) {
      Swal.fire({
        text: '이름을 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    const params = {
      teacher_idx: null,
      teacher_id: teacherId,
      teacher_name: Utils.encrypt(name),
      teacher_gubun: academicSelected.value,
      grade:
        positionSelected.value === TEACHER_ROLE_DETAIL.CLASS ||
        positionSelected.value === TEACHER_ROLE_DETAIL.SUB_CLASS ||
        positionSelected.value === TEACHER_ROLE_DETAIL.GRADE_HEAD
          ? gradeSelected.value
          : '0',
      ban_no:
        positionSelected.value === TEACHER_ROLE_DETAIL.CLASS ||
        positionSelected.value === TEACHER_ROLE_DETAIL.SUB_CLASS
          ? classSelected.value
          : null,
      role_detail: positionSelected.value,
    };
    try {
      const { data } = await upsertTeacher(params);
      if (data.code === 0) {
        Swal.fire({
          text: '교사가 등록되었습니다.',
          confirmButtonText: '확인',
        });
        history.push(`/teacher/teacherMgmt/detail/${data.data}`);
      }
    } catch (e) {
      // console.log(e);
      target.disabled = false;
    }
    target.disabled = false;
  };

  /** API help function */
  // 001, 002 형식으로 변경
  const getIdCountForm = number => {
    if (number >= 100) return `${number}`;
    if (number >= 10) return `0${number}`;
    if (number > 0) return `00${number}`;
    return '';
  };

  /** useEffect */
  useEffect(() => {
    getTeacherInfo();
  }, []);

  return (
    <main id="teacher-mgmt-register" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/teacher/teacherMgmt">교사관리</Link>
            </li>
            <li>
              <Link to="#none">교사등록</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>교사 등록</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Body>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">
                      <div className="lw-input">
                        <Form.Control
                          type="text"
                          onChange={e => {
                            setName(e.target.value);
                          }}
                          value={name || ''}
                          placeholder="이름을 입력해주세요."
                          maxLength="50"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>

              <section className="mb-5">
                <div className="sub-title">
                  <h5>학교정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">학교</Col>
                    <Col className="td col-10">
                      <div className="lw-input">
                        <CustomSelect
                          options={schoolOptions}
                          value={schoolSelected}
                          onChange={setSchoolSelected}
                          isDisabled
                        />
                      </div>
                    </Col>
                  </Row>
                  {(positionSelected.value === TEACHER_ROLE_DETAIL.CLASS ||
                    positionSelected.value === TEACHER_ROLE_DETAIL.SUB_CLASS ||
                    positionSelected.value ===
                      TEACHER_ROLE_DETAIL.GRADE_HEAD) && (
                    <Row className="table-row">
                      <Col className="th col-2">담당학급</Col>
                      <Col className="td col-10">
                        <div className="sw-input smallest">
                          <CustomSelect
                            options={gradeOptions}
                            value={gradeSelected}
                            onChange={setGradeSelected}
                          />
                        </div>
                        {(positionSelected.value ===
                          TEACHER_ROLE_DETAIL.CLASS ||
                          positionSelected.value ===
                            TEACHER_ROLE_DETAIL.SUB_CLASS) && (
                          <div className="sw-input smallest ms-2">
                            <CustomSelect
                              options={classOptions}
                              value={classSelected}
                              onChange={setClassSelected}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row className="table-row">
                    <Col className="th col-2">아이디</Col>
                    <Col className="td col-10">{teacherId}</Col>
                  </Row>
                </div>
              </section>

              <section className="mb-5">
                <div className="sub-title">
                  <h5>교사관리</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">직책</Col>
                    <Col className="td col-10">
                      <div className="lw-input">
                        <CustomSelect
                          options={positionOptions}
                          value={positionSelected}
                          onChange={setPositionSelected}
                          placeholder="직책"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">학적상태</Col>
                    <Col className="td col-10">
                      <div className="lw-input">
                        <CustomSelect
                          options={academicOptions}
                          value={academicSelected}
                          onChange={setAcademicSelected}
                          placeholder="학적상태"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>

              {/* ////////// ↓ 하단 버튼 //////////  */}
              <div className="btn-area mb-0">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={() => {
                    history.push('/teacher/teacherMgmt');
                  }}
                >
                  취소
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    registTeacher(e);
                  }}
                >
                  등록
                </Button>
              </div>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});
