/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Container, Button, Image, Nav } from 'react-bootstrap';
import { Utils } from '@common';
import { images } from '@assets';
import { Timer } from '@components';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//* [ Main ]
export default React.memo(function StudentHeader({
  userInfo,
  menu = {},
  timerOption,
  tokenHandler,
  logoutHandler,
}) {
  const history = useHistory();
  const navi = useLocation();
  console.log('menus ::::: ', menu);

  //* 현재 학년
  // const curGrade = userInfo.grade;

  //* 세부 권한
  const curRoleDetail = userInfo.roleDetail;

  //* 메뉴 필터링 (본인 학년이 아닌 메뉴 제거)
  Object.keys(menu).forEach(item => {
    // 하위 메뉴 검색
    const isEmptyMenu =
      menu[item].filter(subItem => {
        if (subItem.roleDetail) {
          return subItem.roleDetail.split(';').includes(curRoleDetail);
        }
        return false;
      }).length === 0;

    if (isEmptyMenu) {
      // eslint-disable-next-line no-param-reassign
      delete menu[item];
    }
  });

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <header>
      <section className="top-area">
        <Container>
          <h1 className="logo-container">
            <Link to={userInfo ? '/home' : '/'}>
              <Image src={images.logo} />
            </Link>
          </h1>
          <div className="flex-start">
            <Timer mm="60" ss="00" reset={timerOption} />
            <Button
              size="sm"
              variant="outline-primary"
              className="ms-3"
              onClick={tokenHandler}
            >
              시간연장
            </Button>
            <Button
              size="sm"
              variant="primary"
              className="ms-3"
              onClick={() => history.push('/home')}
            >
              메인으로
            </Button>
            <div className="user-menu">
              <p>
                <span>{Utils.decrypt(userInfo.name)}</span>&nbsp;님
              </p>
              <ul>
                <li>
                  <NavLink to="/student/mypage">마이페이지</NavLink>
                </li>
                <li>
                  <NavLink to="/student/service/notice">공지사항</NavLink>
                </li>
                <li>
                  <NavLink to="/student/service/archive">교육 자료실</NavLink>
                </li>
                <li>
                  <NavLink to="/student/service/use-info">
                    이용 안내 영상
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/" onClick={logoutHandler}>
                    로그아웃
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>
      <section className="nav-bar">
        <Container>
          {menu ? (
            <Nav>
              {Object.keys(menu).map((mainTitle, i) => {
                return (
                  <Nav.Item key={i}>
                    {/* 메인 메뉴 */}
                    <NavLink
                      className={classNames({
                        selected: navi.pathname.includes(
                          `${menu[mainTitle][0].url.split('/')[1]}`,
                        ),
                      })}
                      to={`/student${
                        menu[mainTitle][
                          menu[mainTitle].findIndex(item =>
                            item.roleDetail.includes(curRoleDetail),
                          )
                        ].url
                      }`}
                    >
                      {mainTitle}
                    </NavLink>
                    {/* 서브 메뉴 - 드랍다운 */}
                    <ul className="sub-menu">
                      {menu[mainTitle].map((subMenu, ii) => {
                        return subMenu.roleDetail
                          .split(';')
                          .includes(curRoleDetail) ? (
                          <li key={ii}>
                            <NavLink
                              activeClassName="sub-selected"
                              to={`/student${subMenu.url}`}
                            >
                              {subMenu.title}
                            </NavLink>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </Nav.Item>
                );
              })}
            </Nav>
          ) : (
            <Nav>
              {/* ↓ 자기역량관리 */}
              <Nav.Item>
                <NavLink
                  className={classNames({
                    selected: navi.pathname.includes('/student/indivAbility'),
                  })}
                  to="/student/indivAbility/careerDesign"
                >
                  자기역량관리
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/indivAbility/careerDesign"
                    >
                      진로설계
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/indivAbility/portfolio"
                    >
                      직무기초 포트폴리오
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/indivAbility/visitIndustry"
                    >
                      산업체 채용면접
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 현장실무능력 */}
              <Nav.Item>
                <NavLink
                  className={classNames({
                    selected: navi.pathname.includes(
                      '/student/practiceAbility',
                    ),
                  })}
                  to="/student/practiceAbility/excursions"
                >
                  현장실무능력
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/practiceAbility/excursions"
                    >
                      산업체 현장견학
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/practiceAbility/experience"
                    >
                      산업체 현장체험
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/practiceAbility/training"
                    >
                      산업체실습
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 전공직무능력1  */}
              <Nav.Item>
                <NavLink activeClassName="selected" to="/student/majorJobCert">
                  전공직무능력
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/majorJob"
                    >
                      전공 자격증 취득
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 전공직무능력2  */}
              <Nav.Item>
                <NavLink
                  className={classNames({
                    selected: navi.pathname.includes('/student/majorJobEdu'),
                  })}
                  to="/student/majorJobEdu/basic"
                >
                  {/* 전공직무능력2 */}
                  직무능력강화
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/majorJobEdu/basic"
                    >
                      {/* 전공기초교육 */}
                      직무기초교육
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/majorJobEdu/advanced"
                    >
                      {/* 전공심화교육 */}
                      직무심화교육
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/majorJobEdu/applied"
                    >
                      {/* 전공응용교육 */}
                      직무응용교육
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 직업기초능력  */}
              <Nav.Item>
                <Link
                  to="/student/employAbility/jobAssess"
                  className={classNames({
                    selected: navi.pathname.includes('/student/employAbility'),
                  })}
                >
                  직업기초 능력
                </Link>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/employAbility/jobAssess"
                    >
                      직업기초 능력평가
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 취업역량강화  */}
              <Nav.Item>
                <NavLink
                  to="/student/employAbility/mind-development"
                  className={classNames({
                    selected: navi.pathname.includes('/student/employAbility'),
                  })}
                >
                  취업역량강화
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/employAbility/mind-development"
                    >
                      취업마인드 함양
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/employAbility/mind-reinforcing"
                    >
                      취업마인드 강화
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/employAbility/skills-reinforcing"
                    >
                      취업역량 강화
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 직업기초능력  */}
              <Nav.Item>
                <Link
                  to="/student/basicJob/tenacityAction"
                  className={classNames({
                    selected: navi.pathname.includes(
                      '/student/basicJob/tenacityAction',
                    ),
                  })}
                >
                  인성 자존감
                </Link>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/basicJob/tenacityAction"
                    >
                      인성 자존감 활동
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 자율교육 프로그램  */}
              <Nav.Item>
                <NavLink
                  activeClassName="selected"
                  className={classNames({
                    selected: navi.pathname.includes('/student/selfEduProgram'),
                  })}
                  to="/student/selfEduProgram/1"
                >
                  자율교육 프로그램
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/selfEduProgram/1"
                    >
                      1학년
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/selfEduProgram/2"
                    >
                      2학년
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/selfEduProgram/3"
                    >
                      3학년
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 매칭정보  */}
              <Nav.Item>
                <NavLink
                  activeClassName="selected"
                  className={classNames({
                    selected: navi.pathname.includes('/student/matchingInfo'),
                  })}
                  to="/student/matchingInfo/report"
                >
                  매칭정보
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/matchingInfo/report"
                    >
                      종합보고서
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/matchingInfo/comp"
                    >
                      기업정보
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/student/matchingInfo/univ"
                    >
                      대학정보
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>
            </Nav>
          )}
        </Container>
      </section>
    </header>
  );
});
