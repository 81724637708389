/**
 *   [ 진로설계 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const SELECT_CAREER = `${API_PROGRAM}/career-info`;
const INSERT_UPDATE_CAREER = `${API_PROGRAM}/career-design`;

// 진로설계 > 정보조회
export const selectFormCareer = param => {
  return Axios.post(`${SELECT_CAREER}`, param);
};

// 진로설계 > 신규등록, 저장, 취소, 승인요청, 삭제
export const insertUpdateFormCareer = param => {
  return Axios.post(`${INSERT_UPDATE_CAREER}`, param);
};
