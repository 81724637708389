import Axios from '@api/axios';

const API_COMMON = 'api/v1/common';

const CLASSES_BY_GRADE = `${API_COMMON}/classes-by-grade`;
const SCHOOL_LIST = `${API_COMMON}/school-list`;
const NTH_LIST = `${API_COMMON}/nth-list`;
const PROGRAM_LIST = `${API_COMMON}/program-list`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 학교의 학년 별 반 조회
export const selectSchoolGradeAndBan = param => {
  return Axios.post(`${CLASSES_BY_GRADE}`, param);
};

// 학교의 학년 별 반 조회
export const getSchools = () => {
  return Axios.get(`${SCHOOL_LIST}`);
};

// 현재 차수 및 차수 리스트
export const getNthList = () => {
  return Axios.get(`${NTH_LIST}`);
};

// 프로그램 리스트 조회
export const getProgramList = () => {
  return Axios.get(`${PROGRAM_LIST}`);
};
