/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Image, Button } from 'react-bootstrap';
import { images } from '@assets';

export default React.memo(function Footer() {
  return (
    <footer>
      <div className="top-area">
        <Container>
          <div className="dl-box type-bar">
            <dl>
              <a
                href="https://www.pen.go.kr/index.pen"
                target="_blank"
                rel="noreferrer"
              >
                <dd>부산광역시교육청</dd>
              </a>
            </dl>
            <dl>
              <a href="/privacy" target="_blank" rel="noreferrer">
                <dd>개인정보처리방침</dd>
              </a>
            </dl>
          </div>
        </Container>
      </div>
      <div className="bottom-area">
        <Container>
          <h5>부산광역시교육청 취업지원센터</h5>
          <div className="dl-box type-bar">
            <dl>
              <dd>
                부산광역시 연제구 중앙대로 1001(연산동) 부산광역시청 1층
                취업지원센터
              </dd>
            </dl>
            <dl>
              <dt>대표전화&nbsp;&nbsp;:&nbsp;&nbsp;</dt>
              <dd>051-866-7419</dd>
            </dl>
            <dl>
              <dt>팩스&nbsp;&nbsp;:&nbsp;&nbsp;</dt>
              <dd>051-635-7337</dd>
            </dl>
          </div>
          <p>
            Copyright ⓒ BUSAN METROPOLITANCITY OFFICE OF EDUCATION all rights
            reserved.
          </p>
        </Container>
      </div>
    </footer>
  );
});
