/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Form, Card, Row, Col, Modal } from 'react-bootstrap';
import { PdfPreview, PreviewModal } from '@components';
import Swal from 'sweetalert2';
import Moment from 'react-moment';

//* Utils
import Utils from '@common/Utils';

//* CONSTS
import {
  FormStatus,
  PROGRAM_ID,
  RESEARCH_POINT_TYPE_LIST,
  FILE_FORM_TYPE,
  APPROVE_STATE_TYPE_LIST,
  ROLE,
} from '@common/consts';

//* API
import {
  selectFormPracticeAbilityInfoByTeacher,
  updateFormPracticeAbilityByTeacher,
} from '@api/teacher/practiceAbility';
import { getFilePathFromRedis } from '@api/file';

//* [ Main ]
export default React.memo(function PracticeAbilityComment(props) {
  const history = useHistory();

  // 유저 권한 타입
  const authType = props.userInfo.type;

  const { seq, studentIdx, pageType } = useParams();

  if (!window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (!window.parseInt(seq)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  // 제대로 된 페이지로 들어왔는지 확인
  const pageTypes = ['excursions', 'experience', 'training'];
  if (!pageTypes.includes(pageType)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }
  // 페이지별 학년 구분
  const getPageGrade = type => {
    switch (type) {
      case 'excursions':
        return 1;
      case 'experience':
        return 2;
      case 'training':
        return 3;
      default:
        return null;
    }
  };

  // 프로그램 ID 획득
  const getProgramId = type => {
    switch (getPageGrade(type)) {
      case 1:
        return PROGRAM_ID.PROGRAM_ID_FI_VISIT;
      case 2:
        return PROGRAM_ID.PROGRAM_ID_FI_EXPERIENCE;
      case 3:
        return PROGRAM_ID.PROGRAM_ID_FI_TRAINING;
      default:
        return null;
    }
  };

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // radio
  const [approveStateType, setApproveStateType] = useState(
    APPROVE_STATE_TYPE_LIST[0].value,
  );

  // 프로그램 ID
  const programId = getProgramId(pageType);

  // 만족도 조사 radio
  const [researchRadioResult, setResearchRadioResult] = useState({});

  // 학생 정보, 보고서 정보
  const [reportInfo, setReportInfo] = useState({});

  // 파일 리스트
  const [files, setFiles] = useState([]);

  // 교사 코멘트
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  // 리서치 정보
  const [researchList, setResearchList] = useState([]);

  // 교사 코멘트
  const [comment, setComment] = useState('');

  // PDF 미리보기 ( react-pdf )
  const [fileUrl, setFileUrl] = useState(null);
  const [pdfPageNum, setPdfPageNum] = useState(1);
  const [pdfTotalPages, setPdfTotalPages] = useState(1);
  
  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // PDF 파일 로딩 완료
  const onDocumentLoadSuccess = ({numPages}) => {
    setPdfPageNum(1);
    setPdfTotalPages(numPages);
  }

  // 설문조사 값 설정
  const settingResearchValue = (researchIdx, researchResponse) => {
    if (researchIdx && researchResponse) {
      const idxs = researchIdx.split(';');
      const responses = researchResponse.split(';');
      const researchObject = {};
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in idxs) {
        researchObject[`${idxs[i]}`] = responses[i];
      }
      setResearchRadioResult(researchObject);
    }
  };

  //* #################################################################
  //* [ Preview Modal ]
  //* #################################################################
  // Flag
  const [previewModalShow, setPreviewModalShow] = useState(false);
  
  // Open
  const openPreviewModal = () => {
    openFile({fileSeq: null});
    setPreviewModalShow(true);
  };

  // Close
  const closePreviewModal = () => {
    setPreviewModalShow(false);
  };

  //* #################################################################
  //* [ API ] Form 조회
  //* #################################################################
  const getInfo = async () => {
    // 파라미터
    const param = {
      formSeq: seq,
      program_id: programId,
      student_idx: studentIdx,
    };

    // Axios
    try {
      const { data } = await selectFormPracticeAbilityInfoByTeacher(param);

      if (data.code === 0) {
        // 학생 정보, 보고서 정보
        const { info } = data.data;
        if (info) {
          setReportInfo(info);
        }

        // 파일 리스트
        setFiles(data.data.files);

        // 교사 코멘트 리스트
        setTeacherCommentList(data.data.teacherCommentList);

        // 리서치 목록 구성
        setResearchList(data.data.researchList);

        // 리서치 값 세팅
        settingResearchValue(info.research_idx, info.research_response);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 승인, 반려, 승인취소
  //* #################################################################
  const updateRequest = async (target, reqStatus) => {
    target.disabled = true;

    // Axios
    const params = {
      formSeq: seq,
      status: reqStatus,
      comment,
      program_id: programId,
    };
    try {
      const { data } = await updateFormPracticeAbilityByTeacher(params);

      if (data.code === 0) {
        window.location.reload();
      }
    } catch (e) {
      target.disabled = false;
      // alert(e.response.data.message);
    }

    target.disabled = false;
  };

  //* #################################################################
  //* [ API ] 파일 조회 ( 첨부파일, 승인이수 )
  //* #################################################################
  const openFile = async ({ fileSeq }) => {
    let paramMap;

    // [ CASE 1 ] 첨부파일 조회
    if (fileSeq) {
      paramMap = {
        formType: FILE_FORM_TYPE.FILE_INDUSTRY,
        fileSeq,
      };
    }
    // [ CASE 2 ] 승인이수 조회
    else {
      let formType;

      switch (+reportInfo.grade) {
        case 1:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_INDV_VISIT;
          break;
        case 2:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_INDV_EXPERIENCE;
          break;
        case 3:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_INDV_TRAINING;
          break;
        default:
          Swal.fire({
            text: '잘못된 접근입니다.',
            confirmButtonText: '확인',
          });
          return;
      }

      paramMap = {
        formType,
        fileSeq,
      };
    }

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        // 파일 다운로드 (첨부파일)
        if (fileSeq) {
          window.open(url);
          
        } 
        // PDF 미리보기 (이수기준)
        else {
          setFileUrl(url);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getInfo();
    return () => {
      setReportInfo({});
      setFiles([]);
      setTeacherCommentList([]);
      setResearchList([]);
      setResearchRadioResult({});
    };
  }, []);

  const [linkList, setLinkList] = useState([]);
  const pageTypeNames = {
    excursions: '산업체 현장견학',
    experience: '산업체 현장체험',
    training: '산업체 현장실습',
  };

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
          {
            name: pageTypeNames[pageType],
            path: `/teacher/practiceAbility-teacher/${pageType}/${studentIdx}`,
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
          {
            name: pageTypeNames[pageType],
            path: `/teacher/practiceAbility-teacher/${pageType}/${studentIdx}`,
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-practiceAbility-comment" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">결과 보고서</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title mb-60">
              <h5>
                {pageType === 'excursions' && '산업체 현장견학'}
                {pageType === 'experience' && '산업체 현장체험'}
                {pageType === 'training' && '산업체 현장실습'}
                &nbsp;결과 보고서
              </h5>
              <div className="dl-box type-bar lg">
                <dl className="status-text">
                  <strong
                    className={
                      FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].class
                    }
                    >
                    {FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].text}
                    <br/>
                    {reportInfo.status === 'CON' && 
                      <small>{Utils.getJustDateString(reportInfo.confirm_date)}</small>
                    }
                  </strong>
                </dl>
                <dl>
                  <dd>
                    <strong>{reportInfo.point}</strong>
                  </dd>
                </dl>
              </div>
            </div>

            <div className="mb-4">
              <Button variant="text-underline" onClick={openPreviewModal}>
                승인이수 보기
              </Button>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Body>
              {/* 기본정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table">
                  <Row className="table-row">
                    <Col className="th col-2">학번</Col>
                    <Col className="td col-10">{reportInfo.student_id}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">
                      {Utils.decrypt(reportInfo.student_name)}
                    </Col>
                  </Row>
                </div>
              </section>
              {/* 산업체 참여 정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>산업체 참여 정보</h5>
                </div>
                {pageType !== 'training' ? (
                  <div className="split-table">
                    <Row className="table-row">
                      <Col className="th col-2">방문일</Col>
                      <Col className="td col-10">
                        {reportInfo.visit_date && (
                          <Moment
                            format="YYYY-MM-DD"
                            date={reportInfo.visit_date}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">이수시간</Col>
                      <Col className="td col-10">
                        {reportInfo && reportInfo.complete_time
                          ? `${reportInfo.complete_time} 시간`
                          : ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">방문업체명</Col>
                      <Col className="td col-10">
                        {reportInfo.visit_company}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">방문업체주소</Col>
                      <Col className="td col-10">
                        {reportInfo.visit_address}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="split-table">
                    <Row className="table-row">
                      <Col className="th col-2">실습일</Col>
                      <Col className="td col-10">
                        {' '}
                        {reportInfo.visit_date && (
                          <Moment
                            format="YYYY-MM-DD"
                            date={reportInfo.visit_date}
                          />
                        )}
                        &nbsp; ~ &nbsp;
                        {reportInfo.training_end_date && (
                          <Moment
                            format="YYYY-MM-DD"
                            date={reportInfo.training_end_date}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">이수시간</Col>
                      <Col className="td col-10">
                        {reportInfo && reportInfo.complete_time
                          ? `${reportInfo.complete_time} 시간`
                          : ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">지원주제</Col>
                      <Col className="td col-10">
                        {' '}
                        {reportInfo.support_group_gubun_han === '결과없음'
                          ? ''
                          : reportInfo.support_group_gubun_han}
                      </Col>
                    </Row>

                    <Row className="table-row">
                      <Col className="th col-2">종료사유</Col>
                      <Col className="td col-10">
                        {' '}
                        {reportInfo.termination_reason_gubun_han === '결과없음'
                          ? ''
                          : reportInfo.termination_reason_gubun_han}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">방문업체명</Col>
                      <Col className="td col-10">
                        {reportInfo.visit_company}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">방문업체주소</Col>
                      <Col className="td col-10">
                        {reportInfo.visit_address}
                      </Col>
                    </Row>
                  </div>
                )}
              </section>

              {/* 참여활동 내용 */}

              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5>참여활동 내용</h5>
                </div>
                <div className="comment-box">
                  <ul className="comment-list">
                    <li>
                      <p className="text">{reportInfo.visit_contents}</p>
                    </li>
                  </ul>
                </div>
              </section>

              {/* 참여 후 느낀점 */}

              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5>참여 후 느낀점</h5>
                </div>
                <div className="comment-box">
                  <ul className="comment-list">
                    <li>
                      <p className="text">{reportInfo.visit_review}</p>
                    </li>
                  </ul>
                </div>
              </section>

              {/* 만족도 조사 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>만족도 조사</h5>
                </div>
                <div className="split-table h-56">
                  <Row className="table-row">
                    <Col className="th col-2 text-gray">조사내용</Col>
                    <Col className="th col-4 text-center text-gray">질문</Col>
                    <Col className="th col-6">
                      <ul className="type-flex-1">
                        <li>매우 그렇다</li>
                        <li>그렇다</li>
                        <li>보통</li>
                        <li>그렇지 않다</li>
                        <li>매우 그렇지 않다</li>
                      </ul>
                    </Col>
                  </Row>
                  {researchList &&
                    researchList.length > 0 &&
                    researchList.map((item, index) => {
                      return (
                        <Row className="table-row" key={`research-${index}`}>
                          <Col className="th col-2 text-gray">
                            {item.research_contents}
                          </Col>
                          <Col className="td col-4">
                            {item.research_question}
                          </Col>
                          <Col className="td col-6 flex-between">
                            <div className="radio-group type-flex-1">
                              {RESEARCH_POINT_TYPE_LIST.map(pointItem => {
                                return (
                                  <Form.Check
                                    key={`${index}-${pointItem.value}`}
                                    name={`${index}`}
                                    type="radio"
                                    label=""
                                    value={pointItem.value}
                                    id={pointItem.value}
                                    onChange={e => {
                                      setResearchRadioResult({
                                        ...researchRadioResult,
                                        [item.seq]: e.target.value,
                                      });
                                    }}
                                    checked={
                                      researchRadioResult[item.seq] ===
                                      pointItem.value
                                    }
                                    disabled
                                  />
                                );
                              })}
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                </div>
              </section>

              {/* 파일 첨부 */}
              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5>파일 첨부</h5>
                </div>
                {files.length > 0 ? (
                  <ul className="attachment-list">
                    {files.map((file, idx) => {
                      return (
                        <li key={idx}>
                          <Button
                            className="btn-icon ic-down"
                            onClick={() => openFile({ fileSeq: file.file_seq })}
                          >
                            {file.file_name_org}
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <ul className="attachment-list">
                    <li>없음</li>
                  </ul>
                )}
              </section>

              {authType !== ROLE.ADMIN &&
                reportInfo.status === FormStatus.REQ.value && (
                  <section className="mb-5">
                    <div className="sub-title flex-start">
                      <h5>승인여부</h5>
                    </div>
                    <div className="card-radio-group">
                      {APPROVE_STATE_TYPE_LIST.map((item, index) => {
                        return (
                          <Form.Check
                            key={`approve-state-type-${item.value}`}
                            name="approve-state-type"
                            type="radio"
                            label={item.label}
                            value={item.value}
                            id={item.value}
                            onChange={e => setApproveStateType(e.target.value)}
                            checked={approveStateType === item.value}
                          />
                        );
                      })}
                    </div>
                  </section>
                )}

              {/* 교사 코멘트 */}
              <section className="mb-5">
                {authType !== ROLE.ADMIN &&
                  (reportInfo.status === FormStatus.REQ.value ||
                    reportInfo.status === FormStatus.CON.value) && (
                    <div>
                      <div className="sub-title">
                        <h5>교사 코멘트</h5>
                      </div>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="최대 700자 이내로 입력하세요."
                        onChange={e => {
                          setComment(e.target.value);
                        }}
                        value={comment || ''}
                        maxLength="700"
                      />
                      <small className="text-gray flex-end mt-2">{`${
                        comment ? comment.length : '0'
                      }/700자`}</small>
                    </div>
                  )}

                <div className="comment-box disabled mt-4">
                  <ul className="comment-list">
                    {teacherCommentList.length > 0 &&
                      teacherCommentList.map((item, index) => {
                        return (
                          <li key={`comment-${index}`}>
                            <p className="date">
                              {item.reg_date && (
                                <Moment
                                  format="YYYY.MM.DD"
                                  date={item.reg_date}
                                />
                              )}
                            </p>
                            <p className="text">{item.comment}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </section>

              {/* ////////// ↓ 하단 버튼 //////////  */}
              {authType !== ROLE.ADMIN &&
                reportInfo.status === FormStatus.CON.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={e => {
                        const target = e.currentTarget;

                        Swal.fire({
                          title: '알림',
                          text: '취소 하시겠습니까?',
                          confirmButtonText: '확인',
                          cancelButtonText: '취소',
                          showCancelButton: true,
                          reverseButtons: true,
                        }).then(result => {
                          if (result.isConfirmed) {
                            updateRequest(target, FormStatus.REJ.value);
                          }
                        });
                      }}
                    >
                      승인취소
                    </Button>
                  </div>
                )}
              {authType !== ROLE.ADMIN &&
                reportInfo.status === FormStatus.REQ.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={e => {
                        const target = e.currentTarget;
                        
                        Swal.fire({
                          title: '알림',
                          text: '제출 하시겠습니까?',
                          confirmButtonText: '확인',
                          cancelButtonText: '취소',
                          showCancelButton: true,
                          reverseButtons: true,
                        }).then(result => {
                          if (result.isConfirmed) {
                            updateRequest(target, approveStateType);
                          }
                        });
                      }}
                    >
                      제출
                    </Button>
                  </div>
                )}
              {authType !== ROLE.ADMIN &&
                reportInfo.status !== FormStatus.CON.value &&
                reportInfo.status !== FormStatus.REQ.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                  </div>
                )}
              {authType === ROLE.ADMIN && (
                <div className="btn-area mb-0">
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                    variant="outline-primary"
                    size="lg"
                  >
                    목록
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </article>
      </Container>

      {/* PDF 미리보기 모달 */}
      <PreviewModal
        className="preview-modal"
        title="승인이수"
        show={previewModalShow}
        onHide={closePreviewModal}
      >
        <Modal.Body className="border-0 text-center">
          <PdfPreview 
            fileUrl={fileUrl}
            loadHandler={onDocumentLoadSuccess}
            pageNum={pdfPageNum}
            totalPages={pdfTotalPages}
            isPageDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={closePreviewModal}
          >
            닫기
          </Button>
        </Modal.Footer>
      </PreviewModal>
    </main>
  );
});
