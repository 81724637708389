/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Container, Form, Table, Button, Card, Modal } from 'react-bootstrap';
import { CustomModal } from '@components';
import Swal from 'sweetalert2';

//* Utils
import Utils from '@common/Utils';

//* API
import {
  selectSchoolVoucherBatch,
  // preConfirmSchoolVoucherBatch,
  confirmSchoolVoucherBatch,
} from '@api/teacher/voucherBatch';

// 바우처 Form
const initialVoucherForm = {
  seq: null,
  voucher: 0,
  voucherMax: 0,
  grade1Point: 0,
  grade2Point: 0,
  grade3Point: 0,
  grade1Voucher: 0,
  grade2Voucher: 0,
  grade3Voucher: 0,
  totalPoint: 0,
  totalVoucher: 0,
  payYn: 'N',
  confirmYn: 'N',
};

// '확정하기' 모달 메세지
const confirmMsg = (
  <>
    바우처 확정 이후에는 수정 절차가 복잡하오니
    <br />
    다시 한번 확인해주시기 바랍니다.
  </>
);

// '확정취소' 모달 메세지
// const cancelConfirmMsg = <>해당 바우처 확정내역을 취소하시겠습니까?</>;

// '일괄전환' 모달 메세지 - Confirm 모달 제거 ( 23.02.02 )
// const preConfirmMsg = (
//   <>
//     바우처 확정 이후에는 수정 절차가 복잡하오니
//     <br />
//     다시 한번 확인해주시기 바랍니다.
//   </>
// );

//* [ Main ]
export default React.memo(function VoucherBatch(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################

  // 바우처 데이터
  const [voucherForm, setVoucherForm] = useState(initialVoucherForm);

  // 중복 요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  // const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');
  const [confirmModalEventType, setConfirmModalEventType] = useState();

  // 모달 OPEN
  const openConfirmModal = (type, text) => {
    setConfirmModalShow(true); // 모달 출력
    setConfirmModalText(text); // 모달 내부 텍스트 설정
    setConfirmModalEventType(type); // CallBack 함수 타입 설정
  };

  // 모달 CLOSE
  const closeConfirmModal = () => {
    setConfirmModalShow(false);
    setConfirmModalEventType(null); // CallBack 함수 설정
  };

  // const submitConfirmModal = () => {
  //   closeConfirmModal();
  //   // setIsConfirmed(true);
  // };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // const voucherInputHandler = (value, grade) => {
  //   // 학년별 바우처 입력값 전처리 ( 콤마 제거, 숫자 제한 )
  //   let replacedInput = value.replaceAll(',', '').replace(/\D/, '');
  //   let maxInput;

  //   // 점수 반영
  //   switch (grade) {
  //     // 1학년
  //     case 1:
  //       // 최대 입력 확인
  //       maxInput = voucherForm.grade1Point * voucherForm.voucherMax;

  //       if (replacedInput > maxInput) {
  //         replacedInput = maxInput;
  //       }

  //       // 점수 반영
  //       voucherForm.grade1Voucher = Utils.numberComma(replacedInput);
  //       break;
  //     // 2학년
  //     case 2:
  //       // 최대 입력 확인
  //       maxInput = voucherForm.grade2Point * voucherForm.voucherMax;

  //       if (replacedInput > maxInput) {
  //         replacedInput = maxInput;
  //       }

  //       // 점수 반영
  //       voucherForm.grade2Voucher = Utils.numberComma(replacedInput);
  //       break;
  //     // 3학년
  //     case 3:
  //       // 최대 입력 확인
  //       maxInput = voucherForm.grade3Point * voucherForm.voucherMax;

  //       if (replacedInput > maxInput) {
  //         replacedInput = maxInput;
  //       }

  //       // 점수 반영
  //       voucherForm.grade3Voucher = Utils.numberComma(replacedInput);
  //       break;
  //     default:
  //       break;
  //   }

  //   // 입력된 바우처 총계
  //   const updatedTotal =
  //     +voucherForm.grade1Voucher.replaceAll(',', '') +
  //     +voucherForm.grade2Voucher.replaceAll(',', '') +
  //     +voucherForm.grade3Voucher.replaceAll(',', '');

  //   voucherForm.totalVoucher = Utils.numberComma(updatedTotal);

  //   // State 갱신
  //   setVoucherForm({ ...voucherForm });
  // };

  //* #################################################################
  //* [ API ] 학년별 점수, 바우처 정보 가져오기
  //* #################################################################
  const getSchoolVoucherBatch = async () => {
    // Axios
    try {
      const { data } = await selectSchoolVoucherBatch();

      if (data.code === 0) {
        const {
          seq = null,
          voucher = 0,
          voucher_max: voucherMax = 0,
          grade1_point: grade1Point = 0,
          grade2_point: grade2Point = 0,
          grade3_point: grade3Point = 0,
          grade1_voucher: grade1Voucher = 0,
          grade2_voucher: grade2Voucher = 0,
          grade3_voucher: grade3Voucher = 0,
          pay_yn: payYn = 'N',
          confirm_yn: confirmYn = 'N',
        } = data.data;

        // 바우처 입력정보 갱신
        setVoucherForm({
          seq,
          // voucher: voucher - (grade1Voucher + grade2Voucher + grade3Voucher),
          voucher,
          voucherMax,
          grade1Point,
          grade2Point,
          grade3Point,
          grade1Voucher: Utils.numberComma(grade1Voucher),
          grade2Voucher: Utils.numberComma(grade2Voucher),
          grade3Voucher: Utils.numberComma(grade3Voucher),
          totalPoint: grade1Point + grade2Point + grade3Point,
          totalVoucher: Utils.numberComma(
            grade1Voucher + grade2Voucher + grade3Voucher,
          ),
          payYn,
          confirmYn,
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 일괄전환 - 기능 제거 ( 22.02.03 )
  //* #################################################################
  // const preConfirmRequest = async () => {
  //   // 일괄 전환여부 확인 ( pay_yn ) - Confirm 모달 제거 ( 23.02.02 )
  //   // if (voucherForm.payYn === 'Y') {
  //   //   Swal.fire({
  //   //     text: '해당 차수에 이미 전환 바우처입니다.',
  //   //     confirmButtonText: '확인',
  //   //   });
  //   //   return;
  //   // }

  //   // 확정 여부 확인
  //   if (voucherForm.confirmYn === 'Y') {
  //     Swal.fire({
  //       text: '해당 차수에 이미 확정된 바우처입니다.',
  //       confirmButtonText: '확인',
  //     });
  //     return;
  //   }

  //   // 입력 검증 ( 숫자 형식, 최대 입력 )
  //   const inputMaxByPoint = voucherForm.voucherMax;
  //   const grade1Voucher = voucherForm.grade1Voucher.replaceAll(',', '');
  //   const grade2Voucher = voucherForm.grade2Voucher.replaceAll(',', '');
  //   const grade3Voucher = voucherForm.grade3Voucher.replaceAll(',', '');

  //   // 입력 검증 - 숫자 형식
  //   if (
  //     Number.isNaN(grade1Voucher) ||
  //     Number.isNaN(grade2Voucher) ||
  //     Number.isNaN(grade3Voucher)
  //   ) {
  //     Swal.fire({
  //       text: '잘못된 입력입니다.',
  //       confirmButtonText: '확인',
  //     });
  //     return;
  //   }

  //   // 입력 검증 - 최대 입력
  //   if (
  //     grade1Voucher > voucherForm.grade1Point * inputMaxByPoint ||
  //     grade2Voucher > voucherForm.grade2Point * inputMaxByPoint ||
  //     grade3Voucher > voucherForm.grade3Point * inputMaxByPoint
  //   ) {
  //     Swal.fire({
  //       text: '요청한 바우처 금액이 최대 입력금액을 초과합니다.',
  //       confirmButtonText: '확인',
  //     });
  //     return;
  //   }

  //   // JSON 파라미터
  //   const param = {
  //     seq: voucherForm.seq,
  //     grade1_voucher: grade1Voucher,
  //     grade2_voucher: grade2Voucher,
  //     grade3_voucher: grade3Voucher,
  //   };

  //   // Axios
  //   try {
  //     const { data } = await preConfirmSchoolVoucherBatch(param);

  //     if (data.code === 0) {
  //       Swal.fire({
  //         text: '바우처 일괄전환이 완료되었습니다.',
  //         confirmButtonText: '확인',
  //       }).then(result => {
  //         if (result.isConfirmed) {
  //           // 데이터 갱신
  //           getSchoolVoucherBatch();
  //         }
  //       });
  //     }
  //   } catch (e) {
  //     // alert(e.response.data.message);
  //   }
  // };

  //* #################################################################
  //* [ API ] 확정하기
  //* #################################################################
  const confirmRequest = async () => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 일괄전환 검증 ( pay_yn )
    if (voucherForm.payYn !== 'Y') {
      Swal.fire({
        text: '일괄전환이 완료되지 않았습니다.',
        confirmButtonText: '확인',
      }).then(result => {
        if (result.isConfirmed) {
          closeConfirmModal();
        }
      });
      return;
    }

    // 확정완료 검증 ( confirm_yn )
    if (voucherForm.confirmYn === 'Y') {
      Swal.fire({
        text: '해당 차수에 이미 확정된 바우처입니다.',
        confirmButtonText: '확인',
      }).then(result => {
        if (result.isConfirmed) {
          closeConfirmModal();
        }
      });
      return;
    }

    // 미할당 학교 확인 ( confirm_yn )
    if (voucherForm.confirmYn === 'F') {
      Swal.fire({
        text: '해당 차수 교육청 관리자로부터 할당된 바우처가 없습니다.',
        confirmButtonText: '확인',
      }).then(result => {
        if (result.isConfirmed) {
          closeConfirmModal();
        }
      });
      return;
    }

    // JSON 파라미터
    const param = {
      confirm_yn: 'Y',
    };

    // 중복 요청 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await confirmSchoolVoucherBatch(param);

      if (data.code === 0) {
        Swal.fire({
          text: '바우처 확정이 완료되었습니다.',
          confirmButtonText: '확인',
        }).then(result => {
          if (result.isConfirmed) {
            // 데이터 갱신
            getSchoolVoucherBatch();
          }
        });
      }

      // 모달 CLOSE
      closeConfirmModal();

      // 중복 요청 방지 해제
      setIsSentRequest(false);
    } catch (e) {
      // 중복 요청 방지 해제
      setIsSentRequest(false);
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 확정취소
  //* #################################################################
  // const cancelConfirmRequest = async () => {
  //   // 일괄전환 검증 ( pay_yn )
  //   if (voucherForm.payYn !== 'Y') {
  //     Swal.fire({
  //       text: '일괄전환이 완료되지 않았습니다.',
  //       confirmButtonText: '확인',
  //     });
  //     return;
  //   }

  //   // 확정취소 검증 ( confirm_yn )
  //   if (voucherForm.confirmYn !== 'Y') {
  //     Swal.fire({
  //       text: '해당 차수에 이미 확정취소된 바우처입니다.',
  //       confirmButtonText: '확인',
  //     });
  //     return;
  //   }

  //   // JSON 파라미터
  //   const param = {
  //     confirm_yn: 'N',
  //   };

  //   // Axios
  //   try {
  //     const { data } = await confirmSchoolVoucherBatch(param);

  //     if (data.code === 0) {
  //       Swal.fire({
  //         text: '바우처 확정취소가 완료되었습니다.',
  //         confirmButtonText: '확인',
  //       }).then(result => {
  //         if (result.isConfirmed) {
  //           // 데이터 갱신
  //           getSchoolVoucherBatch();
  //         }
  //       });
  //     }
  //   } catch (e) {
  //     // alert(e.response.data.message);
  //   }

  //   // 모달 CLOSE
  //   closeConfirmModal();
  // };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  // 프로그램 정보 가져오기
  useEffect(() => {
    getSchoolVoucherBatch();

    return () => {
      setVoucherForm(initialVoucherForm);
    };
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="teacher-voucherBatch" className="type-02">
      <Container>
        <article className="content">
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>바우처 일괄 전환</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          <Card>
            <Card.Header>
              <div className="table-title">
                <div className="dl-box type-bar">
                  <dl>
                    <dt>바우처 총금액</dt>
                  </dl>
                  {/* 현재 바우처 잔액 */}
                  <dl>
                    <dd>{Utils.numberComma(voucherForm.voucher)} 원</dd>
                  </dl>
                </div>
                <div className="btn-area m-0">
                  {/* 확정하기 버튼 */}
                  {/* {!voucherForm.confirmYn || voucherForm.confirmYn === 'N' ? ( */}
                  <Button
                    variant="outline-primary"
                    onClick={() => openConfirmModal('confirm', confirmMsg)}
                  >
                    확정하기
                  </Button>
                  {/* ) : (
                    // 확정취소
                    <Button
                      variant="outline-primary"
                      onClick={() =>
                        openConfirmModal('cancelConfirm', cancelConfirmMsg)
                      }
                    >
                      확정취소
                    </Button>
                  )} */}
                  {/* 일괄전환 버튼 - 기능 제거 ( 22.02.03 ) */}
                  {/* <Button
                    variant="primary"
                    onClick={() =>
                      // openConfirmModal('preConfirm', preConfirmMsg)
                      preConfirmRequest()
                    }
                  >
                    일괄전환
                  </Button> */}
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <Table className="bt-none tr-even-bg">
                <colgroup>
                  <col width={110} />
                  <col width="*" />
                  <col width={280} />
                  {/* <col width={300} /> */}
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>바우처 금액</th>
                    <th>점수 총계</th>
                    {/* <th className="text-end">점수 당 바우처 금액</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* 1학년 */}
                  <tr>
                    <td>1학년</td>
                    {/* <td>
                      <Form validated={validated}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="text-end"
                            placeholder="0"
                            required
                            value={voucherForm.grade1Voucher}
                            // onChange={e =>
                            //   voucherInputHandler(e.target.value, 1)
                            // }
                            // maxLength={11}
                            // readOnly={voucherForm.confirmYn === 'Y'}
                            readOnly
                          />
                          <Form.Control.Feedback type="invalid">
                            바우처 잔액이 부족합니다.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form>
                    </td> */}
                    <td className="text-end">
                      {voucherForm.grade1Voucher || 0}
                    </td>
                    {/* 점수 */}
                    <td>{voucherForm.grade1Point || 0}</td>
                    {/* 점수당 바우처 금액 */}
                    {/* <td className="text-end"> */}
                    {/*  {Utils.numberComma(voucherForm.voucherMax)} */}
                    {/* </td> */}
                  </tr>
                  {/* 2학년 */}
                  <tr>
                    <td>2학년</td>
                    {/* <td>
                      <Form validated={validated}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="text-end"
                            placeholder="0"
                            required
                            value={voucherForm.grade2Voucher}
                            // onChange={e =>
                            //   voucherInputHandler(e.target.value, 2)
                            // }
                            // maxLength={11}
                            // readOnly={voucherForm.confirmYn === 'Y'}
                            readOnly
                          />
                          <Form.Control.Feedback type="invalid">
                            바우처 잔액이 부족합니다.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form>
                    </td> */}
                    <td className="text-end">
                      {voucherForm.grade2Voucher || 0}
                    </td>
                    {/* 점수 */}
                    <td>{voucherForm.grade2Point || 0}</td>
                    {/* 점수당 바우처 금액 */}
                    {/* <td className="text-end"> */}
                    {/*  {Utils.numberComma(voucherForm.voucherMax)} */}
                    {/* </td> */}
                  </tr>
                  {/* 3학년 */}
                  <tr>
                    <td>3학년</td>
                    {/* <td>
                      <Form validated={validated}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="text-end"
                            placeholder="0"
                            required
                            value={voucherForm.grade3Voucher}
                            // onChange={e =>
                            //   voucherInputHandler(e.target.value, 3)
                            // }
                            // maxLength={11}
                            // readOnly={voucherForm.confirmYn === 'Y'}
                            readOnly
                          />
                          <Form.Control.Feedback type="invalid">
                            바우처 잔액이 부족합니다.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form>
                    </td> */}
                    <td className="text-end">
                      {voucherForm.grade3Voucher || 0}
                    </td>
                    {/* 점수 */}
                    <td>{voucherForm.grade3Point || 0}</td>
                    {/* 점수당 바우처 금액 */}
                    {/* <td className="text-end"> */}
                    {/*  {Utils.numberComma(voucherForm.voucherMax)} */}
                    {/* </td> */}
                  </tr>
                  {/* 총계 */}
                  <tr style={{ height: 60 }}>
                    <td>
                      <strong>총계</strong>
                    </td>
                    {/* 바우처 입력 금액 */}
                    <td className="text-end">
                      <strong>{voucherForm.totalVoucher}</strong>
                    </td>
                    {/* 점수 */}
                    <td>
                      <strong>{voucherForm.totalPoint}</strong>
                    </td>
                    {/* <td className="text-end">-</td> */}
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </article>
      </Container>

      {/* 커스텀 모달 */}
      <CustomModal
        title="알림"
        show={confirmModalShow}
        onHide={closeConfirmModal}
      >
        <Modal.Body className="border-0 text-center">
          {confirmModalText}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeConfirmModal}>
            취소
          </Button>
          <Button
            variant="primary"
            onClick={
              // 확정하기
              confirmModalEventType === 'confirm' ? confirmRequest : null
              // 확정취소 제거 ( 23.02.03 )
              // confirmModalEventType === 'cancelConfirm'
              // ? cancelConfirmRequest
              // Confirm 모달 제거 ( 23.02.02 )
              // confirmModalEventType === 'preConfirm'
              // ? preConfirmRequest
              // 확정취소
            }
          >
            확인
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
