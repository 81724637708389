/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Container, Button, Card, Table } from 'react-bootstrap';
import LoadingSpinner from '@components/LoadingSpinner';
import 'simplebar/dist/simplebar.min.css';
import { getSchools } from '@api/common/common';
import Swal from 'sweetalert2';

import {
  getFinalConfirmStatus,
  putSchoolStateFinalDecision,
} from '@api/eduadmin/student';

export default React.memo(function AdmEduStudentFixedStatus(props) {
  const [schoolList, setSchoolList] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [finalConfirmYn, setFinalConfirmYn] = useState('N');

  // 학교별 리스트 가져오기
  const getSchoolList = async () => {
    const { data } = await getSchools();
    if (data.code === 0) {
      setSchoolList(data.data);
    }
  };

  // 확정유무 확인
  const checkFinalConfirmStatus = async () => {
    const { data } = await getFinalConfirmStatus();
    if (data.code === 0) {
      setFinalConfirmYn(data.data);
    }
  };

  // 확정하기
  const studentFinalConfirm = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    Swal.fire({
      html: '임시 등록된 학생 정보를 최종 확정하시겠습니까?<br />최종 확정 후에는 취소가 불가능합니다.',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      showCancelButton: true,
      reverseButtons: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setShowSpinner(true);
        try {
          const { data } = await putSchoolStateFinalDecision();
          if (data.code === 0) {
            window.location.reload();
          }
          setShowSpinner(false);
        } catch (e) {
          target.disabled = false;
          setShowSpinner(false);
        }
      } else {
        target.disabled = false;
      }
    });

    // setShowSpinner(false);
    // target.disabled = false;
  };

  useEffect(() => {
    checkFinalConfirmStatus();
    getSchoolList();
  }, []);

  return (
    <main id="admEdu-student-fixed-status" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>학생 확정 현황</h5>
              <Button
                variant="outline-primary"
                disabled={
                  schoolList.some(el => el.confirmYn === 'N') ||
                  finalConfirmYn === 'Y'
                }
                onClick={e => {
                  studentFinalConfirm(e);
                }}
              >
                확정하기
              </Button>
            </div>
          </section>
          {showSpinner ? (
            <LoadingSpinner
              show={showSpinner}
              message="학생 최종 확정 중입니다."
            />
          ) : (
            <Card>
              <Card.Body>
                {finalConfirmYn === 'Y' && (
                  <div className="mb-3 text-end text-primary">
                    <strong>관리자 확정 완료</strong>
                  </div>
                )}
                <Table className="bt-none">
                  <colgroup>
                    <col width={120} />
                    <col width="*" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>학교명</th>
                      <th>확정 상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {schoolList.length > 0 ? (
                      schoolList.map((v, i) => {
                        return (
                          <tr key={`status-${v.schoolId}`}>
                            <td>{v.schoolId}</td>
                            <td>{v.schoolName}</td>
                            <td>
                              {v.confirmYn === 'Y' ? (
                                <strong className="text-primary">
                                  확정완료
                                </strong>
                              ) : (
                                <strong className="text-gray">미확정</strong>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className="no-data">
                          데이터가 없습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </article>
      </Container>
    </main>
  );
});
const fixedStatusDummy = [
  {
    id: '501',
    school: '부산영상예술고등학교',
    isFixed: false,
  },
  {
    id: '500',
    school: '부산영상예술고등학교',
    isFixed: true,
  },
  {
    id: '499',
    school: '부산영상예술고등학교',
    isFixed: true,
  },
];
