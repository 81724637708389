import React, { useEffect } from 'react';
import {
  Container,
  Badge,
  Row,
  Col,
  Button,
  Card,
  Table,
} from 'react-bootstrap';
import { Utils } from '@common';
import _, { isNaN } from 'lodash';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

export default React.memo(function AdmEduVoucherStatusDetail(props) {
  const history = useHistory();
  // const { thisVoucher } = history.location.state;
  const thisVoucher =
    (history.location.state && history.location.state.thisVoucher) || false;

  const calcPer = (voucher, score) => {
    const result = voucher / score;
    if (isNaN(result)) {
      return 0;
    }
    return Utils.numberComma(result);
  };

  const isNanNumber = number => {
    if (isNaN(number)) {
      return 0;
    }
    return Utils.numberComma(number);
  };

  useEffect(() => {
    if (!thisVoucher) {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        history.goBack();
      });
    }
  }, []);

  return (
    <main id="admEdu-voucher-status-detail" className="type-02">
      <Container>
        <article className="content py">
          {thisVoucher && (
            <Card>
              <Card.Body>
                <section className="mb-5">
                  {/* 학교별 확정여부 */}
                  <div className="sub-title">
                    {thisVoucher.confirmYn !== 'F' ? (
                      thisVoucher.confirmYn === 'Y' ? (
                        <Badge bg="success">지급완료</Badge>
                      ) : (
                        <Badge bg="warning">미지급</Badge>
                      )
                    ) : (
                      <Badge bg="gray-dark">미참여</Badge>
                    )}
                    <h5>{thisVoucher.schoolName}</h5>
                  </div>
                  {/* 후처리 여부 */}
                  {/* <div className="sub-title">
                    {thisVoucher.completePayment !== null ? (
                      thisVoucher.completePayment === true ? (
                        <Badge bg="success">지급완료</Badge>
                      ) : (
                        <Badge bg="warning">미지급</Badge>
                      )
                    ) : (
                      <Badge bg="gray-dark">미참여</Badge>
                    )}
                    <h5>{thisVoucher.schoolName}</h5>
                  </div> */}
                  <Table className="bt-none mt-4">
                    <colgroup>
                      <col width="300px" />
                      <col width="300px" />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>학년</th>
                        <th>점수 총계</th>
                        <th className="text-end">바우처 금액</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1학년</td>
                        <td>{Utils.numberComma(thisVoucher.grade1Point)}</td>
                        <td className="text-end">
                          {Utils.numberComma(thisVoucher.grade1Voucher)}
                        </td>
                      </tr>
                      <tr>
                        <td>2학년</td>
                        <td>{Utils.numberComma(thisVoucher.grade2Point)}</td>
                        <td className="text-end">
                          {Utils.numberComma(thisVoucher.grade2Voucher)}
                        </td>
                      </tr>
                      <tr>
                        <td>3학년</td>
                        <td>{Utils.numberComma(thisVoucher.grade3Point)}</td>
                        <td className="text-end">
                          {Utils.numberComma(thisVoucher.grade3Voucher)}
                        </td>
                      </tr>
                      <tr className="fw-bold">
                        <td>총계</td>
                        <td>
                          {Utils.numberComma(
                            thisVoucher.grade1Point +
                              thisVoucher.grade2Point +
                              thisVoucher.grade3Point,
                          )}
                        </td>
                        <td className="text-end">
                          {Utils.numberComma(thisVoucher.voucherSum)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </section>
              </Card.Body>
            </Card>
          )}
        </article>
      </Container>
    </main>
  );
});
