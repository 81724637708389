/**
 *   [ 학생관리 > 학생관리, 학생현황 API ]
 *
 */
import Axios from '@api/axios';

const API_TEACHER = 'api/v1/teacher';

const SELECT_TEMP_STUDENT_LIST = `${API_TEACHER}/temp-student-list`;
const INSERT_TEMP_STUDENT = `${API_TEACHER}/insert-temp-student`;
const CONFIRM_TEMP_STUDENT = `${API_TEACHER}/confirm-temp-student`;
const SELECT_STUDENT_LIST = `${API_TEACHER}/student-mgmt-list`;
const STUDENT_LIST = `${API_TEACHER}/student-list`;
const RESET_STUDENT_PASSWORD = `${API_TEACHER}/reset-student-password`;
const STUDENT_DETAIL = `${API_TEACHER}/student-detail`;
const STUDENT_INFO = `${API_TEACHER}/student-info`;

// 학생관리 > 학생현황 : 학생 임시 등록 리스트
export const selectTempStudentList = param => {
  return Axios.post(`${SELECT_TEMP_STUDENT_LIST}`, param);
};

// 학생 임시 등록
export const insertTempStudent = param => {
  return Axios.post(`${INSERT_TEMP_STUDENT}`, param);
};

// 학생임시등록 확정
export const confirmTempStudent = () => {
  return Axios.post(`${CONFIRM_TEMP_STUDENT}`);
};

// 학생 리스트
export const selectStudentList = param => {
  return Axios.post(`${STUDENT_LIST}`, param);
};

// 학생관리 > 학생관리 > 학생 조회
export const selectStudentMgmtList = param => {
  return Axios.post(`${SELECT_STUDENT_LIST}`, param);
};

// 학생관리 > 학생현황 > 학생 비밀번호 초기화
export const updateStudentPassword = param => {
  return Axios.post(`${RESET_STUDENT_PASSWORD}`, param);
};

// 학생관리 > 학생현황 > 상세 : 학생 상제 정보 조회
export const selectStudentDetail = param => {
  return Axios.post(`${STUDENT_DETAIL}`, param);
};

// 학생관리 > 학생현황 > 상세 > 수정 : 학생 정보 수정
export const updateStudentInfo = param => {
  return Axios.post(`${STUDENT_INFO}`, param);
};
